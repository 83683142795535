import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import RadioGroupWrapper from '../../FormInput/RadioGroupWrapper';
import useFormField from '../../Form/hooks/useFormField';

const RadioGroupFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  return (
    <FormField
      {...fieldProps}
      inputComponent={RadioGroupWrapper}
    />
  );
};

export default RadioGroupFormField;
