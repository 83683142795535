import * as as from '@ontologies/as';
import rdf from '@ontologies/core';
import { SomeNode } from 'link-lib';
import {
  Resource,
  useIds,
  useNumbers,
} from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React, {
  ElementType,
  FC,
} from 'react';

import ontola from '../../../ontology/ontola';
import useIsLoading from '../../hooks/useIsLoading';
import { useSeqToArr } from '../../hooks/useSeqToArr';
import CardRow from '../../topologies/Card/CardRow';
import GridItem from '../Grid/GridItem';
import LinkLoader from '../LinkLoader';
import { LoadingCardFixed } from '../Loading';
import ResourceBoundary from '../ResourceBoundary';

import CollectionEmpty from './CollectionEmpty';
import { useCollectionOptions } from './CollectionContext';

export interface ItemProps extends SubjectProp {
  renderer: FC<SubjectProp>;
}

const Item: FC<ItemProps> = ({ subject, renderer }) => {
  const {
    collectionDisplay,
    maxColumns,
  } = useCollectionOptions();
  const loading = useIsLoading(subject);

  if (loading) {
    return <LinkLoader />;
  }

  const Renderer = renderer ?? Resource;
  let Wrapper: ElementType = React.Fragment;
  let wrapperOpts = {};

  if (rdf.equals(collectionDisplay, ontola['collectionDisplay/card'])) {
    Wrapper = CardRow;
    wrapperOpts = {
      borderTop: true,
    };
  }

  if (rdf.equals(collectionDisplay, ontola['collectionDisplay/grid'])) {
    Wrapper = GridItem;
    wrapperOpts = {
      Fallback: LoadingCardFixed,
      maxColumns,
    };
  }

  return (
    <Wrapper {...wrapperOpts}>
      <Renderer subject={subject} />
    </Wrapper>
  );
};

const CollectionItems: FC<{page: SomeNode, renderer: FC<SubjectProp>; resourceBoundary?: boolean}> = ({ page, renderer, resourceBoundary }) => {
  const [itemSeq] = useIds(page, as.items);
  const [items] = useSeqToArr<SomeNode>(itemSeq);
  const [totalCount] = useNumbers(page, as.totalItems);

  const Wrapper = resourceBoundary ? ResourceBoundary : React.Fragment;

  if (totalCount === 0) {
    return (
      <CollectionEmpty />
    );
  }

  if (Array.isArray(items) && items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {items.map((item) => {
        const wrapperProps = resourceBoundary ? { subject: item } : {};

        return (
          <Wrapper
            key={item.value}
            {...wrapperProps}
          >
            <Item
              renderer={renderer}
              subject={item}
            />
          </Wrapper>
        );
      })}
    </React.Fragment>
  );
};

export default CollectionItems;
