import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { SomeTerm } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import clsx from 'clsx';
import { SomeNode } from 'link-lib';
import {
  useIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import ButtonWithFeedback, {
  ButtonWithFeedbackHandler,
  ButtonWithFeedbackProps,
} from '../../components/ButtonWithFeedback';
import { ButtonVariant } from '../../components/Button';
import useEntryPointFormProps from '../../components/Form/hooks/useEntryPointFormProps';
import {
  isFontAwesomeIRI,
  normalizeFontAwesomeIRI,
} from '../../lib/iris';
import {
  BreakPoints,
  LibroTheme,
} from '../../theme/types';

export interface EntryPointButtonProps extends Partial<ButtonWithFeedbackProps> {
  count?: number;
  image?: SomeTerm;
  name?: string;
  stretch?: boolean;
  subject: SomeNode;
}

const EntryPointButton: FC<EntryPointButtonProps> = ({
  active,
  color,
  count,
  disabled,
  grow,
  image: imageFromProp,
  name: nameFromProp,
  onClick,
  stretch,
  subject,
  variant,
  title,
  ...rest
}) => {
  const theme = useTheme<LibroTheme>();
  const screenIsWide = useMediaQuery(theme.breakpoints.up(BreakPoints.Medium));
  const { onSubmit } = useEntryPointFormProps(subject!, rest);
  const [imageFromData] = useIds(subject, schema.image);
  const [nameFromData] = useStrings(subject, schema.name);
  const image = imageFromProp ?? imageFromData;
  const name = nameFromProp ?? nameFromData;
  const displayCount = count === 0 ? '' : count;
  const handleSubmit = React.useCallback<ButtonWithFeedbackHandler>(() => {
    if (onSubmit) {
      return onSubmit({});
    }

    return Promise.resolve();
  }, [onSubmit]);

  const icon = image && isFontAwesomeIRI(image.value) ? normalizeFontAwesomeIRI(image.value) : undefined;
  const classes = clsx({
    'Button--has-icon': true,
    'Button--stretched': stretch,
  });

  const handleOnClick = onClick || handleSubmit;

  return (
    <ButtonWithFeedback
      active={active}
      className={classes}
      color={color}
      disabled={disabled}
      grow={grow}
      icon={icon}
      title={title}
      variant={variant ?? ButtonVariant.Transparent}
      onClick={handleOnClick}
    >
      {screenIsWide ? name : displayCount}
    </ButtonWithFeedback>
  );
};

export default EntryPointButton;
