import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import { InputType } from '../../FormInput/Input/Input';
import InputElement from '../../FormInput/Input/InputElement';
import useFormField from '../../Form/hooks/useFormField';

const PasswordInput = (fieldProps: InputComponentProps) => (
  <InputElement
    {...fieldProps}
    type={InputType.Password}
  />
);

const PasswordFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: true,
    storage: false,
  });

  return (
    <FormField
      {...fieldProps}
      inputComponent={PasswordInput}
    />
  );
};

export default PasswordFormField;
