import React from 'react';

import { handle } from '../../lib/logging';
import PageError from '../Error/PageError';

export interface ErrorBoundaryState {
  caughtError?: Error;
}

class ErrorBoundary extends React.PureComponent<unknown, ErrorBoundaryState> {
  constructor(props: unknown) {
    super(props);

    this.retry = this.retry.bind(this);
    this.state = {
      caughtError: undefined,
    };
  }

  componentDidMount(): void {
    if (__CLIENT__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(e: Error) {
    handle(e);
    this.setState({ caughtError: e });
  }

  retry(): Promise<void> {
    this.setState({
      caughtError: undefined,
    });

    return Promise.resolve();
  }

  render() {
    if (typeof this.state.caughtError !== 'undefined') {
      return (
        <PageError
          caughtError={this.state.caughtError}
          reloadLinkedObject={this.retry}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
