import { t } from 'i18next';

export const emptyMessage = (
  searchable: boolean,
  currentValue: string,
) => {
  if (!searchable) {
    return t('form.noMatchingItems');
  }

  const noResults = currentValue && currentValue.length > 0;

  return noResults
    ? t('form.noMatchingItems')
    : t('form.typeToSearch');
};
