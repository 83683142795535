import rdf, { NamedNode } from '@ontologies/core';
import { useStrings } from 'link-redux';
import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
} from 'react';
import FontAwesome from 'react-fontawesome';

import { useCollectionOptions } from '../../Collection/CollectionContext';
import { Column } from '../../Collection/Frame/TableFrame';
import { SortProps } from '../../Collection/hooks/useSorting';
import { titleProps } from '../../../../ontology/app';
import TableHeaderCell from '../../../topologies/Table/TableHeaderCell';

interface SortableHeaderProps {
  children: string | ReactNode;
  sortOptions: SortProps[];
}

const SortableHeader = ({
  children,
  sortOptions,
}: SortableHeaderProps): JSX.Element => {
  const { setCollectionResource } = useCollectionOptions();
  const [current, clickHandler] = React.useMemo(() => {
    const currentOption = sortOptions.find((option) => option.selected);
    const currentIndex = currentOption ? sortOptions.indexOf(currentOption) : -1;
    const nextOption = sortOptions[((currentIndex + 1) % sortOptions.length)];

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setCollectionResource(rdf.namedNode(nextOption.url));
    };

    return [currentOption, onClick];
  }, [sortOptions, setCollectionResource]);

  return (
    <TableHeaderCell>
      <button
        type="button"
        onClick={clickHandler}
      >
        {children}
        {' '}
        {current && <FontAwesome name={`sort-${current.direction}`} />}
      </button>
    </TableHeaderCell>
  );
};

const TableHeadCell = ({
  children,
  sortOptions,
}: PropsWithChildren<{
  sortOptions: SortProps[]
}>) => {

  if (sortOptions?.length > 0) {
    return (
      <SortableHeader sortOptions={sortOptions}>
        {children}
      </SortableHeader>
    );
  }

  return (
    <TableHeaderCell>
      {children}
    </TableHeaderCell>
  );
};

const TableHeadCellName = ({
  property,
}: {
  property: NamedNode,
}) => {
  const [name] = useStrings(property, titleProps);

  return (
    <React.Fragment>
      {name}
    </React.Fragment>
  );
};

const TableHeadCells: FC<{ columns?: Column[] }> = ({ columns: columnsFromProp }) => {
  const {
    columns,
    sortOptions,
  } = useCollectionOptions();

  if (columnsFromProp) {
    return (
      <React.Fragment>
        {columnsFromProp?.map((column) => (
          <TableHeadCell
            key={column.property.value}
            sortOptions={sortOptions.filter((option) => option.item === (column.sortProperty ?? column.property))}
          >
            {column.label}
          </TableHeadCell>
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {columns?.map((property) => (
        <TableHeadCell
          key={property.value}
          sortOptions={sortOptions.filter((option) => option.item === property)}
        >
          <TableHeadCellName property={property} />
        </TableHeadCell>
      ))}
    </React.Fragment>
  );
};

export default TableHeadCells;
