import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';
import {
  SomeTerm,
  isNamedNode,
  isSomeTerm,
} from '@ontologies/core';
import * as schema from '@ontologies/schema';
import clsx from 'clsx';
import {
  useBooleans,
  useDataInvalidation,
  useLRS,
} from 'link-redux';
import React, { SyntheticEvent } from 'react';

import { entityIsLoaded } from '../../../lib/data';
import FormFieldHelper from '../../FormField/FormFieldHelper';
import { LoadingRow } from '../../Loading';
import { isResource } from '../../../lib/typeCheckers';
import libro from '../../../../ontology/libro';
import ontola from '../../../../ontology/ontola';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputValue } from '../../FormField/FormFieldTypes';
import {
  fieldInputCID,
  fieldInputSelectCID,
  useFormStyles,
} from '../../FormField/UseFormStyles';
import HiddenRequiredInput from '../Input/HiddenRequiredInput';
import useAsyncFieldOptions from '../../Form/hooks/useAsyncFieldOptions';
import form from '../../../../ontology/form';

import FullWidthPopper from './FullWidthPopper';
import { emptyMessage } from './lib/emptyMessage';
import { filterOptions } from './lib/filterOptions';
import { sortByGroup } from './lib/sortByGroup';
import { useItemToString } from './lib/useItemToString';
import useRenderOption from './lib/useRenderOption';
import SelectList from './SelectList';
import useSelectStyles from './useSelectStyles';
import VirtualizedSelect from './VirtualizedSelect';

const VIRTUALIZATION_THRESHOLD = 10;

const RenderInput = (params: AutocompleteRenderInputParams) => {
  const classes = useSelectStyles();

  const inputProps = {
    ...params,
    InputProps: {
      ...params.InputProps,
      endAdornment: (
        <div className="MuiAutocomplete-endAdornment">
          {(params.InputProps.endAdornment as any)?.props?.children}
        </div>
      ),
    },
  };

  return (
    <TextField
      {...inputProps}
      className={classes.input}
      variant="outlined"
    />
  );
};

const SelectInputField: React.FC = () => {
  const {
    fieldShape,
    inputErrors,
    name,
    onChange,
    renderProp,
    values,
  } = React.useContext(formFieldContext);
  const multiple = fieldShape.maxCount && fieldShape.maxCount > 1;

  const lrs = useLRS();
  const classes = useSelectStyles();
  const formClasses = useFormStyles();
  const [open, setOpen] = React.useState(false);
  const itemToString = useItemToString(renderProp);
  const renderOption = useRenderOption(formClasses.fieldListElement, renderProp);
  const [inputValue, setInputValue] = React.useState(multiple ? '' : itemToString(values[0]?.value));
  const [grouped] = useBooleans(form.groupedOptions);
  const {
    loading,
    nullable,
    options,
    searchable,
  } = useAsyncFieldOptions(fieldShape.shIn, inputValue);

  const sortedOptions = React.useMemo(() => (
    grouped ? options.sort(sortByGroup(lrs)) : options
  ), [options]);

  const handleInputValueChange = React.useCallback<(event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void>(
    (_, newValue) => {
      setInputValue(newValue);
    }, [setInputValue]);

  useDataInvalidation(options.filter(isResource));
  const groupBy = React.useCallback((option: SomeTerm) => {
    const group = isNamedNode(option) ? lrs.getResourceProperty(option, ontola.groupBy) : undefined;
    const groupName = isNamedNode(group) ? lrs.getResourceProperty(group, schema.name) : undefined;

    return groupName?.value ?? '';
  }, []);

  const valueProps = React.useMemo(() => {
    const filteredValues = values.filter(isSomeTerm).filter((term) => term.value.length > 0);

    return multiple ? {
      filterSelectedOptions: true,
      multiple: true,
      value: filteredValues,
    } : {
      multiple: false,
      value: filteredValues[0] ?? null,
    };
  }, [multiple, values]);

  const handleChange = React.useCallback<(e: SyntheticEvent<Element, Event>, v: InputValue | InputValue[] | null) => void>((e, v) => {
    e.preventDefault();
    setInputValue('');

    if (multiple) {
      onChange((v as InputValue[]).filter(isSomeTerm));
    } else {
      if (isSomeTerm(v)) {
        onChange([v]);
      } else if (nullable) {
        onChange([libro.null]);
      } else {
        onChange([]);
      }
    }
  }, [multiple, nullable, onChange]);

  React.useEffect(() => {
    if (open) {
      handleInputValueChange(null as unknown as SyntheticEvent, '', 'reset');
    }
  }, [open]);

  const freshValues = values.filter((value) => isNamedNode(value) && !entityIsLoaded(lrs, value));

  if (__CLIENT__ && freshValues.length > 0) {
    freshValues.filter(isNamedNode).forEach((value) => {
      lrs.queueEntity(value);
    });

    return <LoadingRow />;
  }

  const virtualized = !grouped && options.length > VIRTUALIZATION_THRESHOLD;
  const className = clsx({
    [classes.wrapper]: true,
    [formClasses.fieldInput]: true,
    [fieldInputCID]: true,
    [fieldInputSelectCID]: true,
  });

  return (
    <React.Fragment>
      <div className={className}>
        <Autocomplete
          disableListWrap
          openOnFocus
          ListboxComponent={virtualized ? VirtualizedSelect : SelectList}
          PopperComponent={FullWidthPopper}
          disableClearable={fieldShape.required && !nullable}
          disabled={!fieldShape.shIn}
          filterOptions={searchable ? (opts: SomeTerm[]) => opts : filterOptions}
          getOptionLabel={itemToString}
          groupBy={grouped ? groupBy : undefined}
          id={name}
          inputValue={inputValue}
          loading={loading}
          noOptionsText={emptyMessage(searchable, inputValue)}
          options={sortedOptions}
          renderInput={RenderInput}
          renderOption={renderOption}
          onChange={handleChange}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={handleInputValueChange}
          onOpen={() => {
            setOpen(true);
          }}
          {...valueProps}
        />
      </div>
      {fieldShape.required && (
        <HiddenRequiredInput
          name={name}
          value={values[0]?.value}
        />
      )}
      <FormFieldHelper error={inputErrors?.[0]} />
    </React.Fragment>
  );
};

export default SelectInputField;
