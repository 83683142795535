import { SomeTerm } from '@ontologies/core';
import * as rdfx from '@ontologies/rdf';
import {
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, {
  FC,
  FunctionComponent,
} from 'react';

import form from '../../../../ontology/form';
import FormField from '../../FormField/FormField';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import { InputType } from '../../FormInput/Input/Input';
import InputElement from '../../FormInput/Input/InputElement';
import useFormField from '../../Form/hooks/useFormField';

const getInputType = (type: SomeTerm): InputType => {
  if (type === form.EmailInput) {
    return InputType.Email;
  }

  if (type === form.TextAreaInput) {
    return InputType.Textarea;
  }

  if (type === form.MarkdownInput) {
    return InputType.Markdown;
  }

  return InputType.Text;
};

const TextFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: true,
  });
  const [type] = useGlobalIds(subject, rdfx.type);
  const TextInput = React.useCallback<FunctionComponent<InputComponentProps>>((inputProps) => (
    <InputElement
      {...inputProps}
      type={getInputType(type)}
    />
  ), [type]);

  return (
    <FormField
      {...fieldProps}
      inputComponent={TextInput}
    />
  );
};

export default TextFormField;
