import rdf, { SomeTerm } from '@ontologies/core';

import ontola from '../../../../ontology/ontola';

import { invisibleStatusIds } from './useVisibleActions';

export const invalidStatusIds = [
  ...invisibleStatusIds,
  rdf.id(ontola.LockedActionStatus),
];

export const isInvalidActionStatus = (actionStatus: SomeTerm | undefined): boolean => invalidStatusIds.includes(rdf.id(
  actionStatus));
