import Slider from '@mui/material/Slider';
import { makeStyles } from '@mui/styles';
import { useProperty } from 'link-redux';
import React, { EventHandler } from 'react';
import Box from '@mui/material/Box';
import rdf from '@ontologies/core';
import { SliderUnstyledClasses } from '@mui/base/SliderUnstyled/sliderUnstyledClasses';

import { SHADOW_LIGHT } from '../../../theme/base/shadows';
import { tryParseInt } from '../../../lib/numbers';
import { BreakPoints, LibroTheme } from '../../../theme/types';
import ontola from '../../../../ontology/ontola';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import Button, { ButtonVariant } from '../../Button';

import HiddenRequiredInput from './HiddenRequiredInput';

const HALF = 0.5;
const SliderSize = 8;
const ThumbFontSize = 20;
const ThumbOpacity = 0.2;
const ThumpSizeModifier = 3;
const ThumbSize = SliderSize * ThumpSizeModifier;

const useSliderOverrideStyles = makeStyles<LibroTheme>((theme) => ({
  mark: {
    '&:not(&[data-index="0"])': {
      transform: `translateY(-${SliderSize}px) translateX(-${SliderSize}px)`,
    },
    backgroundColor: theme.palette.grey.xLight,
    borderRadius: '50%',
    height: `calc(${SliderSize}px * 2)`,
    transform: 'translateY(-8px)',
    width: `calc(${SliderSize}px * 2)`,
  },
  markLabel: {
    [theme.breakpoints.down(BreakPoints.Medium)]: {
      '&[data-index="1"]': {
        transform: 'translateX(-100%)',
      },
      transform: 'translateX(0%)',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginTop: '.5rem',
  },
  rail: {
    backgroundColor: theme.palette.grey.xLight,
    borderRadius: theme.shape.borderRadius,
    height: SliderSize,
    opacity: 1,
  },
  root: {
    filter: `drop-shadow(${SHADOW_LIGHT})`,
    height: SliderSize,
    marginTop: '.5rem',
  },
  thumb: (present) => ({
    '&:hover': {
      fontSize: present ? undefined : ThumbFontSize,
    },
    height: ThumbSize,
    left: present ? undefined : '50%',
    opacity: present ? undefined : ThumbOpacity,
    width: ThumbSize,
  }),
}));

const getAriaValueText = (v: number) => Number.isNaN(v) ? '⭤' : v.toString();

const SliderInput: React.FC<InputComponentProps> = ({
  inputValue,
  onChange,
}) => {
  const {
    fieldShape,
    name,
    onBlur,
    onFocus,
  } = React.useContext(formFieldContext);
  const currentValue = tryParseInt(inputValue);
  const overrideClasses = useSliderOverrideStyles(!!currentValue) as SliderUnstyledClasses;
  const {
    maxInclusive,
    minInclusive,
    required,
    minCount,
  } = fieldShape;

  const [maxInclusiveLabel] = useProperty(ontola.maxInclusiveLabel);
  const [minInclusiveLabel] = useProperty(ontola.minInclusiveLabel);

  const handleReset: EventHandler<any> = React.useCallback((e) => {
    e.preventDefault();
    onChange(rdf.literal(''));
  }, [onChange]);

  const handleChange = React.useCallback((e: any, val: any) => {
    e.preventDefault();
    onChange(val);
  }, [onChange]);

  if (maxInclusive === undefined || minInclusive === undefined) {
    return null;
  }

  const defaultMarks = [{
    label: minInclusiveLabel?.value ?? minInclusive,
    value: minInclusive,
  }, {
    label: maxInclusiveLabel?.value ?? maxInclusive,
    value: maxInclusive,
  }];
  const defaultValue = minInclusive + (maxInclusive - minInclusive) * HALF;

  return (
    <React.Fragment>
      <Slider
        aria-labelledby="discrete-slider-custom"
        classes={overrideClasses}
        getAriaValueText={getAriaValueText}
        marks={defaultMarks}
        max={maxInclusive}
        min={minInclusive}
        track={false}
        value={currentValue ?? defaultValue}
        valueLabelDisplay="auto"
        valueLabelFormat={getAriaValueText}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
      />
      {!minCount && (
        <Box
          sx={{
            float: 'right',
            marginTop: '1em',
          }}
        >
          <Button
            small
            type="button"
            variant={ButtonVariant.Subtle}
            onClick={handleReset}
          >
            reset
          </Button>
        </Box>
      )}
      {required && (
        <HiddenRequiredInput
          name={name}
          value={inputValue?.value}
        />
      )}
    </React.Fragment>
  );
};

export default SliderInput;
