import * as as from '@ontologies/as';
import {
  useStrings,
  useTopology,
} from 'link-redux';
import React, { FC } from 'react';

import ContainerHeader from '../../topologies/Container/ContainerHeader';
import { cardTopology } from '../../topologies';
import CardHeader from '../Card/CardHeader';
import Heading, { HeadingSize } from '../Heading';

import { useCollectionOptions } from './CollectionContext';
import { HeaderFloat } from './HeaderFloat';

const CardCollectionHeader = ({ title }: {title?: string}): JSX.Element | null => {
  const { currentCollection } = useCollectionOptions();
  const [name] = useStrings(currentCollection, as.name);

  const filterRef = React.useRef(null);

  return (
    <CardHeader
      float={<HeaderFloat filterContainerRef={filterRef} />}
      title={title ?? name}
    >
      <div ref={filterRef} />
    </CardHeader>
  );
};

const ContainerCollectionHeader = ({ title }: {title?: string}): JSX.Element | null => {
  const {
    currentCollection,
  } = useCollectionOptions();
  const filterRef = React.useRef(null);
  const [name] = useStrings(currentCollection, as.name);

  return (
    <React.Fragment>
      <ContainerHeader
        float={<HeaderFloat filterContainerRef={filterRef} />}
      >
        <Heading size={HeadingSize.LG}>
          {title ?? name}
        </Heading>
      </ContainerHeader>
      <div ref={filterRef} />
    </React.Fragment>
  );
};

const CollectionHeader: FC<{title?: string}> = ({ title }) => {
  const topology = useTopology();

  if (topology === cardTopology) {
    return <CardCollectionHeader title={title} />;
  }

  return <ContainerCollectionHeader title={title} />;
};

export default CollectionHeader;
