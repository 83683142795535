import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { CONTAINER_ELEMENT } from '../../lib/config';
import { LibroTheme } from '../../theme/types';
import Page from '../../topologies/Page';

import SkipNavigation from './SkipNavigation';
import NetworkStatusIndicator from './NetworkStatusIndicator';

export interface ContentFrameProps {
  children: React.ReactNode;
  header: FC;
  title?: string;
}

const useStyles = makeStyles<LibroTheme>((theme) => ({
  appContainer: {
    '& #start-of-content': {
      flex: 1,
      position: 'relative',
    },
    '& .preloader': {
      backgroundColor: theme.palette.background.default,
      opacity: 1,
      top: 0,
      zIndex: theme.zIndexLoader,
    },
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const ContentFrame = ({
  children,
  header: Header,
  title,
}: ContentFrameProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Page>
      <Helmet
        defaultTitle={title}
        titleTemplate={title ? `%s - ${title}` : '%s'}
      />
      <SkipNavigation />
      <div
        className={classes.appContainer}
        id={CONTAINER_ELEMENT}
      >
        <Header />
        <NetworkStatusIndicator />
        <div id="start-of-content">
          <main role="main">
            {children}
          </main>
        </div>
      </div>
    </Page>
  );
};

export default ContentFrame;
