import React from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router';

export type HandleTabChange = (_event: React.SyntheticEvent, newTab: string) => void;

const useTabBar = (defaultTab: string): [currentTab: string, handleTabChange: HandleTabChange] => {
  const location = useLocation();
  const navigate = useNavigate();
  const fragment = location.hash;
  const initialTab = fragment.replace('#', '').replace('%20', ' ');
  const [currentTab, setCurrentTab] = React.useState(initialTab || defaultTab);

  const handleTabChange: HandleTabChange = (_event, newTab) => {
    setCurrentTab(newTab);

    const newLocation = { ...location };
    newLocation.hash = newTab;
    navigate(newLocation);
  };

  return [currentTab, handleTabChange];
};

export default useTabBar;
