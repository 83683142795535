import { useFields } from 'link-redux';
import { fromLonLat } from 'ol/proj';
import React from 'react';

import app from '../../../../ontology/app';
import teamGL from '../../../../ontology/teamGL';
import { tryParseFloat } from '../../../lib/numbers';
import { ViewProps } from '../lib/types';

export const DEFAULT_LAT = 52.1344;
export const DEFAULT_LON = 5.1917;
export const DEFAULT_ZOOM = 6.8;

const useView = () => {
  const [zoomProp] = useFields(app.c_a, teamGL.zoom);
  const zoom = tryParseFloat(zoomProp);
  const [latProp] = useFields(app.c_a, teamGL.centerLat);
  const centerLat = tryParseFloat(latProp);
  const [lonProp] = useFields(app.c_a, teamGL.centerLon);
  const centerLon = tryParseFloat(lonProp);

  return React.useState<ViewProps>({
    center: fromLonLat([centerLon ?? DEFAULT_LON, centerLat ?? DEFAULT_LAT]),
    zoom: zoom ?? DEFAULT_ZOOM,
  });
};

export default useView;
