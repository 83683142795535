import React from 'react';
import { Helmet } from 'react-helmet-async';

import CardHeader, { CardHeaderProps } from './CardHeader';

const CardMainHeader = (props: React.PropsWithChildren<CardHeaderProps>): JSX.Element => (
  <React.Fragment>
    <Helmet>
      <title>
        {props.title}
      </title>
    </Helmet>
    <CardHeader {...props} />
  </React.Fragment>
);

export default CardMainHeader;
