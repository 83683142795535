import React from 'react';

import { getMetaContent } from '../../../lib/libro/dom';

const useMapboxTileUrl = () =>
  React.useMemo(
    () => getMetaContent('mapboxTileURL'),
    [],
  );

export default useMapboxTileUrl;
