import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { appName } from '../../lib/config';
import Navbar from '../../topologies/Navbar';
import { Section } from '../App/typescript';
import { NavbarItemInterface } from '../Navbar/NavbarItem';
import NavbarLeftItems from '../Navbar/NavbarLeftItems';
import NavbarMenu from '../Navbar/NavbarMenu';
import NavbarSearch from '../Navbar/NavbarSearch';
import NavbarSignIn from '../Navbar/NavbarSignIn';

import glappLogo from './logos/glapp.svg';
import DeepCanvassingLogo from './logos/deep-canvassing.png';

const logos: Record<string, string> = {
  deep_canvassing: DeepCanvassingLogo,
  glapp: glappLogo,
};

const useStyles = makeStyles(() => ({
  logo: {
    flexBasis: '37em',
    textAlign: 'center',
  },
  logoSmall: {
    '& img': {
      maxHeight: '2rem!important',
    },
    width: '10em',
  },
  right: {
    display: 'flex',
    flexBasis: '100%',
    flexShrink: 1,
    height: '100%',
  },
  rightGrow: {
    flexGrow: 1,
  },
}));

interface GroenLinksHeaderProps {
  primaryItems: NavbarItemInterface[];
  secondaryItems: NavbarItemInterface[];
}

const GroenLinksHeader: FC<GroenLinksHeaderProps> = ({ primaryItems, secondaryItems }) => {
  const classes = useStyles();

  return (
    <Navbar>
      <NavbarLeftItems
        menuItems={primaryItems}
      />
      <Link
        className={classes.logo}
        to="/"
      >
        <Box
          alt=""
          component="img"
          src={logos[appName]}
          sx={{ paddingY: '0.5em' }}
        />
      </Link>
      <div className={classes.right}>
        <div className={classes.rightGrow} />
        {GLOBALS.section === Section.Admin && <NavbarSearch />}
        <NavbarSignIn />
        <NavbarMenu
          primaryItems={primaryItems}
          secondaryItems={secondaryItems}
        />
      </div>
    </Navbar>
  );
};

export default GroenLinksHeader;
