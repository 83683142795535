import * as schema from '@ontologies/schema';
import IconButton from '@mui/material/IconButton';
import { useGlobalIds } from 'link-redux';
import React, { FC } from 'react';
import FontAwesome from 'react-fontawesome';

import { useCollectionOptions } from './CollectionContext';

const CollectionDownloadButton: FC = () => {
  const { currentCollection } = useCollectionOptions();
  const [downloadLink] = useGlobalIds(currentCollection, schema.downloadUrl);

  if (!downloadLink) {
    return null;
  }

  return (
    <IconButton
      href={downloadLink.value}
      size="small"
      target="_blank"
      title="Download"
    >
      <FontAwesome name="download" />
    </IconButton>
  );
};

export default CollectionDownloadButton;
