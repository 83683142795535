import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { LibroTheme } from '../../theme/types';

const useStyles = makeStyles<LibroTheme>(({
  spinner: {
    color: 'rgb(208, 2, 91)',
  },
}));

const Spinner = (): JSX.Element => {
  const classes = useStyles();

  return (
    <CircularProgress className={classes.spinner} />
  );
};

export default Spinner;
