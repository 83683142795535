import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import { MultipleEmailInput } from '../../FormInput/Input/MultipleEmailInput';
import useFormField from '../../Form/hooks/useFormField';

const MultipleEmailFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: false,
  });

  return (
    <FormField
      combinedComponent
      {...fieldProps}
      inputComponent={MultipleEmailInput}
    />
  );
};

export default MultipleEmailFormField;
