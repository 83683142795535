import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { LibroTheme } from '../../theme/types';
import { isString } from '../../lib/typeCheckers';

import { FormFieldError } from './FormFieldTypes';

interface PropTypes {
  helperText?: string | React.ReactNode;
  error?: FormFieldError;
  right?: React.ReactNode;
}

export const useFieldErrorStyles = makeStyles<LibroTheme>((theme) => ({
  fieldError: {
    color: theme.palette.error.main,
    display: 'block',
  },
}));

const useStyles = makeStyles((theme: LibroTheme) => ({
  fieldHelper: {
    color: theme.palette.grey.main,
    display: 'flex',
    fontSize: '.8em',
    justifyContent: 'space-between',
    paddingRight: '.8rem',
  },
  fieldHelperRight: {
    marginLeft: 'auto',
  },
}));

const FieldHelper: React.FC<PropTypes> = ({
  helperText,
  error,
  right,
}) => {
  const classes = useStyles();
  const className = clsx({
    [classes.fieldHelper]: true,
  });
  const errorClassName = useFieldErrorStyles();

  if (!helperText && !error) {
    return null;
  }

  const err = error && (
    <span className={errorClassName.fieldError}>
      {isString(error) ? error : error.error}
    </span>
  );

  return (
    <div className={className}>
      {err || helperText}
      {right && (
        <span className={classes.fieldHelperRight}>
          {right}
        </span>
      )}
    </div>
  );
};

export default FieldHelper;
