import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import LinkedRenderStore, { RENDER_CLASS_NAME } from 'link-lib';
import React from 'react';

import ll from '../../../ontology/ll';
import Loading, {
  loadingParagraphCID,
  loadingStyles,
} from '../../components/Loading';
import Spinner from '../../components/Loading/Spinner';
import { LibroTheme } from '../../theme/types';
import {
  formFooterTopology,
  selectTopology,
  selectedValueTopology,
} from '../../topologies';

const useStyles = makeStyles<LibroTheme>((theme) => ({
  ...loadingStyles(theme),
  loadingSelect: {
    alignItems: 'center',
    display: 'flex',
    height: 42,
    padding: '.5em',
    width: '100%',
  },
  loadingSelectParagraph: {
    height: '1em',
    marginBottom: '1em',
    width: '100%',
  },
}));

export const LoadingSelect: React.FC<{ style: any }> = ({ style }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.loadingSelect}
      style={style}
    >
      <div
        className={clsx(
          loadingParagraphCID,
          classes.loadingSelectParagraph,
          classes.loadingBackground,
        )}
      />
    </div>
  );
};

export default [
  ...LinkedRenderStore.registerRenderer(
    LoadingSelect,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    selectTopology,
  ),
  ...LinkedRenderStore.registerRenderer(
    Loading,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      formFooterTopology,
      selectedValueTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    Spinner,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    formFooterTopology,
  ),
];
