import React, {
  FC,
  ReactNode,
} from 'react';

import CollectionHeader from '../CollectionHeader';
import CollectionPagination from '../Pagination/CollectionPagination';

const DefaultFrame: FC<{children: ReactNode}> = ({ children }) => (
  <React.Fragment>
    <CollectionHeader />
    {children}
    <CollectionPagination />
  </React.Fragment>
);

export default DefaultFrame;
