import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import * as schema from '@ontologies/schema';
import { FormApi } from 'final-form';
import { SomeNode } from 'link-lib';
import {
  useStrings,
  useTempClones,
} from 'link-redux';
import React, {
  EventHandler,
  SyntheticEvent,
} from 'react';

import { error } from '../../lib/logging';
import FormFooter from '../../topologies/FormFooter';
import { convertKeysAtoB } from '../../lib/data';
import { LibroTheme } from '../../theme/types';
import {
  FormValues,
  SubmitHandler,
} from '../Action/hooks/useSubmitHandler';
import { InputValue } from '../FormField/FormFieldTypes';
import { LoadingGridContent } from '../Loading';

import FormFooterRight from './FooterRight';
import FormBody from './FormBody';
import FormErrors from './FormErrors';
import { useDependencies } from './hooks/useDependencies';
import { useFormCancel } from './hooks/useFormCancel';
import useInitialValues from './hooks/useInitialValues';
import Form from './Form';
import useSubmissionErrors from './hooks/useSubmissionErrors';

const subscription = {
  submitting: true,
};

export interface CalculatedEntryPointProps {
  action?: SomeNode;
  actionBody?: SomeNode;
  formID: string;
  httpMethod?: string;
  object?: SomeNode;
  onSubmit?: SubmitHandler;
  url?: string;
}

export interface ProvidedEntryPointProps {
  autoSubmit: boolean;
  autofocusForm: boolean;
  className?: string;
  errorResponse?: SomeNode;
  formID: string;
  formInstance?: FormApi;
  onKeyUp?: EventHandler<SyntheticEvent<unknown>>;
  onCancel?: (event: SyntheticEvent<unknown, Event>) => void;
  onLoad?: () => React.ReactNode;
  sessionStore: Storage;
  entryPoint: SomeNode;
}

export type EntryPointFormProps = CalculatedEntryPointProps & ProvidedEntryPointProps;

const formDataFromValues = (values?: FormValues, formApi?: FormApi<FormValues>) => {
  let formData = {};

  if (formApi && values) {
    const registeredValues = formApi
      .getRegisteredFields()
      .reduce((res: Record<string, InputValue>, key: string) => {
        if (!Object.keys(values).includes(key)) {
          return res;
        }

        return {
          ...res,
          [key]: values[key],
        };
      }, {});

    formData = convertKeysAtoB(registeredValues);
  }

  return formData;
};

const EntryPointForm: React.FC<EntryPointFormProps> = ({
  action,
  actionBody,
  autofocusForm,
  autoSubmit,
  className,
  entryPoint,
  errorResponse,
  formID,
  formInstance,
  httpMethod,
  object,
  onKeyUp,
  onCancel,
  onLoad,
  onSubmit,
  sessionStore,
  url,
}) => {
  const theme = useTheme<LibroTheme>();
  const handleCancel = useFormCancel(formID, onCancel);
  const [name] = useStrings(entryPoint, schema.name);
  const screenIsNarrow = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, objectDependencies] = useDependencies(
    sessionStore,
    actionBody,
    object,
    formID,
  );
  const [submitCount, setSubmitCount] = React.useState(0);

  // Ensure we can modify the object to persist fields while editing, without changing global state.
  const [clonedObject] = useTempClones(objectDependencies);

  const initialValues = useInitialValues(
    loading || ((actionBody && object) && !clonedObject),
    sessionStore,
    actionBody,
    clonedObject,
    formID,
    submitCount,
  );
  const [submissionErrors, clearErrors] = useSubmissionErrors(errorResponse);

  const submitHandler = React.useCallback<SubmitHandler>((formData, formApi, retrySubmit) => {
    clearErrors();

    if (!onSubmit) {
      throw new Error(`No submit handler provided for ${action?.value}`);
    }

    return onSubmit(formData, formApi, retrySubmit).then(() => {
      setSubmitCount((previous) => previous + 1);
    });
  }, [clearErrors]);
  const submitHandlerWithRetry = React.useCallback((values?: FormValues, formApi?: FormApi<FormValues>): Promise<any> => {
    const formData = formDataFromValues(values, formApi);

    return submitHandler(formData, formApi, () => submitHandler(formData, formApi)).catch(error);
  }, [onSubmit, sessionStorage, formID]);

  if (loading || !initialValues) {
    if (onLoad) {
      return (
        <React.Fragment>
          {onLoad()}
        </React.Fragment>
      );
    }

    return <LoadingGridContent />;
  }

  return (
    <Form
      autoSubmit={autoSubmit}
      form={formInstance}
      formID={formID}
      initialValues={initialValues}
      subscription={subscription}
      onSubmit={submitHandlerWithRetry}
    >
      {({ handleSubmit, submitting }) => (
        <FormBody
          action={url && new URL(url).pathname}
          autofocusForm={autofocusForm}
          className={className}
          entryPoint={entryPoint}
          formID={formID}
          handleSubmit={handleSubmit}
          method={httpMethod}
          object={clonedObject}
          sessionStore={sessionStore}
          submissionErrors={submissionErrors}
          onKeyUp={onKeyUp}
        >
          <FormErrors errorResponse={errorResponse} />
          <FormFooter>
            <FormFooterRight
              crammed={screenIsNarrow}
              loading={submitting}
              submitLabel={name}
              onCancel={handleCancel}
            />
          </FormFooter>
        </FormBody>
      )}
    </Form>
  );
};

export default EntryPointForm;

