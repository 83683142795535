import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { t } from 'i18next';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import { LibroTheme } from '../../../theme/types';

interface Props {
  isDragActive: boolean;
  overlay?: boolean;
}

const useStyles = makeStyles((theme: LibroTheme) => ({
  icon: {
    '&.fa': {
      fontSize: '3rem',
    },
  },
  messages: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  overlay: {
    backgroundColor: theme.palette.transparent.light85,
    borderStyle: 'dashed',
    borderWidth: '1px',
    bottom: 0,
    left: 0,
    minHeight: '6em',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: theme.zIndexOverlay,
  },
}));

const DropzoneOverlay: React.FC<Props> = ({ isDragActive, overlay }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.messages]: true,
        [classes.overlay]: overlay,
      })}
    >
      <FontAwesome
        className={classes.icon}
        name="cloud-upload"
      />
      {isDragActive
        ? t('form.dropzone.hover')
        : t('form.dropzone.passive')}
    </div>
  );
};

export default DropzoneOverlay;
