import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';
import FontAwesome from 'react-fontawesome';

import { useStrippedMarkdown } from '../../hooks/useStrippedMarkdown';
import useOneClickProps from '../../components/Action/hooks/useOneClickProps';
import { isInvalidActionStatus } from '../../components/Action/hooks/useValidActions';
import Link, { LinkFeature } from '../../components/Link';
import { useShowDialog } from '../../hooks/useShowDialog';

const ActionTableButton: FC<SubjectProp> = ({
  subject,
}) => {
  const [actionStatus] = useGlobalIds(subject, schema.actionStatus);
  const [name] = useStrings(subject, schema.name);
  const [error] = useStrings(subject, schema.error);
  const {
    icon,
    loading,
    onClick,
  } = useOneClickProps(subject);
  const showDialog = useShowDialog(subject);

  const invalid = isInvalidActionStatus(actionStatus);
  const title = useStrippedMarkdown(invalid ? error : name);

  return (
    <Link
      disabled={invalid || loading}
      features={[LinkFeature.Bold]}
      title={title}
      to={subject.value}
      onClick={onClick ?? showDialog}
    >
      {icon ? (
        <FontAwesome
          name={icon}
          spin={loading}
          title={title}
        />
      ) : title}
    </Link>
  );
};

export default ActionTableButton;
