import { LibroTheme, MaterialStyleMap } from '../../types';

export default (theme: LibroTheme): MaterialStyleMap => ({
  MuiCssBaseline: {
    styleOverrides: {
      '::selection': {
        backgroundColor: 'pink',
        color: '#010101',
      },
      ':root': {
        '--accent-color':'#FFFFFF',
        '--navbar-background':'#FFFFFF',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
      'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
        display: 'block',
      },
      'blockquote, q': {
        quotes: 'none',
      },
      'blockquote:before, blockquote:after': {
        content: 'none',
      },
      body: {
        lineHeight: 'normal',
        margin: 0,
      },
      button: {
        background: 'none',
      },
      html: {
        '.ActionsBar': {
          '&:first-child': {
            borderLeft: '0 !important',
          },
          '.Button': {
            border: 'solid 1px rgb(230, 230, 230) !important',
            borderBottom: '0 !important',
            borderRight: '0 !important',
            flexGrow: 1,
            fontWeight: 'unset',
            marginTop: 0,
            textTransform: 'unset',
          },
          gap: 0,
        },
        '.Button--submit': {
          backgroundColor: theme.palette.primary.main,
        },
        '.CID-Card:': {
          borderRadius: 0,
        },
        '.Container--grid': {
          '&:first-child': {
            paddingTop: 0,
          },
          '.Widget': {
            '.CID-Card': {
              marginBottom: 0,
            },
            '.ContainerHeader': {
              marginTop: 0,
            },
          },
        },
        '.Markdown': {
          'h1, h2': {
            fontWeight: 900,
          },
        },
        backgroundColor: '#ede4e5',
        height: '100%',
        touchAction: 'manipulation',
        width: '100%',
      },
      'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, button, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video': {
        border: 0,
        fontSize: '100%',
        fontStyle: 'inherit',
        margin: 0,
        padding: 0,
        verticalAlign: 'baseline',
      },
      'ol, ul': {
        listStyleType: 'none',
      },
      p: {
        marginBottom: '1em',
      },
      'q:before, q:after': {
        content: 'none',
      },
      table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },
    },
  },
});
