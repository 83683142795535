import {
  Button,
  Icon,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';

import { LibroTheme } from '../../theme/types';

export interface NavbarLinkProps extends Pick<React.AriaAttributes, 'aria-controls' | 'aria-expanded' | 'aria-haspopup'> {
  icon?: string;
  label?: string | JSX.Element;
  onClick?: React.MouseEventHandler;
  title: string,
  path?: string;
}

const NavbarLink = React.forwardRef<HTMLElement, NavbarLinkProps>(({
  icon,
  label,
  onClick,
  title,
  path,
  ...ariaProps
}: NavbarLinkProps, ref): JSX.Element => {
  const theme = useTheme<LibroTheme>();
  const screenIsWide = useMediaQuery(theme.breakpoints.up(theme.appBar.iconBreakPoint));

  if (path === undefined && !onClick) {
    return (
      <div>
        {label}
      </div>
    );
  }

  const hideLabel = icon && !screenIsWide;

  const iconCom = icon ? (
    <Icon>
      <FontAwesome name={icon} />
    </Icon>
  ) : undefined;

  const baseProps = {
    'aria-controls': ariaProps['aria-controls'],
    'aria-expanded': ariaProps['aria-expanded'],
    'aria-haspopup': ariaProps['aria-haspopup'],
    'aria-label': title,
    color: 'inherit' as const as 'inherit',
    component: path ? NavLink : 'button',
    onClick,
    ref: ref as React.ForwardedRef<HTMLAnchorElement & HTMLButtonElement>,
    title: title,
    to: path,
  };

  if (hideLabel) {
    return (
      <IconButton
        sx={{
          borderRadius: 0,
          height: '100%', 
        }}
        {...baseProps}
      >
        {iconCom}
      </IconButton>
    );
  }

  return (
    <Button
      {...baseProps}
      startIcon={iconCom}
    >
      {label}
    </Button>
  );
});

export default NavbarLink;
