import { createNS } from '@ontologies/core';

const teamGL = createNS('http://glapp.nl/tgl#');

export default {
  ns: teamGL,

  /* classes */
  // eslint-disable-next-line sort-keys
  Address: teamGL('Address'),
  Badge: teamGL('Badge'),
  Campaign: teamGL('Campaign'),
  ContactedAction: teamGL('ContactedAction'),
  DashboardPage: teamGL('DashboardPage'),
  Department: teamGL('Department'),
  EarnedBadge: teamGL('EarnedBadge'),
  Event: teamGL('Event'),
  GlappHome: teamGL('GlappHome'),
  GlappStart: teamGL('GlappStart'),
  Group: teamGL('Group'),
  NewUser: teamGL('NewUser'),
  NotAvailableAction: teamGL('NotAvailableAction'),
  Participant: teamGL('Participant'),
  PostalCode: teamGL('PostalCode'),
  PotentialParticipant: teamGL('PotentialParticipant'),
  SignUpAction: teamGL('SignUpAction'),
  Street: teamGL('Street'),
  TargetProgress: teamGL('TargetProgress'),
  TryAgainAction: teamGL('TryAgainAction'),
  UnsubscribeAction: teamGL('UnsubscribeAction'),
  User: teamGL('User'),
  Volunteer: teamGL('Volunteer'),

  /* properties */
  activated: teamGL('activated'),
  active: teamGL('active'),
  activeDaysCount: teamGL('activeDaysCount'),
  activeVolunteersCount: teamGL('activeVolunteersCount'),
  activeVolunteersRatio: teamGL('activeVolunteersRatio'),
  activeVolunteersRatioTrend: teamGL('activeVolunteersRatioTrend'),
  address: teamGL('address'),
  admin: teamGL('admin'),
  app: teamGL('app'),
  badge: teamGL('badge'),
  centerLat: teamGL('centerLat'),
  centerLon: teamGL('centerLon'),
  createdAt: teamGL('createdAt'),
  current: teamGL('current'),
  dashboard: teamGL('dashboard'),
  default: teamGL('default'),
  department: teamGL('department'),
  departmentMemberships: teamGL('departmentMemberships'),
  departments: teamGL('departments'),
  desiredCount: teamGL('desiredCount'),
  didVote: teamGL('didVote'),
  doors: teamGL('doors'),
  doorsCount: teamGL('doorsCount'),
  earnedBadgesCount: teamGL('earnedBadgesCount'),
  engagement: teamGL('engagement'),
  eventStreets: teamGL('eventStreets'),
  eventType: teamGL('eventType'),
  events: teamGL('events'),
  fullAddress: teamGL('fullAddress'),
  futureEventsCount: teamGL('futureEventsCount'),
  glappUsedAt: teamGL('glappUsedAt'),
  goalKey: teamGL('goalKey'),
  goalTarget: teamGL('goalTarget'),
  groups: teamGL('groups'),
  importType: teamGL('importType'),
  inactiveVolunteersRatio: teamGL('inactiveVolunteersRatio'),
  inactiveVolunteersRatioTrend: teamGL('inactiveVolunteersRatioTrend'),
  inputType: teamGL('inputType'),
  key: teamGL('key'),
  lastActivityAt: teamGL('lastActivityAt'),
  lastName: teamGL('lastName'),
  lastVisitedAt: teamGL('lastVisitedAt'),
  maxPriority: teamGL('maxPriority'),
  meanPriority: teamGL('meanPriority'),
  memberships: teamGL('memberships'),
  minPriority: teamGL('minPriority'),
  multipleManagedDepartments: teamGL('multipleManagedDepartments'),
  newVolunteersCount: teamGL('newVolunteersCount'),
  nextDoorsTarget: teamGL('nextDoorsTarget'),
  number: teamGL('number'),
  order: teamGL('order'),
  organizer: teamGL('organizer'),
  participantStatus: teamGL('participantStatus'),
  participants: teamGL('participants'),
  participantsCount: teamGL('participantsCount'),
  pendingAddresses: teamGL('pendingAddresses'),
  pendingAddressesCount: teamGL('pendingAddressesCount'),
  postalDigits: teamGL('postalDigits'),
  postalRangeType: teamGL('postalRangeType'),
  postalRanges: teamGL('postalRanges'),
  potentialParticipants: teamGL('potentialParticipants'),
  prioritiesCount: teamGL('prioritiesCount'),
  priority: teamGL('priority'),
  remarks: teamGL('remarks'),
  required: teamGL('required'),
  result: teamGL('result'),
  signedUp: teamGL('signedUp'),
  signedUpAt: teamGL('signedUpAt'),
  slug: teamGL('slug'),
  source: teamGL('source'),
  status: teamGL('status'),
  street: teamGL('street'),
  streets: teamGL('streets'),
  subDepartments: teamGL('subDepartments'),
  subject: teamGL('subject'),
  target: teamGL('target'),
  telephone: teamGL('telephone'),
  totalActiveVolunteersCount: teamGL('totalActiveVolunteersCount'),
  totalFutureEventsCount: teamGL('totalFutureEventsCount'),
  totalFutureEventsCountTrend: teamGL('totalFutureEventsCountTrend'),
  totalGroupsCount: teamGL('totalGroupsCount'),
  totalGroupsCountTrend: teamGL('totalGroupsCountTrend'),
  totalInactiveVolunteersCount: teamGL('totalInactiveVolunteersCount'),
  totalNewVolunteersCount: teamGL('totalNewVolunteersCount'),
  totalNewVolunteersCountTrend: teamGL('totalNewVolunteersCountTrend'),
  totalRecentVolunteersCount: teamGL('totalRecentVolunteersCount'),
  totalVeryActiveVolunteersCount: teamGL('totalVeryActiveVolunteersCount'),
  totalVolunteersCount: teamGL('totalVolunteersCount'),
  totalVolunteersCountTrend: teamGL('totalVolunteersCountTrend'),
  user: teamGL('user'),
  userId: teamGL('userId'),
  veryActiveVolunteersRatio: teamGL('veryActiveVolunteersRatio'),
  veryActiveVolunteersRatioTrend: teamGL('veryActiveVolunteersRatioTrend'),
  visitsCount: teamGL('visitsCount'),
  volunteer: teamGL('volunteer'),
  volunteers: teamGL('volunteers'),
  volunteersCount: teamGL('volunteersCount'),
  zoom: teamGL('zoom'),
};
