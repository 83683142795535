import { t } from 'i18next';
import React, { FC } from 'react';

import { SignInFormLink } from '../SignInForm';
import { useCurrentActor } from '../../hooks/useCurrentActor';

import NavbarLink from './NavbarLink';

const NavbarSignIn: FC = () => {
  const { actorType } = useCurrentActor();

  if (actorType !== 'GuestUser') {
    return null;
  }

  return (
    <React.Fragment>
      <SignInFormLink
        Component={NavbarLink}
        label={t('auth.newSessionLink')}
      />
    </React.Fragment>
  );
};

export default NavbarSignIn;
