import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useLRS,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';
import { t } from 'i18next';

import FormFooter from '../topologies/FormFooter';
import CardContent from '../components/Card/CardContent';
import FormFooterRight from '../components/Form/FooterRight';
import Heading from '../components/Heading';
import HeadingContext from '../components/Heading/HeadingContext';
import Image from '../components/Image';
import Markdown from '../components/Markdown';
import { HideDialog } from '../middleware/actions';
import Card from '../topologies/Card';

const BadgeDialog: FC<SubjectProp> = ({ subject }) => {
  const lrs = useLRS();
  const [image] = useGlobalIds(subject, schema.image);
  const [name] = useStrings(subject, schema.name);
  const [description] = useStrings(subject, schema.description);
  const [text] = useStrings(subject, schema.text);

  return (
    <Card>
      <HeadingContext>
        <CardContent
          centered
          noSpacing
          style={{ maxWidth: '25em' }}
        >
          <Heading>
            {name}
          </Heading>
          <Image
            linkedProp={image}
            style={{
              marginBottom: '1em',
              maxWidth: '100%',
              width: '300px',
            }}
          />
          <Markdown text={description} />
          <Markdown text={text} />
        </CardContent>
      </HeadingContext>
      <FormFooter>
        <FormFooterRight
          submitLabel={t('badge.continue')}
          onSubmit={() => lrs.actions.get(HideDialog)()}
        />
      </FormFooter>
    </Card>
  );
};

export default BadgeDialog;
