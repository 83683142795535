import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import SliderInput from '../../FormInput/Input/SliderInput';
import useFormField from '../../Form/hooks/useFormField';

const SliderFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: true,
  });

  return (
    <FormField
      {...fieldProps}
      inputComponent={SliderInput}
    />
  );
};

export default SliderFormField;
