import { SubjectProp } from 'link-redux';
import React, {
  FC,
  FunctionComponent,
} from 'react';
import { Box } from '@mui/material/index';

import FormField from '../../FormField/FormField';
import useFormField from '../../Form/hooks/useFormField';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import InputElement from '../../FormInput/Input/InputElement';

const HiddenInput: FunctionComponent<InputComponentProps> = (props) => (
  <Box sx={{ display: 'none' }}>
    <InputElement {...props} />
  </Box>
);

const HiddenFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  return (
    <FormField
      {...fieldProps}
      description={undefined}
      helperText={undefined}
      inputComponent={HiddenInput}
      label={undefined}
    />
  );
};

export default HiddenFormField;
