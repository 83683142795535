import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import enableDevtools from '@ontola/link-devtools';
import rdfFactory from '@ontologies/core';
import type { History } from 'history';
import { LinkedRenderStore } from 'link-lib/dist-types/LinkedRenderStore';
import { RenderStoreProvider } from 'link-redux';
import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import generateLRS from '../../lib/libro/generateLRS';
import patchRequestInitGenerator from '../../lib/libro/monkey';
import { seedToSlice } from '../../lib/seed';
import DialogManager from '../DialogManager';
import HeadingContext from '../Heading/HeadingContext';
import SnackbarManager from '../Snackbar/SnackbarManager';
import views from '../../views';

import ContentFrame from './ContentFrame';
import ErrorBoundary from './ErrorBoundary';
import ScrollMemory from './ScrollMemory';
import ThemeProvider from './ThemeProvider';

export interface RouterProps {
  children?: ReactNode;
}

export interface AppProps {
  children: ReactNode;
  header: FC;
  location?: string,
  seed: string;
}

const createClientRouter = (history: History) => {
  const basename = __CLIENT__ && !__TEST__ && window.location.pathname.startsWith('/d/studio/viewer')
    ? 'd/studio/viewer'
    : '';

  return ({ children }: RouterProps) => (
    <HistoryRouter
      basename={basename}
      history={history}
    >
      {children}
    </HistoryRouter>
  );
};

const EMP_SYMBOL_MAP = {};

const App = ({
  children,
  header,
  seed,
}: AppProps): JSX.Element | null => {
  const [storedLRS, setStoredLRS] = useState<LinkedRenderStore<any> | null>();
  const [storedHistory, setStoredHistory] = useState<History | null>(null);

  useEffect(() => {
    generateLRS(
      seedToSlice(seed, EMP_SYMBOL_MAP),
      EMP_SYMBOL_MAP,
    ).then(({
      history,
      lrs,
    }) => {
      patchRequestInitGenerator(lrs);

      if (document.documentElement.lang) {
        (lrs.store as any).langPrefs.unshift(document.documentElement.lang);
      }

      window.EXEC_ACTIONS?.forEach((action) => {
        lrs.dispatch(rdfFactory.namedNode(action));
      });

      if (typeof window !== 'undefined') {
        enableDevtools(lrs);
        window.LRS = lrs;
      }

      setStoredLRS(lrs);
      setStoredHistory(history);
    });
  }, []);

  if (!storedHistory || !storedLRS) {
    return null;
  }

  storedLRS.registerAll(...views);

  const Router = createClientRouter(storedHistory);

  return (
    <HelmetProvider>
      <RenderStoreProvider value={storedLRS}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <CssBaseline />
            <HeadingContext>
              <Router>
                <ContentFrame header={header}>
                  <ErrorBoundary>
                    {children}
                  </ErrorBoundary>
                </ContentFrame>
                <ScrollMemory history={storedHistory} />
              </Router>
              <SnackbarManager />
              <DialogManager />
            </HeadingContext>
          </ThemeProvider>
        </StyledEngineProvider>
      </RenderStoreProvider>
    </HelmetProvider>
  );
};

export default App;
