import { SubjectProp } from 'link-redux';
import React from 'react';

import CollectionProvider, { CollectionProviderProps } from '../components/Collection/CollectionProvider';

const CollectionDialog = (props: CollectionProviderProps & SubjectProp): JSX.Element => (
  <CollectionProvider
    renderWhenEmpty
    {...props}
  />
);

export default CollectionDialog;
