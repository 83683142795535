import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import { isInvalidActionStatus } from '../../../components/Action/hooks/useValidActions';
import { useDoneHandler } from '../../../components/Action/lib/helpers';
import Button from '../../../components/Button';
import CardContent from '../../../components/Card/CardContent';
import EntryPointCardMain from '../../../components/EntryPoint/EntryPointCardMain';
import Heading from '../../../components/Heading';
import Markdown from '../../../components/Markdown';
import { SignInFormLink } from '../../../components/SignInForm';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';

const FormShow: FC<SubjectProp> = ({ subject }) => {
  const onDoneHandler = useDoneHandler(subject);

  const [actionStatus] = useGlobalIds(subject, schema.actionStatus);
  const [target] = useGlobalIds(subject, schema.target);
  const [name] = useStrings(subject, schema.name);
  const [error] = useStrings(subject, schema.error);

  if (isInvalidActionStatus(actionStatus)) {
    return (
      <Container>
        <CardMain>
          <CardContent endSpacing>
            <Heading>
              {name}
            </Heading>
            <Markdown text={error} />
            <SignInFormLink Component={Button} />
          </CardContent>
        </CardMain>
      </Container>
    );
  }

  return (
    <Container>
      <CardMain>
        <CardContent endSpacing>
          <Heading>
            {name}
          </Heading>
          <EntryPointCardMain
            subject={target}
            onDone={onDoneHandler}
          />
        </CardContent>
      </CardMain>
    </Container>
  );
};

export default FormShow;
