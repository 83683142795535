import {
  Literal,
  NamedNode,
  Node,
} from '@ontologies/core';
import {
  AttributeKey,
  SomeNode,
} from 'link-lib';

import app from '../../ontology/app';
import libro from '../../ontology/libro';

export const CopyToClipboard = new AttributeKey<(value: string) => void>(libro.actions.copyToClipboard.value);
export const ShowSnackbar = new AttributeKey<(message: Literal | string) => void>(libro.actions.snackbar.show.value);
export const ShowDialog = new AttributeKey<(resource: SomeNode, size?: string | null) => Promise<void>>(libro.actions.dialog.alert.value);
export const HideDialog = new AttributeKey<(resource?: SomeNode, done?: boolean) => void>(libro.actions.dialog.close.value);
export const LogOut = new AttributeKey<() => void>(libro.actions.logout.value);
export const Navigate = new AttributeKey<(resource: NamedNode, reload?: boolean) => void>(libro.actions.redirect.value);
export const OpenWindow = new AttributeKey<(url: string) => void>(libro.actions.window.open.value);
export const PlayAudio = new AttributeKey<(resource: NamedNode) => void>(libro.actions.playAudio.value);
export const PlayBeep = new AttributeKey<(opts?: { time?: number, steps?: number, frequency?: number }) => void>(libro.actions.playBeep.value);
export const StartSignIn = new AttributeKey<(redirectUrl?: NamedNode) => Promise<void>>(app.ns('startSignIn').value);
export const StartSignOut = new AttributeKey<(redirect?: NamedNode) => Promise<void>>(app.ns('startSignOut').value);
export const ChangePage = new AttributeKey<(subject: Node, newPage: Node) => void>(app.ns('changePage').value);
