import { t } from 'i18next';
import React, { FC } from 'react';

import NavbarLink from './NavbarLink';

const NavbarSearch: FC = () => (
  <NavbarLink
    icon="search"
    label={t('navbar.search')}
    path="/search"
    title={t('navbar.search')}
  />
);

export default NavbarSearch;
