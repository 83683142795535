import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import CheckboxesInput from '../../FormInput/Input/CheckboxesInput';
import useFormField from '../../Form/hooks/useFormField';

const CheckboxesFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  return (
    <FormField
      combinedComponent
      {...fieldProps}
      inputComponent={CheckboxesInput}
    />
  );
};

export default CheckboxesFormField;
