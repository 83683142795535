import * as schema from '@ontologies/schema';
import MUIContainer from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { useIds } from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React, { FC } from 'react';

import { LibroTheme } from '../../theme/types';

import Breadcrumb from './Breadcrumb';

const useStyles = makeStyles((theme: LibroTheme) => ({
  breadcrumbsBar: {
    backgroundColor: theme.palette.grey.xxLight,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    width: '100%',
    ...(theme.appBar.background === 'white' ? {
      borderTop: `1px solid ${theme.palette.divider}`,
    } : {}),
  },
  flex: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    gap: '.3rem',
  },
}));

const BreadcrumbsBar: FC<SubjectProp> = ({ subject }) => {
  const classes = useStyles();
  const [parent] = useIds(subject, schema.isPartOf);

  if (!parent) {
    return null;
  }

  return (
    <nav
      aria-label="Breadcrumb"
      className={classes.breadcrumbsBar}
    >
      <MUIContainer maxWidth="xl">
        <div className={classes.flex}>
          <Breadcrumb
            first
            subject={parent}
          />
        </div>
      </MUIContainer>
    </nav>
  );
};

export default BreadcrumbsBar;
