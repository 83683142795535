import React, { FC } from 'react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import theme from '../../theme';

const ThemeProvider: FC = ({ children }) => (
  <MaterialThemeProvider theme={theme}>
    {children}
  </MaterialThemeProvider>
);

export default ThemeProvider;
