import rdf from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { LinkedActionResponse } from 'link-lib';
import {
  SubjectProp,
  useGlobalIds,
  useLRS,
  useStrings,
} from 'link-redux';
import React, {
  EventHandler,
  FC,
  SyntheticEvent,
} from 'react';
import { useNavigate } from 'react-router';

import ontola from '../../../ontology/ontola';
import { useCurrentActor } from '../../hooks/useCurrentActor';
import {
  isDifferentWebsite,
  retrievePath,
  website,
} from '../../lib/iris';
import {
  HideDialog,
  Navigate,
} from '../../middleware/actions';
import CardMain from '../../topologies/Card/CardMain';
import Container from '../../topologies/Container';
import useMemoryStore from '../Action/hooks/useMemoryStore';
import CardContent from '../Card/CardContent';
import EntryPointCardMain from '../EntryPoint/EntryPointCardMain';
import Heading from '../Heading';
import LinkLoader from '../LinkLoader';
import AccountHelpersCardAppendix from '../SignInForm/AccountHelpersCardAppendix';

const SessionForm: FC<SubjectProp & {onCancel?: EventHandler<SyntheticEvent<unknown>>}> = ({
  onCancel,
  subject,
}) => {
  const lrs = useLRS();
  const { actorType } = useCurrentActor();

  const navigate = useNavigate();

  const [currentSubject, setSubject] = React.useState(subject);
  const [target] = useGlobalIds(currentSubject, schema.target);
  const [name] = useStrings(currentSubject, schema.name);

  const redirectLocation = new URL(subject.value).searchParams.get('redirect_url') || website;
  const sessionStore = useMemoryStore({
    [ontola.redirectUrl.value]: redirectLocation ? [rdf.literal(redirectLocation)] : [],
  });

  const onCancelHandler = React.useCallback(
    () => currentSubject === subject ? navigate(-1) : setSubject(subject),
    [setSubject, subject, currentSubject],
  );
  const onDoneHandler = React.useCallback(({ iri }: LinkedActionResponse) => {
    if (iri) {
      if (isDifferentWebsite(iri)) {
        lrs.actions.get(HideDialog)();
        lrs.actions.get(Navigate)(iri, true);
      } else {
        setSubject(iri);
      }
    }
  }, [lrs, setSubject]);

  const redirectUser = redirectLocation && actorType && ['ConfirmedUser', 'UnconfirmedUser'].includes(actorType);

  React.useEffect(() => {
    if (redirectUser) {
      navigate(retrievePath(redirectLocation)!, { replace: true });
    }
  }, [redirectUser]);

  if (redirectUser) {
    return null;
  }

  if (!target) {
    return <LinkLoader />;
  }

  return (
    <Container>
      <CardMain>
        <CardContent endSpacing>
          <Heading>
            {name}
          </Heading>
          <EntryPointCardMain
            key={target.value}
            sessionStore={sessionStore}
            subject={target}
            onCancel={currentSubject === subject ? onCancel : onCancelHandler}
            onDone={onDoneHandler}
          />
        </CardContent>
        <AccountHelpersCardAppendix
          currentSubject={currentSubject}
          onClick={(e) => {
            e.preventDefault();
            setSubject(rdf.namedNode((e.target as HTMLAnchorElement).href));
          }}
        />
      </CardMain>
    </Container>
  );
};

export default SessionForm;
