import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import rdf from '@ontologies/core';
import * as schema from '@ontologies/schema';
import clsx from 'clsx';
import { useStrings } from 'link-redux';
import React from 'react';

import { LibroTheme } from '../../../theme/types';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import {
  fieldInputCID,
  fieldInputCheckboxCID,
  useFormStyles,
} from '../../FormField/UseFormStyles';

export const useCheckboxStyles = makeStyles<LibroTheme>(() => ({
  checkBoxWrapper: {
    '& label': {
      alignSelf: 'center',
    },
    alignItems: 'center',
    display: 'flex',
  },
}));

const CheckboxInput = ({
  inputValue,
}: InputComponentProps): JSX.Element => {
  const classes = useCheckboxStyles();
  const formClasses = useFormStyles();
  const { name, onChange, field } = React.useContext(formFieldContext);
  const [label] = useStrings(field, schema.name);
  const checkBoxClassName = clsx({
    [formClasses.fieldInput]: true,
    [fieldInputCID]: true,
    [fieldInputCheckboxCID]: true,
    [classes.checkBoxWrapper]: true,
  });
  const handleChange = React.useCallback<(event: React.SyntheticEvent, checked: boolean) => void>(
    (_, checked) => onChange([rdf.literal(checked)]),
  [onChange],
  );

  return (
    <div className={checkBoxClassName}>
      <FormControlLabel
        checked={inputValue.value === 'true'}
        control={<Checkbox color="primary" />}
        label={label}
        name={name}
        onChange={handleChange}
      />
    </div>
  );
};

export default CheckboxInput;
