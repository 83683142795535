import { useGlobalIds } from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../../ontology/ontola';
import { useCollectionOptions } from '../CollectionContext';
import TotalItems from '../TotalItems';

import DefaultPagination, { PaginationProps } from './DefaultPagination';
import InfinitePagination from './InfinitePagination';

const CollectionPagination: FC<PaginationProps> = ({ alignText }) => {
  const { currentCollection, hidePagination } = useCollectionOptions();
  const [collectionType] = useGlobalIds(currentCollection, ontola.collectionType);

  if (hidePagination) {
    return <TotalItems />;
  }

  if (collectionType === ontola['collectionType/infinite']) {
    return <InfinitePagination />;
  }

  return <DefaultPagination alignText={alignText} />;
};

export default CollectionPagination;
