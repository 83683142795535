import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import EventCard from '../models/events/EventCard';

const EventDialog: FC<SubjectProp> = ({ subject }) => (
  <EventCard subject={subject} />
);

export default EventDialog;
