const palette = {
  primary: {
    main: '#E83A7F',
  },
  secondary: {
    main: '#5E4DCF',
  },
};

export default {
  variables: {
    palette,
  },
};
