import { isNamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { SomeNode } from 'link-lib';
import {
  useFields,
  useIds,
} from 'link-redux';
import React, { FC } from 'react';

import Detail from '../components/Detail';
import LinkLoader from '../components/LinkLoader';
import useIsLoading from '../hooks/useIsLoading';
import { normalizeFontAwesomeIRI } from '../lib/iris';
import teamGL from '../../ontology/teamGL';

const EventTypeDetail: FC<{event: SomeNode}> = ({ event }) => {
  const [image] = useIds(event, schema.image);
  const [eventType] = useIds(event, teamGL.eventType);
  const loading = useIsLoading(eventType);
  const [name] = useFields(isNamedNode(eventType) ? eventType : undefined, schema.name);

  if (loading) {
    return <LinkLoader />;
  }

  return (
    <Detail
      icon={image && normalizeFontAwesomeIRI(image)}
      text={name?.value}
    />
  );
};

export default EventTypeDetail;
