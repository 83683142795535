import React from 'react';

import { NavbarItemInterface } from '../NavbarItem';

export type AddItemCallback = (path: string, ref: HTMLElement) => void;

type Observable = {
  domNode: HTMLElement;
  isHidden: boolean;
};

interface UsePriorityNavigation {
  addObservedItem: AddItemCallback,
  hiddenItems: NavbarItemInterface[],
}

/**
 * Checks which of the input items are visible on the containing element,
 * then returns a list of items that are not visible.
 * Also returns a callback function to start observing the items.
 */
export const usePriorityNavigation = (navBarRef: HTMLElement | null, menuItems: NavbarItemInterface[], showDropdown?: boolean): UsePriorityNavigation => {
  const [observedItems, setObservedItems] = React.useState<Map<string, Observable>>(new Map());
  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const addObservedItem = React.useCallback((path: string, ref: HTMLElement) => {
    if (showDropdown) {
      setObservedItems((current) => new Map(current.set(path, {
        domNode: ref,
        isHidden: true,
      })));
    }
  },
  [],
  );

  React.useEffect(() => {
    if (showDropdown) {
      const observer = new IntersectionObserver((changedEntries) => {
        for (const entry of changedEntries) {
          for (const [key, value] of observedItems) {
            if (value.domNode === entry.target) {
              observedItems.set(key, {
                domNode: value.domNode,
                isHidden: !entry.isIntersecting,
              });
            }
          }
        }

        forceUpdate();
      }, {
        root: navBarRef,
        threshold: 0.5,
      });

      for (const [_key, value] of observedItems) {
        if (value.domNode) {
          observer.observe(value.domNode);
        }
      }

      return () => observer.disconnect();
    }

    return () => undefined;
  }, [observedItems]);

  const hiddenItems = menuItems.filter(({ path }) => observedItems.get(path)?.isHidden);

  return {
    addObservedItem,
    hiddenItems,
  };
};
