import { SomeTerm } from '@ontologies/core';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import {
  isFontAwesomeIRI,
  normalizeFontAwesomeIRI,
} from '../../lib/iris';

export interface ImageProps {
  alt?: string,
  ariaLabel?: string,
  className?: string,
  linkedProp?: SomeTerm,
  spin?: boolean;
  style?: React.CSSProperties,
}

const Image = (props: ImageProps): JSX.Element => {
  const {
    ...overrideProps
  } = props;
  const {
    alt,
    ariaLabel,
    className,
    spin,
    style,
    linkedProp,
  } = overrideProps;

  if (linkedProp && isFontAwesomeIRI(linkedProp.value)) {
    return (
      <span className={className}>
        <FontAwesome
          ariaLabel={ariaLabel || ''}
          name={normalizeFontAwesomeIRI(linkedProp)}
          spin={spin}
          style={style}
        />
      </span>
    );
  }
  
  return (
    <img
      alt={alt}
      aria-label={ariaLabel}
      className={className}
      src={linkedProp?.value}
      style={style}
    />
  );
};

export default Image;
