import * as rdfx from '@ontologies/rdf';
import {
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, { FC } from 'react';

import DateInput, { DateInputType } from '../../FormInput/DateInput';
import FormField from '../../FormField/FormField';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import useFormField from '../../Form/hooks/useFormField';
import form from '../../../../ontology/form';

const DatePicker = (props: InputComponentProps) => (
  <DateInput
    type={DateInputType.Date}
    {...props}
  />
);
const DateTimePicker = (props: InputComponentProps) => (
  <DateInput
    type={DateInputType.DateTime}
    {...props}
  />
);

const DateFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  const [type] = useGlobalIds(subject, rdfx.type);

  return (
    <FormField
      {...fieldProps}
      inputComponent={type === form.DateTimeInput ? DateTimePicker : DatePicker}
    />
  );
};

export default DateFormField;
