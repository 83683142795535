import rdf from '@ontologies/core';
import HttpStatus from 'http-status-codes';

import ll from '../../ontology/ll';
import ontola from '../../ontology/ontola';

export const ERROR_STATUS_CODES = {
  [ll.ErrorResource.value]: HttpStatus.BAD_REQUEST,
  [ontola.ns('errors/AccountLockedError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
  [ontola.ns('errors/ConnectionFailedError').value]: HttpStatus.BAD_GATEWAY,
  [ontola.ns('errors/ExpiredError').value]: HttpStatus.GONE,
  [ontola.ns('errors/ForbiddenError').value]: HttpStatus.FORBIDDEN,
  [ontola.ns('errors/InvalidQueryErrorError"').value]: HttpStatus.BAD_REQUEST,
  [ontola.ns('errors/MissingIndexErrorError').value]: HttpStatus.BAD_REQUEST,
  [ontola.ns('errors/NoPasswordError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
  [ontola.ns('errors/ParameterMissingError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
  [ontola.ns('errors/RecordNotFoundError').value]: HttpStatus.NOT_FOUND,
  [ontola.ns('errors/RecordNotUniqueError').value]: HttpStatus.NOT_MODIFIED,
  [ontola.ns('errors/RoutingErrorError').value]: HttpStatus.NOT_FOUND,
  [ontola.ns('errors/UnauthorizedError').value]: HttpStatus.UNAUTHORIZED,
  [ontola.ns('errors/UnknownEmailError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
  [ontola.ns('errors/UnpermittedParametersError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
  [ontola.ns('errors/WrongPasswordError').value]: HttpStatus.UNPROCESSABLE_ENTITY,
};

export const ERROR_CLASSES = Object.keys(ERROR_STATUS_CODES).map((key) => rdf.namedNode(key));
