import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';

import {
  BreakPoints,
  LibroTheme,
} from '../../theme/types';
import CardContent from '../../components/Card/CardContent';
import CardMainHeader from '../../components/Card/CardMainHeader';
import { CardMain } from '../Card';
import Markdown from '../../components/Markdown';

import {
  PageHeaderImageAndTextWrapper,
  PageHeaderText,
} from './index';

const useStyles = makeStyles<LibroTheme>((theme) => ({
  pageHeader: {
    marginTop: '-5rem',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxWidth: theme.breakpoints.values.xl * 2 / 3,
    [theme.breakpoints.up(BreakPoints.Large)]: {
      paddingRight: '1.125rem',
    },
  },
}));

interface PageHeaderCardProps { menu?: JSX.Element, text: string, title: string; }

const PageHeaderCard: FC<PageHeaderCardProps> = ({ menu, text, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.pageHeader}>
      <CardMain>
        <CardContent>
          <PageHeaderImageAndTextWrapper>
            <PageHeaderText>
              <CardMainHeader
                float={menu}
                title={title}
              />
              <Markdown text={text ?? ''} />
            </PageHeaderText>
          </PageHeaderImageAndTextWrapper>
        </CardContent>
      </CardMain>
    </div>
  );
};

export default PageHeaderCard;
