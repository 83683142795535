import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { SomeNode } from 'link-lib/dist-types/types';
import { useStrings } from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React, { FC } from 'react';

import { useShowDialog } from '../../hooks/useShowDialog';
import { sort } from '../../lib/data';
import { Trigger } from '../DropdownMenu/TriggerButton';
import Menu from '../../topologies/Menu';
import MenuItem from '../MenuItem';

const ORDER = [
  '/participants/add_all',
  '/participants/new',
];

interface CollectionCreateDropdownProps {
  triggerComponent: Trigger<{to: string}>;
  renderedActions: SomeNode[];
}

const ActionDropdownContent: FC<SubjectProp> = ({ subject }) => {
  const [name] = useStrings(subject, schema.name);
  const showDialog = useShowDialog(subject);

  return (
    <MenuItem
      href={subject!.value}
      onClick={showDialog}
    >
      {name}
    </MenuItem>
  );
};

const CollectionCreateDropdown = ({
  triggerComponent: TriggerComponent,
  renderedActions,
}: CollectionCreateDropdownProps): JSX.Element | null => (
  <Menu
    title={t('collection.add')}
    trigger={TriggerComponent as Trigger}
  >
    {() => (
      <React.Fragment>
        {renderedActions
          .sort(sort(ORDER))
          .map((action) => (
            <ActionDropdownContent
              key={action?.value}
              subject={action}
            />
          ))}
      </React.Fragment>
    )}
  </Menu>
);

export default CollectionCreateDropdown;
