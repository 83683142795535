/* eslint-disable no-unused-vars */

export enum Section {
  Admin = 'team',
  Main = 'glapp',
}

export interface AppConfig {
  adminName: string
  app: string;
  appName: string
  privacyPage?: string;
}

export type Globals = {
  adminRoot: string;
  config: AppConfig;
  mainRoot: string;
  section: Section;
};

/**
 * These are injected through webpack.
 */
declare global {
  const __CLIENT__: boolean;
  const __DEVELOPMENT__: boolean;
  const __PRODUCTION__: boolean;
  const __TEST__: boolean;

  const GLOBALS: Globals;

  interface Window {
    EXEC_ACTIONS?: string[];
  }
}
