
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';

import { Trigger } from '../DropdownMenu/TriggerButton';
import MenuItem from '../MenuItem';
import AppMenu from '../../topologies/AppMenu';

import { NavbarItemInterface } from './NavbarItem';
import NavbarLink from './NavbarLink';

const trigger: Trigger = ({
  onClick,
  anchorRef,
  id,
  open,
}) => {
  const icon = open ? 'close' : 'bars';

  return (
    <NavbarLink
      aria-controls={id}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      icon={icon}
      label="Menu"
      ref={anchorRef}
      title="Menu"
      onClick={onClick}
    />
  );
};

interface NavbarMenuProps {
  primaryItems: NavbarItemInterface[];
  secondaryItems: NavbarItemInterface[];
}

const NavbarMenu: FC<NavbarMenuProps> = ({ primaryItems, secondaryItems }) => (
  <AppMenu trigger={trigger}>
    {() => (
      <React.Fragment>
        {primaryItems.map((item) => (
          <MenuItem
            href={item.path}
            key={item.path}
            onClick={item.onClick}
          >
            {item.label}
          </MenuItem>
        ))}
        <Divider />
        {secondaryItems.map((item) => (
          <MenuItem
            href={item.path}
            key={item.path}
            onClick={item.onClick}
          >
            {item.label}
          </MenuItem>
        ))}
      </React.Fragment>
    )}
  </AppMenu>
);

export default NavbarMenu;
