import {
  Map,
  Overlay,
} from 'ol';
import { Coordinate } from 'ol/coordinate';
import {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { NavigateCallback } from '../lib/types';

interface PropTypes {
  map: Map | undefined;
  navigate?: NavigateCallback;
  overlay?: JSX.Element;
  overlayPosition?: Coordinate;
}

const useOverlay = ({
  map,
  overlayPosition,
}: PropTypes): MutableRefObject<HTMLDivElement | null> => {
  const overlayRef = useRef(document.createElement('div'));
  const overlay = useMemo(() => {
    const element = overlayRef.current;

    if (map && element) {
      const o = new Overlay({
        element,
        positioning: 'top-center',
        stopEvent: true,
      });
      map.addOverlay(o);

      return o;
    }

    return null;
  }, [map]);

  useEffect(() => {
    if (overlay && overlayPosition) {
      overlay.setPosition(overlayPosition);
    }
  }, [overlay, overlayPosition]);

  return overlayRef;
};

export default useOverlay;
