import rdf from '@ontologies/core';
import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../../ontology/ontola';
import { useCollectionOptions } from '../CollectionContext';
import CollectionPages from '../CollectionPages';

import CardFrame from './CardFrame';
import DefaultFrame from './DefaultFrame';
import GridFrame from './GridFrame';
import TableFrame from './TableFrame';

const CollectionFrame: FC<{renderer: FC<SubjectProp>}> = ({ renderer }) => {
  const {
    collectionDisplay,
  } = useCollectionOptions();

  switch (rdf.id(collectionDisplay)) {
  case rdf.id(ontola.ns('collectionDisplay/grid')):
    return (
      <GridFrame>
        <CollectionPages renderer={renderer} />
      </GridFrame>
    );
  case rdf.id(ontola.ns('collectionDisplay/settingsTable')):
  case rdf.id(ontola.ns('collectionDisplay/table')):
    return (
      <TableFrame>
        <CollectionPages renderer={renderer} />
      </TableFrame>
    );
  case rdf.id(ontola.ns('collectionDisplay/card')):
    return (
      <CardFrame>
        <CollectionPages
          resourceBoundary
          renderer={renderer}
        />
      </CardFrame>
    );
  case rdf.id(ontola.ns('collectionDisplay/default')):
    return (
      <DefaultFrame>
        <CollectionPages
          resourceBoundary
          renderer={renderer}
        />
      </DefaultFrame>
    );
  default:
    return (
      <React.Fragment>
        <CollectionPages renderer={renderer} />
      </React.Fragment>
    );
  }
};

export default CollectionFrame;
