import { NamedNode, SomeTerm } from '@ontologies/core';
import { SomeNode } from 'link-lib';
import React from 'react';

import { SortProps } from './hooks/useSorting';

export interface CollectionContext {
  appliedFilters: SomeTerm[];
  collectionDisplay?: NamedNode;
  columns: NamedNode[];
  currentCollection?: SomeNode;
  currentCollectionPages?: SomeNode[];
  hasInteraction?: boolean;
  hidePagination?: boolean;
  maxColumns?: number;
  originalCollection: SomeNode;
  redirectPagination?: boolean;
  setCollectionResource: (resource: NamedNode) => void;
  sortOptions: SortProps[];
}

export const collectionContext = React.createContext<CollectionContext>({} as CollectionContext);
export const useCollectionOptions = (): CollectionContext => React.useContext(collectionContext);
