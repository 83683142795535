import {
  NamedNode,
  SomeTerm,
} from '@ontologies/core';
import { SomeNode } from 'link-lib';
import React, {
  FC,
  ReactNode,
} from 'react';

import Card from '../../../topologies/Card';
import TableHeadCells from '../../Table/TableHeadCells';
import Table from '../../../topologies/Table/Table';
import TableFooter from '../../../topologies/Table/TableFooter';
import TableFooterCell from '../../../topologies/Table/TableFooterCell';
import TableFooterRow from '../../../topologies/Table/TableFooterRow';
import TableHead from '../../../topologies/Table/TableHead';
import TableHeaderRow from '../../../topologies/Table/TableHeaderRow';
import { useCollectionOptions } from '../CollectionContext';
import CollectionCreateButton, { TriggerType } from '../CollectionCreateButton';
import CollectionHeader from '../CollectionHeader';
import CollectionRows from '../CollectionRows';
import CollectionPagination from '../Pagination/CollectionPagination';

export interface RendererProps {
  property: NamedNode;
  subject: SomeNode;
  term: SomeTerm;
}

export interface Column {
  label?: string | null;
  property: NamedNode;
  render?: (props: RendererProps) => JSX.Element | string | undefined;
  sortProperty?: NamedNode;
}

interface TableProps {
  showHead?: boolean;
  title?: string;
}

interface TablePropsWithChildren extends TableProps {
  children: ReactNode
  columns?: undefined;
  onRowClick?: undefined;
}

interface TablePropsWithColumns extends TableProps {
  children?: undefined;
  columns: Column[];
  onRowClick?: (resource: SomeNode) => void;
}

const TableFrame: FC<TablePropsWithChildren | TablePropsWithColumns> = ({
  children,
  columns: columnsFromProp,
  onRowClick,
  showHead,
  title,
}) => {
  const { columns: columnsFromContext } = useCollectionOptions();
  const columns = children ? columnsFromContext : columnsFromProp;

  return (
    <React.Fragment>
      <CollectionHeader title={title} />
      <Card>
        <Table>
          {showHead !== false && (
            <TableHead>
              <TableHeaderRow>
                <TableHeadCells columns={columnsFromProp} />
              </TableHeaderRow>
            </TableHead>
          )}
          <tbody>
            {children ?? (
              <CollectionRows
                columns={columnsFromProp!}
                onRowClick={onRowClick}
              />
            )}
          </tbody>
          <TableFooter>
            <TableFooterRow>
              {(columns?.length || 0) > 1 && (
                <TableFooterCell colSpan={1}>
                  <CollectionCreateButton trigger={TriggerType.Text} />
                </TableFooterCell>
              )}
              <TableFooterCell colSpan={(columns?.length || 1) - 1}>
                <CollectionPagination
                  alignText="right"
                />
              </TableFooterCell>
            </TableFooterRow>
          </TableFooter>
        </Table>
      </Card>
    </React.Fragment>
  );
};

export default TableFrame;
