import {
  DeprecatedThemeOptions,
  PaletteColor,
  createTheme,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { appName } from '../lib/config';

import { LibroTheme, MaterialStyleMap } from './types';
import appThemes from './apps';

type ComponentStyle = (theme: LibroTheme) => MaterialStyleMap;

export interface GenerateStyle {
  components?: ComponentStyle[];
  variables?: DeprecatedThemeOptions;
}

export const generateStyle = ({
  components,
  variables,
}: GenerateStyle): LibroTheme => {
  const appTheme = appThemes[appName] || {};
  const mergedVariables = deepmerge(variables ?? {}, appTheme.variables ?? {});
  const theme = createTheme(mergedVariables);

  const mergedComponents = [...(components ?? []), ...(appTheme.components ?? [])];

  mergedComponents.forEach((component) => {
    theme.components = deepmerge(theme.components, component(theme));
  });

  if (!theme.palette.mapIcon) {
    theme.palette.mapIcon = {
      background: theme.palette.secondary.main,
      backgroundHover: theme.palette.secondary.light,
      text: theme.palette.common.white,
    };
  }

  theme.appBar.resolveColor = () => {
    const { appBar: { background, color }, palette } = theme;

    if (color === 'auto' && background) {
      return (palette as unknown as { [background: string]: PaletteColor })[background].contrastText;
    } else if (color) {
      return (palette as unknown as { [color: string]: PaletteColor })[color].main;
    }

    return undefined;
  };

  return theme;
};
