import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  ReturnType,
  useLink,
  useRecordStatus,
} from 'link-redux';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import { SignInFormLink } from '../SignInForm';
import { useCurrentActor } from '../../hooks/useCurrentActor';
import Button, { ButtonVariant } from '../Button';
import Heading, { HeadingSize, HeadingVariant } from '../Heading';
import HeadingContext from '../Heading/HeadingContext';

import ErrorButtonWithFeedback from './ErrorButtonWithFeedback';
import {
  bodyForStatus,
  headerForStatus,
  useErrorStatus,
} from './errorMessages';
import { ErrorComponentProps, shouldShowSignIn } from './helpers';

const propMap = {
  message: schema.text,
  name: schema.name,
};

const dataErrOpts = {
  fetch: false,
  returnType: ReturnType.Literal,
};

const CardError = (props: ErrorComponentProps): JSX.Element => {
  const {
    caughtError,
    error,
    linkRequestStatus,
  } = props;
  useRecordStatus();
  const { actorType } = useCurrentActor();
  const errFromData = useLink(propMap, dataErrOpts);

  const err = caughtError ?? error ?? errFromData;
  const statusCode = useErrorStatus(linkRequestStatus);
  const errorLabel = headerForStatus(statusCode);
  const errorBody = bodyForStatus(statusCode);

  let mainAction = (
    <ErrorButtonWithFeedback
      variant={ButtonVariant.Box}
      {...props}
    >
      {t('errors.retry')}
    </ErrorButtonWithFeedback>
  );

  if (shouldShowSignIn(actorType, statusCode)) {
    mainAction = (
      <SignInFormLink Component={Button} />
    );
  }

  return (
    <HeadingContext>
      <Heading
        size={HeadingSize.LG}
        variant={HeadingVariant.Alert}
      >
        <FontAwesome name="exclamation-triangle" />
        {' '}
        {errorLabel ?? err?.name}
      </Heading>
      {errorBody ? (
        <p>
          {errorBody}
        </p>
      ) : null}
      {err && (
        <p>
          {err.message}
        </p>
      )}
      {__DEVELOPMENT__ && err instanceof Error && (
        <pre>
          {err.stack}
        </pre>
      )}
      {mainAction}
    </HeadingContext>
  );
};

export default CardError;
