import { isTerm } from '@ontologies/core';

/**
 * Calculates rounded percentage when given a number and the total amount
 * @param {number} value The number to calculate the percentage of
 * @param {number} total The total amount
 * @return {number} result Returns a rounded percentage
 */
export function tryParseFloat(linkedProp: unknown): number | undefined {
  const value = isTerm(linkedProp) ? linkedProp.value : linkedProp;

  const parsed = Number.parseFloat(value as string);

  return (isNaN(value as number) || isNaN(parsed))
    ? undefined : parsed;
}

export function tryParseInt(linkedProp: unknown): number | undefined {
  const value = isTerm(linkedProp) ? linkedProp.value : linkedProp;

  const parsed = Number.parseInt(value as string);

  return (isNaN(value as number) || isNaN(parsed))
    ? undefined : parsed;
}
