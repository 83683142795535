import { SubjectProp } from 'link-redux/dist-types/types';
import React, { FC } from 'react';

import HeadingContext from '../Heading/HeadingContext';

import { useCollectionOptions } from './CollectionContext';
import CollectionItems from './CollectionItems';

const CollectionPages: FC<{ renderer: FC<SubjectProp>; resourceBoundary?: boolean }> = ({ renderer, resourceBoundary }) => {
  const { currentCollectionPages } = useCollectionOptions();

  return (
    <React.Fragment>
      {currentCollectionPages?.map((collectionPage) => (
        <HeadingContext key={collectionPage.value}>
          <CollectionItems
            page={collectionPage}
            renderer={renderer}
            resourceBoundary={resourceBoundary}
          />
        </HeadingContext>
      ))}
    </React.Fragment>
  );
};

export default CollectionPages;
