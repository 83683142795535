import * as schema from '@ontologies/schema';
import { Box } from '@mui/material';
import * as as from '@ontologies/as';
import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import {
  useGlobalIds,
  useNumbers,
} from 'link-redux';
import React from 'react';

import Link, { LinkFeature } from '../Link';

import { useCollectionOptions } from './CollectionContext';

const useIsSinglePage = (currentCollection?: SomeNode) => {
  const [first] = useGlobalIds(currentCollection, as.first);
  const [last] = useGlobalIds(currentCollection, as.last);

  return first === last;
};

const TotalItems = (): JSX.Element | null => {
  const { currentCollection } = useCollectionOptions();
  const [totalItems] = useNumbers(currentCollection, as.totalItems);
  const [url] = useGlobalIds(currentCollection, schema.url);
  const isSinglePage = useIsSinglePage(currentCollection);

  if (totalItems === 0 || isSinglePage || !currentCollection) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: '1em' }}>
      <Link
        features={[LinkFeature.Bold]}
        to={(url ?? currentCollection).value}
      >
        {t('collection.readAll', { count: totalItems })}
      </Link>
    </Box>
  );
};

export default TotalItems;
