import React, {
  FC,
  MouseEventHandler,
} from 'react';

import { AddItemCallback } from './lib/usePriorityNavigation';
import NavbarLink from './NavbarLink';

export interface NavbarItemInterface {
  icon?: string;
  label: string;
  onClick?: MouseEventHandler;
  path: string;
}

interface NavbarItemProps extends NavbarItemInterface {
  addObservedItem?: AddItemCallback,
}

const NavbarItem: FC<NavbarItemProps> = ({
  addObservedItem,
  ...itemProps
}) => {
  const {
    label,
    icon,
    onClick,
    path,
  } = itemProps;
  const setRef = React.useCallback<React.RefCallback<HTMLElement>>((ref) => {
    if (addObservedItem && ref) {
      addObservedItem(path, ref);
    }
  }, []);

  return (
    <NavbarLink
      icon={icon}
      label={label}
      path={path}
      ref={setRef}
      title={label}
      onClick={onClick}
    />
  );
};

export default NavbarItem;
