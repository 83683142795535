import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import React, { EventHandler } from 'react';

import Spinner from '../../Loading/Spinner';
import { parseForStorage } from '../../../lib/persistence';
import useFieldOptions from '../../Form/hooks/useFieldOptions';
import Select from '../../../topologies/Select';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputValue } from '../../FormField/FormFieldTypes';
import {
  fieldInputCID,
  fieldInputCheckboxCID,
  useFormStyles,
} from '../../FormField/UseFormStyles';
import { useItemToString } from '../SelectInput/lib/useItemToString';

import { useCheckboxStyles } from './CheckboxInput';
import HiddenRequiredInput from './HiddenRequiredInput';

function handleChange(e: any, values: InputValue[], onChange: EventHandler<any>) {
  const newValue = values?.slice()?.filter((term) => term.value) || [];
  const parsedValues = parseForStorage(e.target.value);

  if (typeof parsedValues === 'undefined') {
    return;
  }

  const parsedValue = parsedValues[0];

  if (e.target.checked) {
    newValue.push(parsedValue);
  } else {
    const index = values.map((v) => v.value).indexOf(parsedValue.value);

    if (index !== -1) {
      newValue.splice(index, 1);
    }
  }

  onChange(newValue);
}

const CheckboxesInput: React.FC = () => {
  const {
    name,
    onBlur,
    onChange,
    fieldShape,
    renderProp,
    values,
  } = React.useContext(formFieldContext);
  const checkboxClasses = useCheckboxStyles();
  const formClasses = useFormStyles();
  const itemToString = useItemToString(renderProp);

  const checkBoxClassName = clsx({
    [formClasses.fieldInput]: true,
    [fieldInputCID]: true,
    [fieldInputCheckboxCID]: true,
    [checkboxClasses.checkBoxWrapper]: true,
  });

  const {
    required,
    shIn,
  } = fieldShape;

  const {
    loading,
    options,
  } = useFieldOptions(shIn);

  if (loading) {
    return <Spinner />;
  }

  if (options.length === 0) {
    return t('collection.empty');
  }

  const items = options.map((item) => {
    const label = itemToString(item);

    return (
      <div
        className={checkBoxClassName}
        key={`checkbox-${item.value}`}
      >
        <FormControlLabel
          checked={values?.some((v) => v.value === item.value)}
          control={(
            <Checkbox
              color="primary"
              id={item.value}
              value={JSON.stringify(item)}
            />
          )}
          label={label}
          name={name}
          value={JSON.stringify(item)}
          onBlur={onBlur}
          onChange={(e) => handleChange(e, values, onChange)}
        />
      </div>
    );
  });

  return (
    <Select>
      {required && (
        <HiddenRequiredInput
          name={name}
          value={values?.[0]?.value}
        />
      )}
      <div>
        {items}
      </div>
    </Select>
  );
};

export default CheckboxesInput;
