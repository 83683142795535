import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import rdf from '@ontologies/core';
import * as xsd from '@ontologies/xsd';
import { t } from 'i18next';
import React from 'react';

import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import HiddenRequiredInput from '../Input/HiddenRequiredInput';

export enum DateInputType {
  Date = 'date',
  DateTime = 'dateTime',
}

export interface DateInputProps extends InputComponentProps {
  type: DateInputType;
}

const renderInput = (params: MuiTextFieldPropsType) => (
  <TextField
    {...params}
    fullWidth
  />
);

const DateInput: React.FC<DateInputProps> = ({
  type,
  inputValue,
  onChange,
}) => {
  const {
    fieldShape,
    name,
  } = React.useContext(formFieldContext);

  const dataType = type === DateInputType.Date ? xsd.date : xsd.dateTime;
  const handleChange = React.useCallback(
    (newValue) => onChange(newValue === null ? null : rdf.literal(newValue, dataType)),
    [],
  );

  const value = inputValue.value?.length > 0 ? inputValue.value : null;
  const Component = type === DateInputType.Date ? MobileDatePicker : MobileDateTimePicker;
  const props = type === DateInputType.Date ? {
    inputFormat: 'd MMMM yyyy',
  } : {
    inputFormat: 'd MMMM yyyy, HH:mm',
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {fieldShape.required && (
        <HiddenRequiredInput
          name={name}
          value={value}
        />
      )}
      <div>
        <Component
          disableMaskedInput
          showTodayButton
          cancelText={t('form.cancel')}
          clearText={t('form.clear')}
          clearable={!!inputValue.value}
          okText={t('form.okLabel')}
          orientation="portrait"
          renderInput={renderInput}
          todayText={t('form.todayLabel')}
          value={value}
          onChange={handleChange}
          {...props}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateInput;
