import { makeStyles } from '@mui/styles';
import rdf from '@ontologies/core';
import * as rdfx from '@ontologies/rdf';
import { t } from 'i18next';
import {
  useFields,
  useGlobalIds,
  useTopology,
} from 'link-redux';
import React, { FC } from 'react';

import CardContent from '../Card/CardContent';
import GridItem from '../Grid/GridItem';
import { containerTopology } from '../../topologies';
import Container from '../../topologies/Container';
import { LibroTheme } from '../../theme/types';
import ontola from '../../../ontology/ontola';
import TableCell from '../../topologies/Table/TableCell';
import TableRow from '../../topologies/Table/TableRow';

import { useCollectionOptions } from './CollectionContext';
import { isTableDisplay } from './lib/displayTypes';

const useStyles = makeStyles((theme: LibroTheme) => ({
  empty: {
    color: theme.palette.grey.midDark,
  },
  hidden: {
    display: 'none',
  },
}));

const CollectionEmpty: FC = () => {
  const topology = useTopology();
  const [baseCollection] = useGlobalIds(ontola.baseCollection);

  const styles = useStyles();
  const {
    collectionDisplay,
    columns,
  } = useCollectionOptions();
  const collectionType = useFields(baseCollection, rdfx.type);

  const message = (
    <span className={styles.empty}>
      {t('collection.empty')}
    </span>
  );

  if (isTableDisplay(collectionDisplay)) {
    return (
      <TableRow>
        <TableCell colSpan={columns?.length ?? 1}>
          {message}
        </TableCell>
      </TableRow>
    );
  }

  if (collectionType.includes(ontola.SearchResult)) {
    return (
      <div className={styles.hidden}>
        {message}
      </div>
    );
  }

  if (rdf.equals(collectionDisplay, ontola['collectionDisplay/card'])) {
    return (
      <CardContent endSpacing>
        {message}
      </CardContent>
    );
  }

  if (rdf.equals(collectionDisplay, ontola['collectionDisplay/grid'])) {
    return (
      <GridItem>
        {message}
      </GridItem>
    );
  }

  if (rdf.equals(collectionDisplay, ontola['collectionDisplay/default']) && topology !== containerTopology) {
    return (
      <Container>
        {message}
      </Container>
    );
  }

  return message;
};

export default CollectionEmpty;
