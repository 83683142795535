import { LinkReduxLRSType } from 'link-redux';

import { handle } from '../lib/logging';

import { HideDialog } from './actions';

function unloadPage(lrs: LinkReduxLRSType) {
  lrs.actions.get(HideDialog)();

  if (lrs.api) {
    try {
      (lrs.api as any).channel?.disconnect();
    } catch (e) {
      handle(e);
    }
  }
}

export function reloadPage(lrs: LinkReduxLRSType): void {
  unloadPage(lrs);

  window.location.reload();
}

export function redirectPage(lrs: LinkReduxLRSType, location: string): void {
  unloadPage(lrs);

  if (window.location.pathname === new URL(location).pathname) {
    window.location.href = location;
    window.location.reload();
  } else {
    window.location.href = location;
  }
}
