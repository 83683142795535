import { MaterialStyleMap } from '../types';
import { headingCID } from '../../components/Heading';

import SuisseIntlUltralightWebSWoff2 from './fonts/SuisseIntl-Ultralight-WebS.woff2';
import SuisseIntlUltralightWebSWoff from './fonts/SuisseIntl-Ultralight-WebS.woff';
import SuisseIntlUltralightItalicWebSWoff2 from './fonts/SuisseIntl-UltralightItalic-WebS.woff2';
import SuisseIntlUltralightItalicWebSWoff from './fonts/SuisseIntl-UltralightItalic-WebS.woff';
import SuisseIntlThinWebSWoff2 from './fonts/SuisseIntl-Thin-WebS.woff2';
import SuisseIntlThinWebSWoff from './fonts/SuisseIntl-Thin-WebS.woff';
import SuisseIntlThinItalicWebSWoff2 from './fonts/SuisseIntl-ThinItalic-WebS.woff2';
import SuisseIntlThinItalicWebSWoff from './fonts/SuisseIntl-ThinItalic-WebS.woff';
import SuisseIntlLightWebSWoff2 from './fonts/SuisseIntl-Light-WebS.woff2';
import SuisseIntlLightWebSWoff from './fonts/SuisseIntl-Light-WebS.woff';
import SuisseIntlLightItalicWebSWoff2 from './fonts/SuisseIntl-LightItalic-WebS.woff2';
import SuisseIntlLightItalicWebSWoff from './fonts/SuisseIntl-LightItalic-WebS.woff';
import SuisseIntlRegularWebSWoff2 from './fonts/SuisseIntl-Regular-WebS.woff2';
import SuisseIntlRegularWebSWoff from './fonts/SuisseIntl-Regular-WebS.woff';
import SuisseIntlRegularItalicWebSWoff2 from './fonts/SuisseIntl-RegularItalic-WebS.woff2';
import SuisseIntlRegularItalicWebSWoff from './fonts/SuisseIntl-RegularItalic-WebS.woff';
import SuisseIntlBookWebSWoff2 from './fonts/SuisseIntl-Book-WebS.woff2';
import SuisseIntlBookWebSWoff from './fonts/SuisseIntl-Book-WebS.woff';
import SuisseIntlBookItalicWebSWoff2 from './fonts/SuisseIntl-BookItalic-WebS.woff2';
import SuisseIntlBookItalicWebSWoff from './fonts/SuisseIntl-BookItalic-WebS.woff';
import SuisseIntlMediumWebSWoff2 from './fonts/SuisseIntl-Medium-WebS.woff2';
import SuisseIntlMediumWebSWoff from './fonts/SuisseIntl-Medium-WebS.woff';
import SuisseIntlMediumItalicWebSWoff2 from './fonts/SuisseIntl-MediumItalic-WebS.woff2';
import SuisseIntlMediumItalicWebSWoff from './fonts/SuisseIntl-MediumItalic-WebS.woff';
import SuisseIntlSemiBoldWebSWoff2 from './fonts/SuisseIntl-SemiBold-WebS.woff2';
import SuisseIntlSemiBoldWebSWoff from './fonts/SuisseIntl-SemiBold-WebS.woff';
import SuisseIntlSemiBoldItalicWebSWoff2 from './fonts/SuisseIntl-SemiBoldItalic-WebS.woff2';
import SuisseIntlSemiBoldItalicWebSWoff from './fonts/SuisseIntl-SemiBoldItalic-WebS.woff';
import SuisseIntlBoldWebSWoff2 from './fonts/SuisseIntl-Bold-WebS.woff2';
import SuisseIntlBoldWebSWoff from './fonts/SuisseIntl-Bold-WebS.woff';
import SuisseIntlBoldItalicWebSWoff2 from './fonts/SuisseIntl-BoldItalic-WebS.woff2';
import SuisseIntlBoldItalicWebSWoff from './fonts/SuisseIntl-BoldItalic-WebS.woff';
import SuisseIntlBlackWebSWoff2 from './fonts/SuisseIntl-Black-WebS.woff2';
import SuisseIntlBlackWebSWoff from './fonts/SuisseIntl-Black-WebS.woff';
import SuisseIntlBlackItalicWebSWoff2 from './fonts/SuisseIntl-BlackItalic-WebS.woff2';
import SuisseIntlBlackItalicWebSWoff from './fonts/SuisseIntl-BlackItalic-WebS.woff';

const fontFaces = [
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 100,
      src: `url('${SuisseIntlUltralightWebSWoff2}') format('woff2'), url('${SuisseIntlUltralightWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 100,
      src: `url('${SuisseIntlUltralightItalicWebSWoff2}') format('woff2'), url('${SuisseIntlUltralightItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 200,
      src: `url('${SuisseIntlThinWebSWoff2}') format('woff2'), url('${SuisseIntlThinWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 200,
      src: `url('${SuisseIntlThinItalicWebSWoff2}') format('woff2'), url('${SuisseIntlThinItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 300,
      src: `url('${SuisseIntlLightWebSWoff2}') format('woff2'), url('${SuisseIntlLightWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 300,
      src: `url('${SuisseIntlLightItalicWebSWoff2}') format('woff2'), url('${SuisseIntlLightItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 400,
      src: `url('${SuisseIntlRegularWebSWoff2}') format('woff2'), url('${SuisseIntlRegularWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 400,
      src: `url('${SuisseIntlRegularItalicWebSWoff2}') format('woff2'), url('${SuisseIntlRegularItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 450,
      src: `url('${SuisseIntlBookWebSWoff2}') format('woff2'), url('${SuisseIntlBookWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 450,
      src: `url('${SuisseIntlBookItalicWebSWoff2}') format('woff2'), url('${SuisseIntlBookItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 500,
      src: `url('${SuisseIntlMediumWebSWoff2}') format('woff2'), url('${SuisseIntlMediumWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 500,
      src: `url('${SuisseIntlMediumItalicWebSWoff2}') format('woff2'), url('${SuisseIntlMediumItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 600,
      src: `url('${SuisseIntlSemiBoldWebSWoff2}') format('woff2'), url('${SuisseIntlSemiBoldWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 600,
      src: `url('${SuisseIntlSemiBoldItalicWebSWoff2}') format('woff2'), url('${SuisseIntlSemiBoldItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 700,
      src: `url('${SuisseIntlBoldWebSWoff2}') format('woff2'), url('${SuisseIntlBoldWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 700,
      src: `url('${SuisseIntlBoldItalicWebSWoff2}') format('woff2'), url('${SuisseIntlBoldItalicWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'normal',
      fontWeight: 900,
      src: `url('${SuisseIntlBlackWebSWoff2}') format('woff2'), url('${SuisseIntlBlackWebSWoff}') format('woff')`,
    },
  },
  {
    '@font-face': {
      fontDisplay: 'swap',
      fontFamily: 'Suisse Webfont',
      fontStyle: 'italic',
      fontWeight: 900,
      src: `url('${SuisseIntlBlackItalicWebSWoff2}') format('woff2'), url('${SuisseIntlBlackItalicWebSWoff}') format('woff')`,
    },
  },
];

const components = [
  () => ({
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Suisse Webfont',
          fontWeight: 900,
          textTransform: 'uppercase',
        },
      },
    },
  }),
  (): MaterialStyleMap => ({
    MuiCssBaseline: {
      styleOverrides: {
        'h1, h2, h3, h4, h5, h6': {
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        html: {
          fallbacks: fontFaces,
          'h1, h2': {
            [`&.${headingCID}`]: {
              fontFamily: 'Suisse Webfont',
              fontWeight: '900 !important',
              textTransform: 'uppercase',
            },
          },
        },
      },
    },
  }),
];

const palette = {
  link: {
    header: undefined,
    text: '#39a935',
  },
  primary: {
    main: '#39a935',
  },
  secondary: {
    main: '#533bff',
  },
};

const typography = {
  button: {
    fontFamily: 'Suisse Webfont',
    fontWeight: 800,
  },
};

export default {
  components,
  variables: {
    palette,
    typography,
  },
};
