import {
  NamedNode,
  Namespace,
  createNS,
} from '@ontologies/core';
import * as dcterms from '@ontologies/dcterms';
import * as foaf from '@ontologies/foaf';
import * as rdfs from '@ontologies/rdfs';
import * as schema from '@ontologies/schema';

import { frontendIRIStr } from '../app/lib/iris';

export const trailing = (iri: string): string => iri.endsWith('/') ? iri : `${iri}/`;

interface AppNS {
  ns: Namespace;

  contents: NamedNode;
  parent: NamedNode;
  thumbnail: NamedNode;
  title: NamedNode;

  AppSignOut: NamedNode;
  Menu: NamedNode;

  bannerMembers: NamedNode;
  c_a: NamedNode;
  collectionResource: NamedNode;
  currentTab: NamedNode;
  empty: NamedNode;
  menu: NamedNode;
  menuTabs: NamedNode;
  n: NamedNode;
  pagination: NamedNode;
  policy: NamedNode;
  privacy: NamedNode;
  search: NamedNode;
  target: NamedNode;

  ['individuals/searchTarget']: NamedNode;
}

export const createAppNS = (websiteIRI: string): AppNS => {
  const app = createNS(trailing(websiteIRI));

  return {
    ns: app,

    // eslint-disable-next-line sort-keys
    contents: app('contents'),
    parent: app('parent'),
    thumbnail: app('thumbnail'),
    title: app('title'),

    /* classes */
    // eslint-disable-next-line sort-keys
    AppSignOut: app('AppSignOut'),
    Menu: app('Menu'),

    /* properties */
    bannerMembers: app('banners?page=1#members'),
    c_a: app('c_a'),
    collectionResource: app('collectionResource'),
    currentTab: app('currentTab'),
    empty: app('empty'),
    menu: app('menu'),
    menuTabs: app('menuTabs'),
    n: app('n'),
    pagination: app('pagination'),
    policy: app('policy'),
    privacy: app('privacy'),
    search: app('search'),
    target: app('target'),

    /* individuals */
    // eslint-disable-next-line sort-keys
    ['individuals/searchTarget']: app('individuals/searchTarget'),
  };
};

const contextAppNS = createAppNS(frontendIRIStr);

export const contentsProps = [schema.text, schema.description, dcterms.description];
export const titleProps = [schema.name, rdfs.label, foaf.name, dcterms.title];

export default contextAppNS;
