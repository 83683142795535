import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  pageHeaderImageAndTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const PageHeaderImageAndTextWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.pageHeaderImageAndTextWrapper}>
      {children}
    </div>
  );
};

export default PageHeaderImageAndTextWrapper;
