import form from '../../ontology/form';
import libro from '../../ontology/libro';

export const actionsBarTopology = libro.topologies.actionsBar;
export const gridTopology = libro.topologies.grid;
export const fullResourceTopology = libro.topologies.fullResource;
export const detailsBarTopology = libro.topologies.detail;
export const contentDetailsTopology = libro.topologies.contentDetails;
export const containerHeaderTopology = libro.topologies.containerHeader;
export const containerFloatTopology = libro.topologies.containerFloat;
export const containerTopology = libro.topologies.container;
export const cardRowTopology = libro.topologies.cardRow;
export const cardMicroRowTopology = libro.topologies.cardMicroRow;
export const cardMainTopology = libro.topologies.cardMain;
export const cardFloatTopology = libro.topologies.cardFloat;
export const cardFixedTopology = libro.topologies.cardFixed;
export const cardAppendixTopology = libro.topologies.cardAppendix;
export const cardTopology = libro.topologies.card;
export const parentTopology = libro.topologies.parent;
export const formFooterTopology = form.topologies.footer;
export const alertDialogTopology = libro.ns('dialog/alert');
export const attributeListTopology = libro.topologies.attributeList;
export const pageHeaderTopology = libro.topologies.pageHeader;
export const pageTopology = libro.topologies.page;
export const mainBodyTopology = libro.topologies.mainBody;
export const listTopology = libro.topologies.list;
export const hoverBoxTopology = libro.topologies.hoverBox;
export const radioGroupTopology = form.topologies.radioGroup;
export const selectTopology = form.topologies.select;
export const selectedValueTopology = form.topologies.selectedValue;
export const appMenuTopology = libro.topologies.appMenu;
export const menuTopology = libro.topologies.menu;
export const navbarTopology = libro.topologies.navbar;
