import rdf, { SomeTerm } from '@ontologies/core';
import { t } from 'i18next';
import { useLRS } from 'link-redux';
import React from 'react';

import { handle } from '../../../lib/logging';
import { ShowSnackbar } from '../../../middleware/actions';
import Uploader from '../../FormInput/Input/FileInput/Uploader';
import { getMetaContent } from '../../../lib/libro/dom';

type UploadFile = (newFile: File, handleUploadFinished: (signedId: SomeTerm) => void) => void;

export type UseFileUpload = [uploadFile: UploadFile, progress: number | undefined];

const blogUploadIRI = getMetaContent('blob-upload');

export const useFileUpload = (): UseFileUpload => {
  const directUploadUrl = blogUploadIRI;

  const lrs = useLRS();

  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const handleError = (error: Error) => {
    setUploading(false);
    lrs.actions.get(ShowSnackbar)(t('form.fileUploadFailed'));
    handle(error);
  };

  const handleProgress = (e: ProgressEvent) => {
    if (e.lengthComputable) {
      setProgress((e.loaded / e.total) * 100);
    }
  };

  const uploadFile = React.useCallback<UploadFile>((newFile, handleUploadFinished) => {
    setUploading(true);
    setProgress(0);

    const handleFinish = (signedId: string) => {
      setUploading(false);
      handleUploadFinished(rdf.literal(signedId));
    };

    if (!directUploadUrl) {
      throw new Error('No upload url in manifest');
    }

    new Uploader(directUploadUrl, handleProgress, handleFinish, handleError).upload(newFile);
  }, []);

  return [uploadFile, uploading ? progress : undefined];
};
