import { makeStyles } from '@mui/styles';
import * as as from '@ontologies/as';
import { t } from 'i18next';
import {
  useGlobalIds,
  useLRS,
} from 'link-redux';
import React, {
  FC,
  useCallback,
} from 'react';

import { quadruple } from '../../../lib/libro/quadruple';
import ButtonWithFeedback from '../../ButtonWithFeedback';
import ontola from '../../../../ontology/ontola';
import {
  LibroTheme,
  Margin,
} from '../../../theme/types';
import { useCollectionOptions } from '../CollectionContext';

const useStyles = makeStyles<LibroTheme>((theme) => ({
  paginationButton: {
    marginTop: theme.spacing(Margin.Medium),
  },
}));

const InfinitePagination: FC = () => {
  const lrs = useLRS();
  const { currentCollection } = useCollectionOptions();
  const pages = useGlobalIds(currentCollection, ontola.pages);
  const lastPage = pages && pages[pages.length - 1];
  const [nextPage] = useGlobalIds(lastPage, as.next);
  const classes = useStyles();

  const onClick = useCallback(
    () => new Promise<void>((resolve) => {
      if (currentCollection) {
        lrs.store.addQuads([
          quadruple(currentCollection, ontola.pages, nextPage),
        ]);
        (lrs as any).broadcast();
      }

      resolve();
    }),
    [lrs, currentCollection, nextPage],
  );

  if (!lastPage) {
    return null;
  }

  return (
    <ButtonWithFeedback
      className={classes.paginationButton}
      onClick={onClick}
    >
      {t('collection.loadMore')}
    </ButtonWithFeedback>
  );
};

export default InfinitePagination;
