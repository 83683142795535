import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import AssociationInput from '../../FormInput/Input/AssociationInput';
import { useItemFactory } from '../../FormInput/Input/AssociationInput/lib/useItemFactory';
import useFormField from '../../Form/hooks/useFormField';

const AssociationFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const newItem = useItemFactory(subject);

  const fieldProps = useFormField(subject, {
    alwaysVisible: false,
    newItem,
  });

  return (
    <FormField
      {...fieldProps}
      className={fieldProps.className}
      inputComponent={AssociationInput}
      label={fieldProps.values.length > 0 ? fieldProps.label : undefined}
    />
  );
};

export default AssociationFormField;
