import { SomeNode } from 'link-lib';
import React, { EventHandler } from 'react';

import {
  OnInputChange,
  SubmissionErrors,
} from '../FormField/FormFieldTypes';

export interface FormContext {
  autofocusForm: boolean;
  formID: string;
  formIRI: SomeNode;
  formSection?: string;
  handleParentChange?: OnInputChange;
  object?: SomeNode;
  onKeyUp?: EventHandler<any>;
  parentObject?: SomeNode;
  sessionStore?: Storage;
  submissionErrors?: SubmissionErrors;
  submitting?: boolean;
}

export const formContext = React.createContext<Partial<FormContext>>({});
