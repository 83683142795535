import React, {
  FC,
  ReactNode,
} from 'react';

import Grid from '../../../topologies/Grid';
import CollectionHeader from '../CollectionHeader';
import CollectionPagination from '../Pagination/CollectionPagination';

const GridFrame: FC<{children: ReactNode}> = ({ children }) => (
  <React.Fragment>
    <CollectionHeader />
    <Grid container>
      {children}
    </Grid>
    <CollectionPagination />
  </React.Fragment>
);

export default GridFrame;
