import { SomeTerm } from '@ontologies/core';
import { SomeNode } from 'link-lib';
import {
  SubjectProp,
  useResourceLink,
} from 'link-redux';
import React, {
  FC,
  MouseEventHandler,
  useMemo,
} from 'react';

import TableCell from '../../topologies/Table/TableCell';
import TableRow from '../../topologies/Table/TableRow';

import CollectionPages from './CollectionPages';
import {
  Column,
  RendererProps,
} from './Frame/TableFrame';

const CollectionCell = ({ column, subject, term }: {column: Column, subject: SomeNode, term: SomeTerm}) => {
  if (!term) {
    return <TableCell />;
  }

  const Renderer = column.render as FC<RendererProps>;
  
  return (
    <TableCell key={column.property.value}>
      {Renderer ? (
        <Renderer
          property={column.property}
          subject={subject}
          term={term}
        />
      ) : term.value}
    </TableCell>
  );
};

const CollectionRows: FC<{columns: Column[], onRowClick?: (resource: SomeNode) => void}> = ({ columns, onRowClick }) => {
  const renderer: FC<SubjectProp> = ({ subject }) => {
    const propMap = useMemo(() => columns.reduce((acc, column, index) => ({
      ...acc,
      [index]: column.property, 
    }), {}), [columns]);
    const properties = useResourceLink(subject, propMap) as Record<number, SomeTerm>;

    if (!properties) {
      return null;
    }

    const handleRowClick: MouseEventHandler | undefined = onRowClick ? (e) => {
      e.preventDefault();
      onRowClick(subject);
    } : undefined;

    return (
      <TableRow onClick={handleRowClick}>
        {columns.map((column, index) => (
          <CollectionCell
            column={column}
            key={column.property.value}
            subject={subject}
            term={properties[index]}
          />
        ))}
      </TableRow>
    );
  };

  return <CollectionPages renderer={renderer} />;
};

export default CollectionRows;
