import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { Trigger } from '../DropdownMenu/TriggerButton';
import { TriggerButtonNavBar } from '../DropdownMenu/TriggerButtonNavBar';
import AppMenu, { AppMenuChildProps } from '../../topologies/AppMenu';
import DropdownMenuItem from '../DropdownMenu/DropdownMenuItem';

import { usePriorityNavigation } from './lib/usePriorityNavigation';
import NavbarItem, { NavbarItemInterface } from './NavbarItem';

export const navBarContentItemsCID = 'CID-NavBarContentItems';

interface NavbarNavigationsMenuProps {
  menuItems: NavbarItemInterface[];
  showDropdown?: boolean;
}

const useStyles = makeStyles({
  navBarContentItems: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  triggerButtonIcon: {
    transition: 'transform 100ms ease',
  },
  triggerButtonIconOpen: {
    transform: 'rotateZ(180deg)',
  },
});

const createTrigger: (classes: ReturnType<typeof useStyles>) => Trigger = (classes) => (props) => {
  const iconClassName = clsx({
    [classes.triggerButtonIcon]: true,
    [classes.triggerButtonIconOpen]: props.open,
  });

  return (
    <TriggerButtonNavBar
      {...props}
      endIcon={<ArrowDropDownIcon className={iconClassName} />}
    />
  );
};

const NavbarLeftItems = ({ menuItems, showDropdown }: NavbarNavigationsMenuProps): JSX.Element => {
  const classes = useStyles();
  const navBarRef = React.useRef<HTMLDivElement>(null);

  const { addObservedItem, hiddenItems } = usePriorityNavigation(navBarRef.current, menuItems, showDropdown);

  return (
    <React.Fragment>
      <div
        className={clsx(
          navBarContentItemsCID,
          classes.navBarContentItems,
        )}
        ref={navBarRef}
      >
        {menuItems.map((props) => (
          <NavbarItem
            addObservedItem={addObservedItem}
            key={props.path}
            {...props}
          />
        ))}
      </div>
      {(hiddenItems.length > 0) && (
        <AppMenu trigger={createTrigger(classes)}>
          {({ handleClose }: AppMenuChildProps) => hiddenItems?.map(({ label, path }) => (
            <DropdownMenuItem
              hideIcon
              href={path}
              key={path}
              label={label}
              onClose={handleClose}
            />
          ))}
        </AppMenu>
      )}
    </React.Fragment>
  );
};

export default NavbarLeftItems;
