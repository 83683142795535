/* eslint @typescript-eslint/no-magic-numbers: 0 */

import { AppConfig } from '../components/App/typescript';

import { getMetaContent } from './libro/dom';

export const CONTAINER_ELEMENT = 'App__container';
export const FRONTEND_ACCEPT = 'application/empathy+json';

export const appConfig = (key: keyof AppConfig) => GLOBALS.config[key];

export const appName = getMetaContent('app') ?? 'default';
