import * as schema from '@ontologies/schema';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ClickAwayListener, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import {
  useGlobalIds,
  useValues,
} from 'link-redux';
import React from 'react';

import ontola from '../../../ontology/ontola';

import { useCollectionOptions } from './CollectionContext';
import CollectionCreateButton, { TriggerType } from './CollectionCreateButton';
import CollectionDownloadButton from './CollectionDownloadButton';
import CollectionFilterToggle, { CollectionFilterProps } from './CollectionFilterToggle';
import CollectionSortButton from './CollectionSortButton';
import { useFilterOptions } from './FilterComboInput/lib/useFilterOptions';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
}));

export const HeaderFloat: React.FC<CollectionFilterProps> = ({
  filterContainerRef,
}) => {
  const {
    currentCollection,
    hidePagination,
  } = useCollectionOptions();
  const classes = useStyles();
  const [filterOptions] = useFilterOptions();
  const [sortOptions] = useValues(currentCollection, ontola.sortOptions);
  const [downloadUrl] = useGlobalIds(currentCollection, schema.downloadUrl);
  const renderPagination = !hidePagination;

  const [renderButtons, setRenderButtons] = React.useState(false);

  const handleClickAway = React.useCallback(() => {
    setRenderButtons(false);
  }, [setRenderButtons]);

  const buttonCount = [
    renderPagination && !!downloadUrl,
    renderPagination && !!filterOptions,
    renderPagination && !!sortOptions,
  ].filter((value) => value).length;

  if (buttonCount === 0) {
    return (
      <CollectionCreateButton trigger={TriggerType.TextWithIcon} />
    );
  }

  const buttons = (
    <React.Fragment>
      {renderPagination && <CollectionFilterToggle filterContainerRef={filterContainerRef} />}
      {renderPagination && <CollectionSortButton />}
      <CollectionDownloadButton />
    </React.Fragment>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span className={classes.wrapper}>
        <CollectionCreateButton trigger={TriggerType.TextWithIcon} />
        {(buttonCount === 1) ? buttons : (
          <React.Fragment>
            {renderButtons && buttons}
            {!renderButtons && (
              <IconButton
                aria-label={t('collection.actionsAriaLabel')}
                size="small"
                onClick={() => setRenderButtons(true)}
              >
                <MoreHorizIcon />
              </IconButton>
            )}
          </React.Fragment>
        )}
      </span>
    </ClickAwayListener>
  );
};
