import * as schema from '@ontologies/schema';
import {
  useGlobalIds,
  useStrings,
} from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React, {
  FC,
  MouseEventHandler,
} from 'react';

import ontola from '../../../ontology/ontola';
import Link, { IsActiveCheck } from '../Link';

interface MenuItemCardRowProps extends SubjectProp {
  isActive: IsActiveCheck;
  onClick: MouseEventHandler;
}

const MenuItemCardRow: FC<MenuItemCardRowProps> = ({
  isActive,
  onClick,
  subject,
}) => {
  const [href] = useGlobalIds(subject, ontola.href);
  const [name] = useStrings(subject, schema.name);

  if (!href) {
    return null;
  }

  return (
    <li>
      <Link
        isActive={isActive}
        to={href.value}
        onClick={onClick}
      >
        {name}
      </Link>
    </li>
  );
};

export default MenuItemCardRow;
