import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  dig,
  useDataFetching,
  useGlobalIds,
  useIds,
  useLRS,
  useStrings,
} from 'link-redux';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import { entityIsLoaded } from '../../lib/data';
import { useVisibleActions } from '../Action/hooks/useVisibleActions';
import Button from '../Button';
import HeaderButton from '../Button/HeaderButton';
import { useShowDialog } from '../../hooks/useShowDialog';
import { normalizeFontAwesomeIRI } from '../../lib/iris';
import ontola from '../../../ontology/ontola';
import TriggerButton, { Trigger } from '../DropdownMenu/TriggerButton';
import LinkLoader from '../LinkLoader';

import { useCollectionOptions } from './CollectionContext';
import { useFavoriteActions } from './hooks/useFavoriteActions';
import CollectionCreateDropdown from './CollectionCreateDropdown';

export enum TriggerType {
  TextWithIcon = 'TextWithIcon',
  Icon = 'icon',
  Text = 'text',
}

interface CollectionCreateButtonProps {
  trigger?: TriggerType;
}

const IconTrigger: Trigger<{to: string}> = (props) => {
  const { currentCollection } = useCollectionOptions();
  const [image] = useIds(currentCollection, dig(ontola.createAction, schema.target, schema.image));
  const icon = (image ? normalizeFontAwesomeIRI(image) : undefined) ?? 'plus';

  return (
    <TriggerButton {...props}>
      <FontAwesome name={icon} />
    </TriggerButton>
  );
};

const TextTrigger: Trigger<{to: string}> = ({
  onClick,
  anchorRef,
}) => (
  <Button
    plain
    ref={anchorRef}
    onClick={onClick}
  >
    {t('form.newTrigger')}
  </Button>
);

const TextWithIconTrigger: Trigger<{to: string}> = ({
  onClick,
  title,
  to,
  anchorRef,
}) => {
  const { currentCollection } = useCollectionOptions();
  const [image] = useIds(currentCollection, dig(ontola.createAction, schema.target, schema.image));
  const icon = (image ? normalizeFontAwesomeIRI(image) : undefined) ?? 'plus';

  return (
    <HeaderButton
      anchorRef={anchorRef}
      icon={icon}
      title={title}
      to={to ?? '#'}
      onClick={onClick}
    />
  );
};

const getTrigger = (type?: TriggerType) => {
  switch (type) {
  case TriggerType.Text:
    return TextTrigger;
  case TriggerType.TextWithIcon:
    return TextWithIconTrigger;
  default:
    return IconTrigger;
  }
};

const CollectionCreateButton: React.FC<CollectionCreateButtonProps> = ({
  trigger,
}) => {
  const lrs = useLRS();
  const { currentCollection } = useCollectionOptions();
  const createActions = useGlobalIds(currentCollection, ontola.createAction);
  const validActions = useVisibleActions(createActions);
  const renderedActions = useFavoriteActions(validActions, false);
  const primaryAction = renderedActions[0];
  const [actionName] = useStrings(primaryAction, schema.name);
  const showDialog = useShowDialog(primaryAction);
  const TriggerComponent = getTrigger(trigger);
  useDataFetching(createActions);

  const freshAction = createActions.find((action) => !entityIsLoaded(lrs, action));

  if (freshAction) {
    return (
      <LinkLoader />
    );
  }

  if (renderedActions.length == 0) {
    return null;
  }

  if (renderedActions.length > 1) {
    return (
      <CollectionCreateDropdown
        renderedActions={renderedActions}
        triggerComponent={TriggerComponent}
      />
    );
  }

  return (
    <TriggerComponent
      title={actionName}
      to={primaryAction.value}
      onClick={showDialog}
    />
  );
};

CollectionCreateButton.defaultProps = {
  trigger: TriggerType.Icon,
};

export default CollectionCreateButton;
