import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { deepmerge } from '@mui/utils';

import locales from '../locales';
import { appName } from '../app/lib/config';

const resources = {
  [document.documentElement.lang]: {
    translation: deepmerge(locales[document.documentElement.lang], locales[appName] ?? {}),
  },
};

i18n.use(initReactI18next).init({
  interpolation: {
    // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    escapeValue: false,
  },
  lng: document.documentElement.lang,
  resources,
});
