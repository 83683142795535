import { t } from 'i18next';
import React from 'react';

import useErrorReload from '../../hooks/useErrorReload';
import Button, { ButtonProps } from '../Button';

import { useErrorStatus, useTitleForStatus } from './errorMessages';
import { ErrorComponentProps } from './helpers';

const ErrorButtonWithFeedback = (props: ErrorComponentProps & ButtonProps): JSX.Element => {
  const {
    children,
    linkRequestStatus,
    reloadLinkedObject,
    subject,
  } = props;
  const {
    loading,
    reload,
  } = useErrorReload(subject, reloadLinkedObject);
  const statusCode = useErrorStatus(linkRequestStatus);
  const titleForStatus = useTitleForStatus(statusCode);

  return (
    <Button
      icon="refresh"
      loading={loading}
      title={titleForStatus!}
      onClick={reload}
      {...props}
    >
      {children ?? t('errors.retry')}
    </Button>
  );
};

export default ErrorButtonWithFeedback;
