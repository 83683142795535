import React from 'react';

import FieldLabel from '../FormInput/FieldLabel';

import { formFieldContext } from './FormFieldContext';

const FormFieldLabel: React.FC = () => {
  const {
    label,
    name,
    fieldShape,
  } = React.useContext(formFieldContext);

  if (!label) {
    return null;
  }

  return (
    <span>
      <FieldLabel
        htmlFor={name}
        label={label}
        optional={!fieldShape.required}
      />
    </span>
  );
};

export default FormFieldLabel;
