import {
  NamedNode,
  SomeTerm,
} from '@ontologies/core';
import React, { HTMLAttributes } from 'react';

import { useItemToString } from './useItemToString';

const wrappedOption = (props: HTMLAttributes<HTMLLIElement>, option: SomeTerm, component: JSX.Element): JSX.Element => (
  <li
    {...props}
    key={option.value}
  >
    {component}
  </li>
);

const useRenderOption = (className: string, renderProp?: NamedNode): (props: HTMLAttributes<HTMLLIElement>, option: SomeTerm) => JSX.Element => {
  const itemToString = useItemToString(renderProp);

  return React.useCallback((props: HTMLAttributes<HTMLLIElement>, option: SomeTerm) => wrappedOption(props, option,
    <option
      className={className}
      value={option.value}
    >
      {itemToString(option)}
    </option>,
  ), []);
};

export default useRenderOption;
