import { SomeNode } from 'link-lib';
import { useIds } from 'link-redux';
import React, { FC } from 'react';

import LinkLoader from '../../components/LinkLoader';
import useIsLoading from '../../hooks/useIsLoading';
import ContentDetails from '../../topologies/ContentDetails';
import teamGL from '../../../ontology/teamGL';
import DepartmentDetail from '../../details/DepartmentDetail';
import EventTypeDetail from '../../details/EventTypeDetail';
import LocationDetail from '../../details/LocationDetail';
import ParticipantsCountDetail from '../../details/ParticipantsCountDetail';
import StartDateDetail from '../../details/StartDateDetail';

const EventDetails: FC<{event: SomeNode}> = ({ event }) => {
  const [department] = useIds(event, teamGL.department);
  const loading = useIsLoading(event, department);

  if (loading) {
    return(
      <ContentDetails>
        <LinkLoader />
      </ContentDetails>
    );
  }

  return (
    <ContentDetails>
      <EventTypeDetail event={event} />
      <ParticipantsCountDetail event={event} />
      <DepartmentDetail department={department} />
      <StartDateDetail event={event} />
      <LocationDetail event={event} />
    </ContentDetails>
  );
};

export default EventDetails;
