import { Term } from '@ontologies/core';
import { t } from 'i18next';

import { relativeTimeDestructure } from '../../lib/date';

export interface RelativeDateProps {
  date: Term;
  title?: string;
}

const RelativeDate = ({
  date,
}: RelativeDateProps): JSX.Element | null => {
  if (!date) {
    return null;
  }

  const dateObj = new Date(date.value);
  const timeStructure = relativeTimeDestructure(dateObj.getTime());

  return t('relativeDate', timeStructure);
};

export default RelativeDate;
