import { GridTypeMap, Grid as MaterialGrid } from '@mui/material';
import { TopologyFC, createTopologyProvider } from 'link-redux';
import React from 'react';

import { gridTopology } from '../index';

type GridProps = GridTypeMap['props'] & { className?: string; };

const GridTopology = createTopologyProvider(gridTopology);

const Grid: TopologyFC<GridProps> = ({ children, ...muiGridProps }) => (
  <GridTopology>
    <MaterialGrid
      container
      spacing={6}
      {...muiGridProps}
    >
      {children}
    </MaterialGrid>
  </GridTopology>
);

export default Grid;
