import rdf from '@ontologies/core';
import * as schema from '@ontologies/schema';
import {
  makeStyles,
  useTheme,
} from '@mui/styles';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import React from 'react';
import { t } from 'i18next';

import fa4 from '../../../../ontology/fa4';
import ontola from '../../../../ontology/ontola';
import {
  BreakPoints,
  LibroTheme,
} from '../../../theme/types';
import { useFormFieldForPath } from '../../Form/hooks/useFormFieldForPath';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import ControlledMap from '../../Map/components/ControlledMap';
import { GeometryType } from '../../Map/lib/geometry';
import { getStyles } from '../../Map/lib/helpers';
import {
  Layer,
  MapInteractionCallback,
  MapVariant,
  SearchSelectCallback,
} from '../../Map/lib/types';
import { tryParseFloat } from '../../../lib/numbers';
import { SHADOW_LIGHT } from '../../../theme/base/shadows';

import HiddenRequiredInput from './HiddenRequiredInput';

const useStyles = makeStyles<LibroTheme>((theme) => ({
  locationFlow: {
    [theme.breakpoints.up(BreakPoints.Medium)]: {
      width: 'min(80vw, 600px)',
    },
    [theme.breakpoints.down(BreakPoints.Medium)]: {
      maxHeight: '40vh',
    },
    borderRadius: theme.shape.borderRadius,
    boxShadow: SHADOW_LIGHT,
    height: '35rem',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
  locationInput: {
    flex: 1,
    marginBottom: '1rem',
    position: 'relative',
  },
}));

const buildLocation = (lat: number | undefined, lon: number | undefined) => {
  if (lat && lon) {
    return new Point(fromLonLat([lon, lat]));
  }

  return undefined;
};

const buildFeature = (location: Point, theme: LibroTheme) => {
  const { hoverStyle, style } = getStyles(fa4.ns('map-marker').value, theme);
  const feature = new Feature(location);

  feature.setProperties({
    hoverStyle,
    image: fa4.ns('map-marker'),
    style,
  });

  return feature;
};

const LocationInput: React.FC<InputComponentProps> = ({
  inputValue,
  onChange,
}) => {
  const classes = useStyles();
  const theme = useTheme<LibroTheme>();
  const { fieldShape: { required } } = React.useContext(formFieldContext);

  const latInput = useFormFieldForPath(schema.latitude);
  const lonInput = useFormFieldForPath(schema.longitude);
  const zoomLevelInput = useFormFieldForPath(ontola.zoomLevel);
  const [lat] = latInput.values || [];
  const [lon] = lonInput.values || [];
  const [zoomLevel] = zoomLevelInput.values || [];

  const [location, setLocation] = React.useState<Point | undefined>(
    () => buildLocation(tryParseFloat(lat), tryParseFloat(lon)),
  );
  const [locationLayer, setLocationLayer] = React.useState<Layer[]>([{ features: [] }]);

  React.useEffect(() => {
    if (!inputValue?.value && lat?.value && lon?.value) {
      onChange(rdf.literal(true));
    }

    setLocation(buildLocation(lat?.value, lon?.value));
  }, [inputValue, lat, lon]);
  React.useEffect(() => {
    if (location) {
      const features = [buildFeature(location, theme)];

      setLocationLayer([{
        features,
      }]);
    }
  }, [location]);

  const handleZoom = React.useCallback(
    (newZoom: string | number) => zoomLevelInput.onChange([rdf.literal(newZoom)]),
    [zoomLevelInput],
  );

  const handleNewLocation = (newLon: number, newLat: number) => {
    latInput.onChange([rdf.literal(newLat)]);
    lonInput.onChange([rdf.literal(newLon)]);

    setLocation(buildLocation(newLat, newLon));
  };

  const handleInteraction = React.useCallback<MapInteractionCallback>((newGeometry, newZoom) => {
    if (newGeometry.type === GeometryType.POINT) {
      if (newZoom) {
        zoomLevelInput.onChange([rdf.literal(newZoom)]);
      }

      const { lat: newLat, lon: newLon } = newGeometry.points[0];
      handleNewLocation(newLon, newLat);
    }
  }, []);

  const handleSearchSelect: SearchSelectCallback = ([newLon, newLat]) => {
    handleNewLocation(newLon, newLat);
  };

  return (
    <div className={classes.locationInput}>
      {required && (
        <React.Fragment>
          <HiddenRequiredInput
            customErrorMessage={t('form.mapInput.error')}
            name={latInput.name}
            value={lat?.value}
          />
          <HiddenRequiredInput
            customErrorMessage={t('form.mapInput.error')}
            name={lonInput.name}
            value={lon?.value}
          />
          <HiddenRequiredInput
            customErrorMessage={t('form.mapInput.error')}
            name={zoomLevelInput.name}
            value={zoomLevel?.value}
          />
        </React.Fragment>
      )}
      <ControlledMap
        layers={locationLayer}
        variant={MapVariant.Default}
        onInteraction={handleInteraction}
        onSearchSelect={handleSearchSelect}
        onZoom={handleZoom}
      />
    </div>
  );
};

export default LocationInput;
