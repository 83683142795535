import { useLRS } from 'link-redux';
import React, { MouseEventHandler } from 'react';

import MenuItem from '../MenuItem';

interface DropdownMenuItemProps {
  hideIcon?: boolean;
  href: string;
  icon?: string;
  innerRef?: any;
  label?: string;
  onClick?: MouseEventHandler;
  onClose?: () => void;
}

const DropdownMenuItem = ({
  hideIcon,
  href,
  icon,
  innerRef,
  label,
  onClick,
  onClose,
}: DropdownMenuItemProps): JSX.Element => {
  const lrs = useLRS();

  const sharedProps = {
    icon: hideIcon ? undefined : icon,
    lrs,
    ref: innerRef,
  };

  const handleClick: MouseEventHandler = (e) => {
    if (onClick) {
      e.preventDefault();

      onClick(e);
    } else if (onClose){
      onClose();
    }
  };

  return (
    <MenuItem
      href={href}
      {...sharedProps}
      onClick={handleClick}
    >
      {label}
    </MenuItem>
  );
};

export default DropdownMenuItem;
