import * as schema from '@ontologies/schema';
import {
  useBooleans,
  useNumbers,
  useStrings,
} from 'link-redux';

import app from '../../ontology/app';
import teamGL from '../../ontology/teamGL';
import ontola from '../../ontology/ontola';

export const useCurrentActor = (): {
  activeDaysCount?: number;
  activeVolunteersCount: number;
  actorType?: string;
  admin?: boolean;
  doorsCount?: number;
  firstName?: string;
  futureEventsCount: number;
  id?: number;
  multipleManagedDepartments?: boolean;
  newVolunteersCount: number;
  nextDoorsTarget?: number;
  organizer?: boolean;
  postalDigits?: number;
  volunteersCount: number;
} => {
  const actorIRI = app.c_a;
  const [actorType] = useStrings(actorIRI, ontola.actorType);
  const [firstName] = useStrings(actorIRI, schema.givenName);
  const [id] = useNumbers(actorIRI, teamGL.userId);
  const [postalDigits] = useNumbers(actorIRI, teamGL.postalDigits);
  const [doorsCount] = useNumbers(actorIRI, teamGL.doorsCount);
  const [activeDaysCount] = useNumbers(actorIRI, teamGL.activeDaysCount);
  const [nextDoorsTarget] = useNumbers(actorIRI, teamGL.nextDoorsTarget);
  const [admin] = useBooleans(actorIRI, teamGL.admin);
  const [organizer] = useBooleans(actorIRI, teamGL.organizer);
  const [multipleManagedDepartments] = useBooleans(actorIRI, teamGL.multipleManagedDepartments);
  const [volunteersCount] = useNumbers(actorIRI, teamGL.volunteersCount);
  const [newVolunteersCount] = useNumbers(actorIRI, teamGL.newVolunteersCount);
  const [futureEventsCount] = useNumbers(actorIRI, teamGL.futureEventsCount);
  const [activeVolunteersCount] = useNumbers(actorIRI, teamGL.activeVolunteersCount);

  return {
    activeDaysCount,
    activeVolunteersCount,
    actorType,
    admin,
    doorsCount,
    firstName,
    futureEventsCount,
    id,
    multipleManagedDepartments,
    newVolunteersCount,
    nextDoorsTarget,
    organizer,
    postalDigits,
    volunteersCount,
  };
};
