import {
  SubjectProp,
  useGlobalIds,
  useLRS,
} from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../ontology/ontola';
import teamGL from '../../ontology/teamGL';
import { OnDoneHandler } from '../components/Action/lib/helpers';
import {
  HideDialog,
  Navigate,
} from '../middleware/actions';

import FormDialog from './FormDialog';

interface AddressDialogProps {
  onDone?: OnDoneHandler;
}

const AddressDialog: FC<AddressDialogProps & SubjectProp> = ({
  onDone,
  subject,
}) => {
  const lrs = useLRS();
  const [street] = useGlobalIds(subject, teamGL.street);
  const [action] = useGlobalIds(subject, ontola.createAction);
  const goToStreet = React.useCallback(() => {
    lrs.actions.get(Navigate)(street);
  }, [lrs]);
  const closeDialog = lrs.actions.get(HideDialog) as OnDoneHandler;

  return (
    <FormDialog
      subject={action}
      onCancel={goToStreet}
      onDone={onDone ?? closeDialog}
    />
  );
};

export default AddressDialog;
