
import form from '../../../../ontology/form';
import ontola from '../../../../ontology/ontola';

import AssociationFormField from './AssociationFormField';
import CheckboxesFormField from './CheckboxesFormField';
import CheckboxFormField from './CheckboxFormField';
import Condition from './Condition';
import DateFormField from './DateFormField';
import DelayedFormField from './DelayedFormField';
import FileFormField from './FileFormField';
import HiddenFormField from './HiddenFormField';
import LocationFormField from './LocationFormField';
import MultipleEmailFormField from './MultipleEmailFormField';
import PasswordFormField from './PasswordFormField';
import PostalRangeFormField from './PostalRangeFormField';
import RadioGroupFormField from './RadioGroupFormField';
import ResourceField from './ResourceField';
import SelectFormField from './SelectFormField';
import SliderFormField from './SliderFormField';
import TextFormField from './TextFormField';
import ToggleButtonGroupField from './ToggleButtonGroupField';
import UrlFormField from './UrlFormField';

const fieldTypes = {
  [form.AssociationInput.value]: AssociationFormField,
  [form.CheckboxGroup.value]: CheckboxesFormField,
  [form.CheckboxInput.value]: CheckboxFormField,
  [form.ColorInput.value]: DelayedFormField,
  [form.DateInput.value]: DateFormField,
  [form.DateTimeInput.value]: DateFormField,
  [form.FileInput.value]: FileFormField,
  [form.HiddenInput.value]: HiddenFormField,
  [form.LocationInput.value]: LocationFormField,
  [form.MultipleEmailInput.value]: MultipleEmailFormField,
  [form.NumberInput.value]: DelayedFormField,
  [form.PasswordInput.value]: PasswordFormField,
  [form.PostalRangeInput.value]: PostalRangeFormField,
  [form.RadioGroup.value]: RadioGroupFormField,
  [form.ResourceField.value]: ResourceField,
  [form.SelectInput.value]: SelectFormField,
  [form.SliderInput.value]: SliderFormField,
  [form.TextInput.value]: TextFormField,
  [form.EmailInput.value]: TextFormField,
  [form.TextAreaInput.value]: TextFormField,
  [form.MarkdownInput.value]: TextFormField,
  [form.ToggleButtonGroup.value]: ToggleButtonGroupField,
  [form.UrlInput.value]: UrlFormField,
  [ontola.Condition.value]: Condition,
};

export default fieldTypes;
