import { MapBrowserEvent, Map as OLMap } from 'ol';
import { pointerMove, singleClick } from 'ol/events/condition';
import { FeatureLike } from 'ol/Feature';
import Select from 'ol/interaction/Select';
import { Vector } from 'ol/layer';
import { toLonLat } from 'ol/proj';
import { StyleFunction } from 'ol/style/Style';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  ClusterSelectCallback,
  FeatureSelectCallback,
  MapInteractionCallback,
} from '../lib/types';
import { GeometryType } from '../lib/geometry';

import { useSelectHandler } from './useSelectHandler';

type MapClickHandler = (e: MapBrowserEvent<UIEvent>) => boolean;

const defaultStyleFunction = new Vector().getStyleFunction() ?? (() => null);

export const getStyle = (styleName: string): StyleFunction => (
  (feature: FeatureLike) => {
    const features = feature?.get('features');
    const styleFunction = (features?.[0] || feature).get(styleName);

    return styleFunction ? styleFunction(feature) : defaultStyleFunction(feature, 0);
  }
);

const getHoverSelect = (): Select =>
  new Select({
    condition: pointerMove,
    style: getStyle('hoverStyle'),
  });

export const getSelect = (
  setDeselect: Dispatch<SetStateAction<undefined | (() => void)>>,
  handleSelect: (e: any) => void,
): Select => {
  const select = new Select({
    condition: singleClick,
    style: getStyle('hoverStyle'),
  });
  select.on('select', handleSelect);
  setDeselect(() => () => {
    select.getFeatures().clear();
    handleSelect(undefined);
  });

  return select;
};

const handleMapClick = (
  onInteraction: MapInteractionCallback,
  zoom: number,
): MapClickHandler => (e: MapBrowserEvent<UIEvent>) => {
  const features = e.map.getFeaturesAtPixel(e.pixel);

  // if there are any features the click is handled by the select handler instead
  if (features.length === 0) {
    const [lon, lat] = toLonLat(e.coordinate);
    const geometry = {
      points: [{
        lat,
        lon,
      }],
      type: GeometryType.POINT,
    };

    onInteraction(geometry, e.map.getView().getZoom() || zoom);
  }

  return true;
};

export const useInteraction = (
  zoom: number,
  onInteraction: MapInteractionCallback | undefined,
  map: OLMap | undefined,
  onClusterSelect: ClusterSelectCallback | undefined,
  onSelect: FeatureSelectCallback | undefined,
): [(() => void) | undefined] => {
  const [deselect, setDeselect] = useState<undefined | (() => void)>(undefined);
  const handleSelect = useSelectHandler(onClusterSelect, onSelect);

  // sets handler for clicking empty map space
  useEffect(() => {
    if (!onInteraction || !map) {
      return () => undefined;
    }

    const callback = handleMapClick(onInteraction, zoom);

    map.on('click', callback);

    return () => {
      map?.un('click', callback);
    };
  }, [!!map, onInteraction, zoom]);

  useEffect(() => {
    if (!map) {
      return () => undefined;
    }

    const select = getSelect(setDeselect, handleSelect);
    map.addInteraction(select);

    const hover = getHoverSelect();
    map.addInteraction(hover);

    return () => {
      map.removeInteraction(select);
      map.removeInteraction(hover);
    };
  }, [handleSelect, map]);

  return [deselect];
};
