import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import { useNumbers } from 'link-redux';
import React, { FC } from 'react';

import Detail from '../components/Detail';
import Progress from '../components/Progress';
import teamGL from '../../ontology/teamGL';

const ParticipantsCountDetail: FC<{ event: SomeNode }> = ({ event }) => {
  const [currentCount] = useNumbers(event, teamGL.participantsCount);
  const [desiredCount] = useNumbers(event, teamGL.desiredCount);

  const text = desiredCount === 0
    ? currentCount.toString()
    : (
      <div>
        <Progress
          detail
          max={desiredCount}
          maxWidth="10em"
          min={0}
          value={Math.min(currentCount, desiredCount)}
        />
        {` ${currentCount}/${desiredCount}`}
      </div>
    );

  return (
    <Detail
      icon="users"
      text={text}
      title={t('details.participants')}
    />
  );
};

export default ParticipantsCountDetail;
