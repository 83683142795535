import { makeStyles } from '@mui/styles';
import { NamedNode } from '@ontologies/core';
import React, { ReactNode } from 'react';

import { CardFloat } from '../../topologies/Card';
import Heading from '../Heading';
import Link, { LinkProps } from '../Link';

interface PropTypes {
  link?: NamedNode;
  float?: React.ReactNode;
  noMargin?: boolean;
  title: string | ReactNode;
}

const useStyles = makeStyles(() => ({
  header: {
    flexGrow: 1,
  },
  menuWithMargin: {
    marginRight: '-1rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const HeaderWithFloat: React.FC<PropTypes> = ({
  float,
  link,
  noMargin,
  title,
}) => {
  const styles = useStyles();
  const Wrapper = link ? Link : React.Fragment;
  const wrapperProps = link ? {
    target: '_top',
    to: link.value,
  } : {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Wrapper {...wrapperProps as LinkProps}>
          <Heading>
            {title}
          </Heading>
        </Wrapper>
      </div>
      {float && (
        <div className={noMargin ? undefined : styles.menuWithMargin}>
          <CardFloat>
            {float}
          </CardFloat>
        </div>
      )}
    </div>
  );
};

export default HeaderWithFloat;
