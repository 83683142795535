import * as schema from '@ontologies/schema';
import {
  NamedNode,
  SomeTerm,
} from '@ontologies/core';
import { useLRS } from 'link-redux';
import React from 'react';

import { entityIsLoaded } from '../../../../lib/data';
import { namePredicates } from '../../../../lib/predicates';
import { isResource } from '../../../../lib/typeCheckers';
import libro from '../../../../../ontology/libro';

export const useItemToString = (prop?: NamedNode): ((i: SomeTerm | undefined | null) => string) => {
  const lrs = useLRS();

  return React.useCallback((item: SomeTerm | undefined | null) => {
    if (typeof item === 'undefined' || item === null) {
      return '';
    }

    if (!isResource(item)) {
      return item.value ?? '';
    }

    if (item === libro.null) {
      return '';
    }

    if (!entityIsLoaded(lrs, item)) {
      return 'Loading';
    }

    const label = lrs.getResourceProperty(item, [prop ?? schema.name, ...namePredicates]);

    return label?.value ?? item.value ?? '';
  }, [lrs]);
};
