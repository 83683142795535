import { isNamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { SomeNode } from 'link-lib';
import {
  useIds,
  useValues,
} from 'link-redux';

import {
  EntryPointFormProps,
  ProvidedEntryPointProps,
} from '../EntryPointForm';
import ll from '../../../../ontology/ll';
import useSubmitHandler, { SubmitSuccessHandler } from '../../Action/hooks/useSubmitHandler';

export interface EntryPointProps extends Partial<ProvidedEntryPointProps> {
  modal?: boolean;
  onDone?: SubmitSuccessHandler;
  onStatusForbidden?: () => Promise<void>;
}

const formIDFromEntryPoint = (entryPoint: SomeNode) => {
  if (isNamedNode(entryPoint)) {
    const formURL = new URL(entryPoint.value);

    return [formURL.origin, formURL.pathname].join('');
  }

  return entryPoint.value;
};

const useEntryPointFormProps = (entryPoint: SomeNode, props: Partial<EntryPointProps>): EntryPointFormProps => {
  const [action] = useIds(entryPoint, schema.isPartOf);
  const [actionBody] = useIds(entryPoint, ll.actionBody);
  const [httpMethod] = useValues(entryPoint, schema.httpMethod);
  const [url] = useValues(entryPoint, schema.url);

  const [object] = useIds(action, schema.object);

  const formID = props.formID ?? formIDFromEntryPoint(entryPoint);

  const {
    modal,
    onCancel,
    onDone,
    onStatusForbidden,
  } = props;

  const onSubmit = useSubmitHandler({
    entryPoint: entryPoint,
    formID,
    modal,
    onDone,
    onStatusForbidden,
  });

  return {
    action,
    actionBody,
    autoSubmit: props.autoSubmit || false,
    autofocusForm: props.autofocusForm ?? true,
    entryPoint: entryPoint,
    formID,
    formInstance: props.formInstance,
    httpMethod,
    object,
    onCancel,
    onKeyUp: props.onKeyUp,
    onSubmit,
    sessionStore: props.sessionStore ?? sessionStorage,
    url,
  };
};

export default useEntryPointFormProps;
