import {
  LaxNode,
  useDataFetching,
  useLRS,
} from 'link-redux';

import { entityIsLoaded } from '../lib/data';

const useIsLoading = (...subjects: LaxNode[]): boolean => {
  const lrs = useLRS();
  useDataFetching(subjects);

  return subjects.findIndex((subject) => subject && !entityIsLoaded(lrs, subject)) !== -1;
};

export default useIsLoading;
