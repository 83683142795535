import clsx from 'clsx';
import { Coordinate } from 'ol/coordinate';
import 'ol/ol.css';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import { ButtonVariant } from '../../Button';
import ErrorButtonWithFeedback from '../../Error/ErrorButtonWithFeedback';
import OverlayContainer from '../OverlayContainer';
import LinkLoader from '../../LinkLoader';
import useMap, { UseMapProps } from '../hooks/useMap';
import useMapStyles from '../hooks/useMapStyles';
import useOverlay from '../hooks/useOverlay';
import {
  MapVariant,
  NavigateCallback,
} from '../lib/types';

export interface MapCanvasProps extends UseMapProps {
  navigate?: NavigateCallback;
  overlay?: JSX.Element;
  overlayPosition?: Coordinate;
  variant?: MapVariant;
}

const MapCanvas = (props: MapCanvasProps): JSX.Element => {
  const {
    variant = MapVariant.Default,
    navigate,
    overlay,
    overlayPosition,
  } = props;

  const {
    error,
    map,
    mapToken,
    mapRef,
    requestMapToken,
  } = useMap(props);

  const overlayRef = useOverlay({
    map,
    navigate,
    overlay,
    overlayPosition,
  });

  const classes = useMapStyles();

  const wrapperClassName = clsx({
    [classes.container]: true,
    [classes.containerLarge]: variant === MapVariant.Fullscreen || variant === MapVariant.MapQuestion,
  });

  const canvasClassName = clsx({
    [classes.canvas]: true,
    [classes.canvasLarge]: variant === MapVariant.Fullscreen || variant === MapVariant.MapQuestion,
    [classes.canvasFullscreen]: variant === MapVariant.Fullscreen,
    [classes.canvasMapQuestion]: variant === MapVariant.MapQuestion,
  });

  if (mapToken.loading) {
    return <LinkLoader />;
  }

  const currentError = error || mapToken.error;

  if (!mapRef || currentError) {
    return (
      <div
        className={wrapperClassName}
        data-testid="map-view"
      >
        <div className={canvasClassName} />
        <div className={classes.indicator}>
          <FontAwesome name="map-o" />
          {currentError && (
            <ErrorButtonWithFeedback
              reloadLinkedObject={requestMapToken}
              variant={ButtonVariant.Box}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={wrapperClassName}
      data-testid="map-view"
    >
      <div
        className={canvasClassName}
        data-testid="map-canvas"
        ref={mapRef}
      />
      <OverlayContainer overlayRef={overlayRef.current}>
        {overlay}
      </OverlayContainer>
    </div>
  );
};

export default MapCanvas;
