import React from 'react';

import Card from '../../topologies/Card';
import Container from '../../topologies/Container';
import CardContent from '../Card/CardContent';

import CardError from './CardError';
import { ErrorComponentProps } from './helpers';

const PageError = (props: ErrorComponentProps): JSX.Element => (
  <Container>
    <Card>
      <CardContent endSpacing>
        <CardError {...props} />
      </CardContent>
    </Card>
  </Container>
);

export default PageError;
