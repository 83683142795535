import * as schema from '@ontologies/schema';
import * as sh from '@ontologies/shacl';
import {
  SubjectProp,
  useGlobalIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import { formContext } from '../../Form/FormContext';
import { useFormGroup } from '../../Form/hooks/useFormGroup';
import Heading, { HeadingSize } from '../../Heading';
import Markdown from '../../Markdown';

const ResourceField: FC<SubjectProp> = ({ subject }) => {
  const [path] = useGlobalIds(subject, sh.path);
  const [label] = useStrings(subject, schema.name);
  const [text] = useStrings(subject, schema.text);
  const { object } = React.useContext(formContext);
  const [image] = useGlobalIds(object, path);
  const [contentUrl] = useGlobalIds(image, schema.contentUrl);

  const { setHasContent } = useFormGroup();
  React.useLayoutEffect(() => {
    if (setHasContent) {
      setHasContent(true);
    }
  }, [setHasContent]);

  return (
    <React.Fragment>
      <Heading size={HeadingSize.MD}>
        {label}
      </Heading>
      {contentUrl && (
        <img
          data-test="ImageObject-image"
          src={contentUrl?.value}
        />
      )}
      <Markdown text={text} />
    </React.Fragment>
  );
};

export default ResourceField;
