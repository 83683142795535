import { NamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import {
  array,
  useGlobalIds,
  useIds,
  useLRS,
  useStrings,
} from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React, {
  FC,
  MouseEventHandler,
} from 'react';
import FontAwesome from 'react-fontawesome';

import ontola from '../../../ontology/ontola';
import { normalizeFontAwesomeIRI } from '../../lib/iris';
import Menu from '../../topologies/Menu';
import ResourceBoundary from '../ResourceBoundary';

import LinkedDropdownMenuItem from './LinkedDropdownMenuItem';
import TriggerButton, { Trigger } from './TriggerButton';

interface LinkedDropdownMenuProps extends SubjectProp {
  label: NamedNode;
}

const LinkedDropdownMenu: FC<LinkedDropdownMenuProps> = ({
  subject,
  label,
}) => {
  const lrs = useLRS();
  const [menu] = useIds(subject, label);
  const items = useIds(menu, array(ontola.menuItems));
  const [image] = useGlobalIds(menu, schema.image);
  const [action] = useGlobalIds(menu, ontola.action);
  const [name] = useStrings(menu, schema.name);
  const [loading, setLoading] = React.useState(false);
  const actionHandler = React.useCallback<MouseEventHandler>((e) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);

    return lrs.exec(action!).then(() => setLoading(false));
  }, [action]);

  if (items.length == 0) {
    return (
      <TriggerButton
        title={name}
        onClick={actionHandler}
      >
        {loading ? (
          <FontAwesome
            spin
            name="spinner"
          />
        ) : (
          image && <FontAwesome name={normalizeFontAwesomeIRI(image)} />
        )}
      </TriggerButton>
    );
  }

  const trigger: Trigger = (props) => (
    <TriggerButton {...props}>
      {image && <FontAwesome name={normalizeFontAwesomeIRI(image)} />}
    </TriggerButton>
  );

  return (
    <ResourceBoundary subject={menu}>
      <Menu
        title={name}
        trigger={trigger}
      >
        {items.map((item) => (
          <LinkedDropdownMenuItem
            key={item.value}
            subject={item}
          />
        ))}
      </Menu>
    </ResourceBoundary>
  );
};

export default LinkedDropdownMenu;
