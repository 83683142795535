import React, { KeyboardEvent } from 'react';

const SPACE_KEY = 32;
const ENTER_KEY = 13;

export const useOnClickToOnKeyUp = (onAction: (e: any) => void): (e: KeyboardEvent<unknown>) => void => (
  React.useCallback((e) => {
    if ([SPACE_KEY, ENTER_KEY].includes(e.keyCode) && onAction) {
      onAction(e);
    }
  }, [onAction])
);
