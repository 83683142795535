import * as sh from '@ontologies/shacl';
import {
  SubjectProp,
  useIds,
} from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../../ontology/ontola';
import { formContext } from '../../Form/FormContext';
import useShapeValidation from '../../Form/hooks/useShapeValidation';
import FormFieldWrapper from '../FormFieldWrapper';

const Condition: FC<SubjectProp> = ({
  subject,
}) => {
  const shapes = useIds(subject, sh.node);
  const { object } = React.useContext(formContext);
  const [pass] = useShapeValidation(shapes, object);
  const [field] = useIds(subject, ontola.pass);

  if (!pass) {
    return null;
  }

  return <FormFieldWrapper subject={field} />;
};

export default Condition;
