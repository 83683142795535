import { TopologyFC, createTopologyProvider } from 'link-redux';
import React, { MouseEventHandler, Ref } from 'react';

import { selectTopology } from '../index';

export interface SelectProps {
  innerRef?: Ref<HTMLUListElement>;
  role?: string;
  scrollIntoView?: any;
  onMouseDown?: MouseEventHandler;
}

const SelectTopology = createTopologyProvider(selectTopology);

const Select: TopologyFC<SelectProps> = ({
  children,
  onMouseDown,
  innerRef,
}) => (
  <SelectTopology>
    <ul
      ref={innerRef}
      role="listbox"
      onMouseDown={onMouseDown}
    >
      {children}
    </ul>
  </SelectTopology>
);

export default Select;
