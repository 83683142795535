import { ChevronRight } from '@mui/icons-material';
import * as schema from '@ontologies/schema';
import {
  useGlobalIds,
  useStrings,
} from 'link-redux';
import { SubjectProp } from 'link-redux/dist-types/types';
import React from 'react';

import { namePredicates } from '../../lib/predicates';
import Link from '../Link';

import breadcrumbStyles from './BreadcrumbStyles';

interface PropTypes extends SubjectProp {
  first?: boolean;
}

/**
 * A single part of a BreadcrumbsBar
 * @returns {component} Component
 */
const Breadcrumb: React.FC<PropTypes> = ({
  first,
  subject,
}) => {
  const classes = breadcrumbStyles();
  const [name] = useStrings(subject, namePredicates);
  const [url] = useGlobalIds(subject, schema.url);
  const [parent] = useGlobalIds(subject, schema.isPartOf);

  if (!name) {
    return null;
  }

  return (
    <React.Fragment>
      <Breadcrumb subject={parent} />
      <Link
        className={classes.default}
        title={name}
        to={(url ?? subject)?.value}
      >
        <div className="Breadcrumb__text">
          {name}
        </div>
      </Link>
      {!first && <ChevronRight fontSize="small" />}
    </React.Fragment>
  );
};

export default Breadcrumb;
