import * as schema from '@ontologies/schema';
import { SomeNode } from 'link-lib';
import { useStrings } from 'link-redux';
import React, { FC } from 'react';

import Detail from '../components/Detail';
import emoji from '../lib/emoji';

const DepartmentDetail: FC<{department: SomeNode}> = ({ department }) => {
  const [name] = useStrings(department, schema.name);
  const [url] = useStrings(department, schema.url);

  if (!department) {
    return null;
  }

  return (
    <Detail
      text={emoji(`🌍 ${name}`)}
      url={url}
    />
  );
};

export default DepartmentDetail;
