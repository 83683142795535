import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useLRS,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import { isInvalidActionStatus } from '../components/Action/hooks/useValidActions';
import {
  ActionProps,
  useDoneHandler,
} from '../components/Action/lib/helpers';
import Button from '../components/Button';
import CardContent from '../components/Card/CardContent';
import EntryPointCardMain from '../components/EntryPoint/EntryPointCardMain';
import Heading from '../components/Heading';
import HeadingContext from '../components/Heading/HeadingContext';
import LinkLoader from '../components/LinkLoader';
import Markdown from '../components/Markdown';
import { SignInFormLink } from '../components/SignInForm';
import { HideDialog } from '../middleware/actions';
import CardMain from '../topologies/Card/CardMain';
import Container from '../topologies/Container';

const FormDialog: FC<ActionProps & SubjectProp> = ({
  appendix: Appendix,
  onCancel,
  onDone,
  sessionStore,
  subject,
}) => {
  const [actionStatus] = useGlobalIds(subject, schema.actionStatus);
  const [name] = useStrings(subject, schema.name);
  const [error] = useStrings(subject, schema.error);
  const [target] = useGlobalIds(subject, schema.target);

  const onDoneHandler = useDoneHandler(subject, onDone);
  const lrs = useLRS();

  if (isInvalidActionStatus(actionStatus)) {
    return (
      <Container>
        <HeadingContext>
          <CardMain>
            <CardContent endSpacing>
              <Heading>
                {name}
              </Heading>
              <Markdown text={error} />
              <SignInFormLink Component={Button} />
            </CardContent>
          </CardMain>
        </HeadingContext>
      </Container>
    );
  }

  if (!target) {
    return <LinkLoader />;
  }

  const closeModal = () => lrs.actions.get(HideDialog)();

  return (
    <Container>
      <HeadingContext>
        <CardMain>
          <CardContent endSpacing>
            <Heading>
              {name}
            </Heading>
            <EntryPointCardMain
              sessionStore={sessionStore}
              subject={target}
              onCancel={onCancel ?? closeModal}
              onDone={onDoneHandler}
            />
          </CardContent>
          {Appendix && <Appendix />}
        </CardMain>
      </HeadingContext>
    </Container>
  );
};

export default FormDialog;
