import { Node } from '@ontologies/core';
import {
  array,
  useIds,
} from 'link-redux';
import React from 'react';

import UnorderedList from '../UnorderedList';
import ontola from '../../../ontology/ontola';
import CardContent from '../Card/CardContent';
import { IsActiveCheck } from '../Link';
import {
  CardAppendix,
  CardRow,
} from '../../topologies/Card';
import app from '../../../ontology/app';
import MenuItemCardRow from '../MenuItem/MenuItemCardRow';

export interface AccountHelpersCardAppendixProps {
  currentSubject: Node;
  onClick: React.MouseEventHandler;
}

const AccountHelpersCardAppendix = ({
  currentSubject,
  onClick,
}: AccountHelpersCardAppendixProps): JSX.Element | null => {
  const isActive = React.useCallback<IsActiveCheck>(
    (to: string) => new URL(to).pathname === new URL(currentSubject.value).pathname,
    [currentSubject.value],
  );
  const menu = app.ns('menus/sign_in');
  const menuItems = useIds(menu, array(ontola.menuItems));

  if (menuItems.length == 0) {
    return null;
  }

  return (
    <CardAppendix>
      <CardRow backdrop>
        <CardContent endSpacing>
          <UnorderedList>
            {menuItems.map((menuItem) => (
              <MenuItemCardRow
                isActive={isActive}
                key={menuItem.value}
                subject={menuItem}
                onClick={onClick}
              />
            ))}
          </UnorderedList>
        </CardContent>
      </CardRow>
    </CardAppendix>
  );
};

export default AccountHelpersCardAppendix;
