import {
  BlankNode,
  NamedNode,
} from '@ontologies/core';

export const isFunction = (value: unknown): value is ((...props: any) => any) => typeof value === 'function';

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isPromise = (obj: unknown): obj is Promise<any> => (
  isFunction((obj as Record<string, unknown>)?.then)
);

export const isResource = (obj: unknown): obj is NamedNode | BlankNode => (
  ['NamedNode', 'BlankNode'].includes((obj as Record<string, unknown>)?.termType as string)
);

export const isString = (value: unknown): value is string => typeof value === 'string';
