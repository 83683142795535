import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../FormField';
import { InputComponentProps } from '../FormFieldTypes';
import { InputType } from '../../FormInput/Input/Input';
import InputElement from '../../FormInput/Input/InputElement';
import useFormField from '../../Form/hooks/useFormField';

const UrlInput = (fieldProps: InputComponentProps) => (
  <InputElement
    {...fieldProps}
    type={InputType.Url}
  />
);

const UrlFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: true,
  });

  return (
    <FormField
      {...fieldProps}
      inputComponent={UrlInput}
    />
  );
};

export default UrlFormField;
