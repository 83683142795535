import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import useFormField from '../../Form/hooks/useFormField';
import LocationInput from '../../FormInput/Input/LocationInput';
import FormField from '../FormField';

const LocationFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  return (
    <FormField
      {...fieldProps}
      inputComponent={LocationInput}
    />
  );
};

export default LocationFormField;
