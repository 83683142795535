import React, { FC } from 'react';
import {
  Route,
  Routes,
} from 'react-router';

import { OnDoneHandler } from './components/Action/lib/helpers';
import RouteNotFound from './components/Routes/RouteNotFound';
import WrapRoute from './components/Routes/WrapRoute';
import AddressDialog from './dialogs/AddressDialog';
import BadgeDialog from './dialogs/BadgeDialog';
import CollectionDialog from './dialogs/CollectionDialog';
import EventDialog from './dialogs/EventDialog';
import FormDialog from './dialogs/FormDialog';
import SessionDialog from './dialogs/SessionDialog';

const DialogRoutes: FC<{onDone: OnDoneHandler}> = ({ onDone } ) => (
  <Routes>
    <Route
      element={(
        <WrapRoute
          component={SessionDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/u/session/new"
    />
    <Route
      element={(
        <WrapRoute
          component={AddressDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/addresses/:id"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/addresses/:id/visits/new"
    />
    <Route
      element={(
        <WrapRoute
          component={BadgeDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/badges/:id"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/badges/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/campaigns/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/departments/:id/departments/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/departments/:id/memberships/new"
    />
    <Route
      element={(
        <WrapRoute
          component={EventDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/event_streets/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/participants/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/potential_participants/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/potential_participants/actions/add_all"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/:id/potential_participants/actions/add_group"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/events/new"
    />
    <Route
      element={(
        <WrapRoute
          component={CollectionDialog}
          elementProps={{ renderer: EventDialog }}
        />
      )}
      path="/events"
    />
    <Route
      element={(
        <WrapRoute
          component={CollectionDialog}
          elementProps={{ renderer: EventDialog }}
        />
      )}
      path="/future_events"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/event_streets/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/event_streets/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/future_events/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/groups/:id/group_memberships/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/groups/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/imports/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/imports/:id/activate"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/mail_templates/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/mail_templates/:id/send_test_mail"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/new_users/:id/follow_up/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/pages/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/pages/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/pages/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/participants/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/potential_participants/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_forms/:id/survey_fields/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_forms/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_forms/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_forms/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_fields/:id/edit"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/survey_fields/:id/delete"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/users/:id/departments/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/users/:id/follow_up/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/users/:id/group_memberships/new"
    />
    <Route
      element={(
        <WrapRoute
          component={FormDialog}
          elementProps={{ onDone }}
        />
      )}
      path="/users/:id/memberships/new"
    />
    <Route
      element={(
        <WrapRoute
          component={CollectionDialog}
          elementProps={{ renderer: EventDialog }}
        />
      )}
      path="/postal_codes/:id/events"
    />
    <Route
      element={<RouteNotFound />}
      path="*"
    />
  </Routes>
);

export default DialogRoutes;
