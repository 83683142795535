import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import { useDates } from 'link-redux';
import React, { FC } from 'react';

import Detail from '../components/Detail';
import emoji from '../lib/emoji';

const DATE_FORMAT = {
  day: 'numeric',
  month: 'long',
};
const TIME_FORMAT = {
  hour: 'numeric',
  minute: 'numeric',
};
const DATETIME_FORMAT = {
  ...DATE_FORMAT,
  ...TIME_FORMAT,
};

const isSameDay = (date1: Date, date2: Date) => (
  date1.getDate() === date2.getDate()
  && date1.getMonth() === date2.getMonth()
  && date1.getFullYear() === date2.getFullYear()
);

const dateString = (date1: Date, date2: Date) => {
  if (!date2) {
    return t('glapp.startDate.single', {
      date: date1,
      formatParams: {
        date: DATETIME_FORMAT,
      }, 
    });
  }

  if (isSameDay(date1, date2)) {
    return t('glapp.startDate.sameDay', {
      date1,
      date2,
      formatParams: {
        date1: DATETIME_FORMAT,
        date2: TIME_FORMAT,
      },
    },
    );
  }

  return t('glapp.startDate.range', {
    date1,
    date2,
    formatParams: {
      date1: DATE_FORMAT,
      date2: DATE_FORMAT,
    },
  });
};

const StartDateDetail: FC<{event: SomeNode}> = ({ event }) => {
  const [startDate] = useDates(event, schema.startDate);
  const [endDate] = useDates(event, schema.endDate);

  return (
    <Detail text={emoji(`📅 ${dateString(startDate, endDate)}`)} />
  );
};

export default StartDateDetail;
