import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useIds,
  useStrings,
} from 'link-redux';
import React, {
  EventHandler,
  FC,
  Fragment,
  SyntheticEvent,
} from 'react';

import ll from '../../../ontology/ll';
import EntryPointForm from '../Form/EntryPointForm';
import useEntryPointFormProps, { EntryPointProps } from '../Form/hooks/useEntryPointFormProps';
import Markdown from '../Markdown';

interface EntryPointCardMainProps extends EntryPointProps {
  onCancel?: EventHandler<SyntheticEvent<unknown>>;
}

const EntryPointCardMain: FC<EntryPointCardMainProps & SubjectProp> = (props) => {
  const entryPointFormProps = useEntryPointFormProps(props.subject, props);
  const [text] = useStrings(props.subject, schema.text);
  const [errorResponse] = useIds(entryPointFormProps.action, ll.errorResponse);

  return (
    <Fragment>
      <Markdown text={text} />
      <EntryPointForm
        {...entryPointFormProps}
        errorResponse={errorResponse}
      />
    </Fragment>
  );
};

export default EntryPointCardMain;
