import { SomeNode } from 'link-lib';
import React, { FC } from 'react';

import { useContainerToArr } from '../../hooks/useContainerToArr';
import { useFieldErrorStyles } from '../FormField/FieldHelper';

const FormErrors: FC<{errorResponse?: SomeNode}> = ({ errorResponse }) => {
  const [generalErrors] = useContainerToArr(errorResponse);
  const classes = useFieldErrorStyles();

  if (generalErrors.length === 0) {
    return null;
  }

  return (
    <div>
      {generalErrors.map((error) => (
        <div
          className={classes.fieldError}
          key={error.value}
        >
          {error.value}
        </div>
      ))}
    </div>
  );
};

export default FormErrors;
