import equal from 'fast-deep-equal';
import { FormApi, MutableState } from 'final-form';
import React, { ReactNode } from 'react';
import {
  Form as FinalForm,
  FormRenderProps,
} from 'react-final-form';

import { FormValues } from '../Action/hooks/useSubmitHandler';

interface FormProps {
  autoSubmit?: boolean;
  children?: ((props: FormRenderProps<FormValues, Partial<FormValues>>) => ReactNode) | undefined;
  form?: any;
  formID?: string;
  initialValues?: any;
  onSubmit: (formData?: FormValues, formApi?: FormApi<FormValues>) => Promise<void>;
  subscription?: any;
  validateOnBlur?: boolean;
}

const defaultProps = {
  method: 'post',
  validateOnBlur: false,
};

const mutators = {
  focusField([key]: string[], state: MutableState<Record<string, any>, Record<string, any>>) {
    const field = state.fields[key];

    if (field) {
      field.active = true;
    }
  },
  touchFields: ([lastKey]: string[] | undefined[], state: MutableState<Record<string, any>, Record<string, any>>) => {
    for (const field of Object.keys(state.fields)) {
      state.fields[field].touched = true;

      if (lastKey === field) {
        break;
      }
    }
  },
};

const Form: React.FC<FormProps> = ({
  autoSubmit,
  children,
  form,
  formID,
  initialValues,
  onSubmit,
  subscription,
  validateOnBlur,
}) => {
  const [autoSubmitted, setAutoSubmitted] = React.useState(false);
  React.useEffect(() => {
    if (autoSubmit && !autoSubmitted) {
      setAutoSubmitted(true);
      onSubmit();
    }
  }, [autoSubmit, autoSubmitted]);

  return (
    <FinalForm
      destroyOnUnregister
      form={form}
      initialValues={initialValues}
      initialValuesEqual={equal}
      key={formID}
      mutators={mutators}
      render={children}
      subscription={subscription}
      validateOnBlur={validateOnBlur}
      onSubmit={onSubmit}
    />
  );
};

Form.defaultProps = defaultProps;

export default Form;
