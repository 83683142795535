import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar as MUISnackbar } from '@mui/material';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  SubjectProp,
  useStrings,
} from 'link-redux';
import React, { SyntheticEvent } from 'react';

import { useStrippedMarkdown } from '../../hooks/useStrippedMarkdown';

const AVERAGE_DUTCH_WORDS_PER_MINUTE = 202;
const MIN_SNACKBAR_TIMEOUT = 2750;
const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

interface SnackbarProps extends SubjectProp {
  close: () => void;
}

const calcDuration = (text = '') => {
  const avgWPS = AVERAGE_DUTCH_WORDS_PER_MINUTE / SECONDS_IN_MINUTE;
  const wordAmount = text.split(/\b/)
    .map((x) => x.trim())
    .filter(Boolean)
    .length;

  const duration = Math.round(wordAmount / avgWPS) * MILLISECONDS_IN_SECOND;

  return Math.max(MIN_SNACKBAR_TIMEOUT, duration);
};

const Snackbar = ({ close, subject }: SnackbarProps): JSX.Element => {
  const [text] = useStrings(subject, schema.text);
  const strippedText = useStrippedMarkdown(text);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <MUISnackbar
      TransitionProps={{
        onExited: close,
      }}
      action={[
        <IconButton
          aria-label={t('form.close')}
          color="inherit"
          href="#"
          key="close"
          title={t('form.close')}
          onClick={handleClick}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      autoHideDuration={calcDuration(strippedText)}
      data-testid="current-snackbar"
      message={strippedText}
      open={open}
      onClose={handleClose}
    />
  );
};

export default Snackbar;
