import { makeStyles } from '@mui/styles';
import rdf from '@ontologies/core';
import clsx from 'clsx';
import React from 'react';
import Textarea from 'react-autosize-textarea';

import { LibroTheme } from '../../../theme/types';
import { formContext } from '../../Form/FormContext';
import { formFieldContext } from '../../FormField/FormFieldContext';
import FormFieldTrailer from '../../FormField/FormFieldTrailer';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import {
  fieldInputCID,
  fieldInputCheckboxCID,
  fieldInputHiddenCID,
  fieldInputMarkdownCID,
  useFormStyles,
} from '../../FormField/UseFormStyles';
import TextEditor, { PlainEditorProps } from '../TextEditor';

import Input, {
  PropTypes as InputProps,
  InputType,
} from './Input';

interface StyleProps {
  invalid?: boolean;
  touched?: boolean;
}

export interface InputPropTypes extends InputComponentProps {
  trailer: (props: any) => any;
  type?: InputType;
}

type SharedInputProps = InputProps & Partial<PlainEditorProps> & Partial<Omit<Textarea.Props, keyof InputProps>>;

const TEXTFIELD_MIN_ROWS = 3;

const useStyles = makeStyles<LibroTheme, StyleProps>(() => ({
  startAdornment: {
    fontWeight: 'bold',
  },
}));

const InputElement = ({
  errors,
  inputValue,
  onChange,
  trailer: Trailer,
  type,
}: InputPropTypes): JSX.Element => {
  const {
    onKeyUp,
  } = React.useContext(formContext);
  const {
    autofocus,
    fieldShape,
    meta,
    name,
    startAdornment,
    onBlur,
    onFocus,
    placeholder,
  } = React.useContext(formFieldContext);

  const [focus, setFocus] = React.useState(autofocus);

  const {
    maxInclusive,
    minInclusive,
    minLength,
    required,
  } = fieldShape;
  const {
    active,
    invalid,
    touched,
  } = meta;

  React.useEffect(() => {
    setFocus(autofocus || meta.active);
  }, [meta.active]);

  const classes = useStyles({
    invalid,
    touched,
  });
  const formClasses = useFormStyles();

  const className = clsx({
    'Field__input--active': active,
    [formClasses.fieldInput]: true,
    [fieldInputCID]: true,
    [fieldInputCheckboxCID]: type === InputType.Checkbox,
    [fieldInputHiddenCID]: type === InputType.Hidden,
    [fieldInputMarkdownCID]: type === InputType.Markdown,
  });

  const sharedProps: SharedInputProps = {
    'data-testid': name,
    id: name,
    name,
    onBlur,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      let val;

      if (e && Object.prototype.hasOwnProperty.call(e, 'target')) {
        val = e.target.value;
      } else {
        val = e === null ? '' : e;
      }

      onChange(rdf.literal(val));
    },
    onFocus,
    required,
    value: inputValue?.value,
  };

  let element;

  switch (type) {
  case 'textarea':
    element = Textarea;
    sharedProps.async = true;
    sharedProps.rows = TEXTFIELD_MIN_ROWS;
    sharedProps.maxRows = 50;
    break;
  case 'markdown':
    element = TextEditor;
    sharedProps.id = name;
    sharedProps.rows = TEXTFIELD_MIN_ROWS;
    break;

  default:
    element = 'input';
  }

  return (
    <div className={className}>
      {startAdornment && (
        <div className={classes.startAdornment}>
          {startAdornment}
        </div>
      )}
      <Input
        {...sharedProps}
        autoFocus={focus}
        element={element}
        max={maxInclusive}
        // TODO: [AOD-218] HTML only noscript
        // maxLength={maxLength}
        min={minInclusive}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        type={type}
        onKeyUp={onKeyUp}
      />
      {Trailer && (
        <Trailer
          errors={errors}
          inputValue={inputValue}
        />
      )}
    </div>
  );
};

InputElement.defaultProps = {
  trailer: FormFieldTrailer,
};

export default InputElement;
