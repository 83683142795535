import { makeStyles } from '@mui/styles';
import equal from 'fast-deep-equal';
import { SomeNode } from 'link-lib';
import {
  dig,
  useIds, 
} from 'link-redux';
import React, {
  EventHandler,
  FormEventHandler,
  ReactNode,
} from 'react';

import ll from '../../../ontology/ll';
import { LibroTheme } from '../../theme/types';
import { SubmissionErrors } from '../FormField/FormFieldTypes';
import FormFieldWrapper from '../FormField/FormFieldWrapper';
import Input, { InputType } from '../FormInput/Input/Input';

import { FormContext, formContext } from './FormContext';
import { formFieldsPath } from './lib/diggers';

interface FormBodyProps {
  action?: string;
  autofocusForm?: boolean;
  children: ReactNode;
  className?: string;
  entryPoint: SomeNode;
  formID?: string;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  method?: string;
  object?: SomeNode;
  onKeyUp?: EventHandler<any>;
  sessionStore?: Storage;
  submissionErrors?: SubmissionErrors;
  submitting?: boolean;
}

const useStyles = makeStyles<LibroTheme>((theme) => ({
  form: {
    '& fieldset': {
      '& legend': {
        color: theme.palette.grey.midDark,
        fontSize: theme.typography.fontSizes.large,
        fontWeight: 'bold',
      },
    },
    '& hr': {
      backgroundColor: theme.palette.grey.xLight,
      border: 0,
      height: '1px',
    },
  },
}));

const FormBody = ({
  action,
  autofocusForm,
  children,
  className,
  entryPoint,
  formID,
  handleSubmit,
  method,
  object,
  onKeyUp,
  sessionStore,
  submissionErrors,
  submitting,
}: FormBodyProps): JSX.Element => {
  const [formIRI] = useIds(entryPoint, ll.actionBody);
  const formFields = useIds(formIRI, dig(...formFieldsPath));
  const lowerMethod = method?.toLowerCase();
  const methodInput = lowerMethod && !['get', 'post'].includes(lowerMethod) && (
    <Input
      name="_method"
      type={InputType.Hidden}
      value={method}
    />
  );
  const formMethod = lowerMethod === 'get' ? 'get' : 'post';
  const nextContext = {
    autofocusForm,
    formID,
    formIRI,
    formSection: undefined,
    object,
    onKeyUp,
    parentObject: undefined,
    sessionStore,
    submissionErrors,
    submitting,
  };
  const [context, setContext] = React.useState<Partial<FormContext>>(nextContext);
  React.useEffect(() => {
    if (!equal(context, nextContext)) {
      setContext(nextContext);
    }
  });
  const classes = useStyles();

  return (
    <formContext.Provider value={context}>
      <form
        action={action}
        className={className ?? classes.form}
        data-testid={formID}
        method={formMethod}
        onSubmit={(e) => {
          e.preventDefault();

          if (handleSubmit) {
            handleSubmit(e);
          }
        }}
      >
        {formFields.map((formField) => (
          <FormFieldWrapper
            key={formField.value}
            subject={formField}
          />
        ))}
        {methodInput}
        {children}
      </form>
    </formContext.Provider>
  );
};

export default FormBody;
