import * as schema from '@ontologies/schema';
import { SomeNode } from 'link-lib';
import { useStrings } from 'link-redux';
import React, { FC } from 'react';

import Detail from '../components/Detail';

const LocationDetail: FC<{event: SomeNode}> = ({ event }) => {
  const [location] = useStrings(event, schema.location);

  return (
    <Detail
      icon="map-marker"
      text={location}
    />
  );
};

export default LocationDetail;
