import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import FormField from '../../FormField/FormField';
import useFormField from '../../Form/hooks/useFormField';
import SelectInputField from '../../FormInput/SelectInput/SelectInputField';

const SelectFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject);

  return (
    <FormField
      combinedComponent
      {...fieldProps}
      inputComponent={SelectInputField}
    />
  );
};

export default SelectFormField;
