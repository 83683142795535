import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  FormControlLabel,
  RadioGroup as MaterialRadioGroup,
  Radio,
} from '@mui/material';
import {
  NamedNode,
  SomeTerm,
} from '@ontologies/core';
import {
  TopologyFC,
  createTopologyProvider,
} from 'link-redux';
import React, { EventHandler } from 'react';

import {
  FocusRelatedEventHandler,
  InputValue,
} from '../../components/FormField/FormFieldTypes';
import { useItemToString } from '../../components/FormInput/SelectInput/lib/useItemToString';
import { radioGroupTopology } from '../index';

interface RadioGroupProps {
  loading?: boolean;
  name?: string;
  onBlur: FocusRelatedEventHandler;
  onChange: EventHandler<any>;
  onFocus: FocusRelatedEventHandler;
  options: SomeTerm[];
  renderProp?: NamedNode,
  required?: boolean;
  value?: InputValue;
}

const RadioGroupTopology = createTopologyProvider(radioGroupTopology);

const RadioGroup: TopologyFC<RadioGroupProps> = ({
  options,
  required,
  name,
  value,
  onBlur,
  onChange,
  onFocus,
  renderProp,
}) => {
  const itemToString = useItemToString(renderProp);
  const onChangeMemo = React.useCallback<(event: React.ChangeEvent<HTMLInputElement>, value: string) => void>((_, v) => (
    onChange(options.find((option) => option.value === v))
  ), [onChange, options]);

  return (
    <RadioGroupTopology>
      <div>
        <MaterialRadioGroup
          value={value?.value}
          onChange={onChangeMemo}
        >
          {options.map((option) => (
            <FormControlLabel
              control={(
                <Radio
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  name={name}
                  required={required}
                  onBlur={onBlur}
                  onFocusVisible={onFocus}
                />
              )}
              key={option.value}
              label={itemToString(option)}
              value={option.value}
            />
          ))}
        </MaterialRadioGroup>
      </div>
    </RadioGroupTopology>
  );
};

export default RadioGroup;
