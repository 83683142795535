import * as rdfx from '@ontologies/rdf';
import {
  Resource,
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, { FC } from 'react';
import { useLocation } from 'react-router';

import ontola from '../../../ontology/ontola';
import useIsLoading from '../../hooks/useIsLoading';
import { currentLocationControl } from '../../lib/paths';
import LinkLoader from '../LinkLoader';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const WrapRoute = <T extends SubjectProp, >({ component: Component, elementProps }: { component: FC<T>, elementProps?: Omit<T, 'subject'>}): JSX.Element => {
  const location = useLocation();
  const subject = currentLocationControl(location, false);
  const loading = useIsLoading(subject);
  const types = useGlobalIds(subject, rdfx.type);

  if (loading) {
    return <LinkLoader />;
  }

  if (types.findIndex((type) => type.value.startsWith(ontola.ns('errors').value)) > -1) {
    return (
      <Resource subject={subject} />
    );
  }
  
  return (
    <Component
      {...elementProps as T}
      subject={subject}
    />
  );
};

export default WrapRoute;
