import { fromLonLat } from 'ol/proj';
import React from 'react';

import { LoadingCard } from '../../Loading';
import useMapboxTileUrl from '../hooks/useMapboxTileUrl';
import {
  DEFAULT_LAT,
  DEFAULT_LON,
  DEFAULT_ZOOM,
} from '../hooks/useView';
import {
  MapViewChangeCallback,
  ViewProps,
} from '../lib/types';

import MapCanvas, { MapCanvasProps } from './MapCanvas';

const defaultView = {
  center: fromLonLat([DEFAULT_LON, DEFAULT_LAT]),
  zoom: DEFAULT_ZOOM,
};

const ControlledMap: React.FC<Omit<MapCanvasProps, 'mapboxTileURL' | 'view' | 'onViewChange'>> = (props) => {
  const mapboxTileURL = useMapboxTileUrl();
  const [controlledView, setView] = React.useState<ViewProps>(defaultView);

  const handleViewChange = React.useCallback<MapViewChangeCallback>((newCenter, newZoom) => {
    setView({
      center: newCenter,
      zoom: newZoom,
    });
  }, [setView]);

  if (!mapboxTileURL) {
    return <LoadingCard />;
  }

  return (
    <MapCanvas
      {...props}
      mapboxTileURL={mapboxTileURL}
      view={controlledView ?? defaultView}
      onViewChange={handleViewChange}
    />
  );
};

export default ControlledMap;
