import { TabList } from '@mui/lab';
import Tab from '@mui/material/Tab';
import React, { FC } from 'react';

import { HandleTabChange } from './lib/useTabBar';

interface Tab {
  label: string;
  value: string;
  title?: string;
}

const TabBar: FC<{handleTabChange: HandleTabChange, tabs: Tab[]}> = ({ handleTabChange, tabs }) => (
  <TabList
    allowScrollButtonsMobile
    scrollButtons
    variant="scrollable"
    onChange={handleTabChange}
  >
    {tabs.map((tab) => (
      <Tab
        {...tab}
        key={`${tab.value}${tab.title ?? ''}`}
        sx={{
          paddingY: '2em',
        }}
        value={`${tab.value}${tab.title ?? ''}`}
      />
    ))}
  </TabList>
);

export default TabBar;
