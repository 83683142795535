import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  TopologyFC,
  Type,
  createTopologyProvider,
} from 'link-redux';
import React from 'react';

import { pageHeaderTopology } from '../index';
import {
  BreakPoints,
  LibroTheme,
  Margin,
  Size,
} from '../../theme/types';
import Container from '../Container';

export { default as PageHeaderImageAndTextWrapper } from './PageHeaderImageAndTextWrapper';
export { default as PageHeaderText } from './PageHeaderText';

const defaultPercentage = 50;

interface PageHeaderProps {
  // URL to the background image
  background?: string;
  // Number between 0 and 100
  positionY?: number;
}

const useStyles = makeStyles<LibroTheme, PageHeaderProps>((theme) => ({
  pageHeader: {
    '& .MuiContainer-root': {
      bottom: 0,
      height: '100%',
    },
    marginBottom: theme.spacing(Margin.Large),
    marginTop: `-${theme.spacing(Margin.Medium)}`,
  },

  pageHeaderBackground: {
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '25vh',
    [theme.breakpoints.up(BreakPoints.Large)]: {
      height: '30vh',
    },
    [theme.breakpoints.up(BreakPoints.XLarge)]: {
      height: '35vh',
    },
  },

  pageHeaderNoBackground: {
    height: '6em',
  },
}));

const PageHeaderTopology = createTopologyProvider(pageHeaderTopology);

/**
 * Page filler with title and nav items at the top of a page
 * Stretches to big size when a background is present
 */
const PageHeader: TopologyFC<PageHeaderProps> = ({ children, ...props }) => {
  const classes = useStyles(props);

  const className = clsx({
    [classes.pageHeader]: true,
    [classes.pageHeaderBackground]: props.background,
    [classes.pageHeaderNoBackground]: !props.background,
  });

  return (
    <React.Fragment>
      <Box
        className={className}
        sx={{
          backgroundImage: props.background ? `url(${props.background})` : undefined,
          backgroundPositionY: `${props.positionY ?? defaultPercentage}%`,
        }}
      />
      <Container
        size={Size.Large}
      >
        <PageHeaderTopology>
          {children ?? <Type />}
        </PageHeaderTopology>
      </Container>
    </React.Fragment>

  );
};

export default PageHeader;
