import LinkedRenderStore, { RENDER_CLASS_NAME } from 'link-lib';

import ll from '../../../ontology/ll';
import {
  LoadingCellRow,
  LoadingDetail,
} from '../../components/Loading';
import {
  tableCellTopology,
  tableFooterCellTopology,
  tableHeaderRowTopology,
  tableRowTopology,
  tableTopology,
} from '../../topologies/Table';

export default [
  ...LinkedRenderStore.registerRenderer(
    LoadingDetail,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [tableCellTopology, tableFooterCellTopology],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingCellRow,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [tableTopology, tableRowTopology, tableHeaderRowTopology],
  ),
];
