import { t } from 'i18next';
import LinkedRenderStore, { RENDER_CLASS_NAME } from 'link-lib';
import React from 'react';

import Button, { ButtonVariant } from '../../components/Button';
import { useErrorStatus, useTitleForStatus } from '../../components/Error/errorMessages';
import { ErrorComponentProps } from '../../components/Error/helpers';
import {
  attributeListTopology,
  cardFixedTopology,
  cardFloatTopology,
  cardMainTopology,
  cardMicroRowTopology,
  cardRowTopology,
  cardTopology,
  containerFloatTopology,
  detailsBarTopology,
  hoverBoxTopology,
  parentTopology,
} from '../../topologies';
import useErrorReload from '../../hooks/useErrorReload';
import { ERROR_CLASSES } from '../../lib/metaData';

export const ErrorButtonInline = ({
  linkRequestStatus,
  reloadLinkedObject,
  subject,
}: ErrorComponentProps): JSX.Element => {
  const {
    loading,
    reload,
  } = useErrorReload(subject, reloadLinkedObject);
  const statusCode = useErrorStatus(linkRequestStatus);
  const titleForStatus = useTitleForStatus(statusCode);

  return (
    <Button
      small
      icon="exclamation-triangle"
      loading={loading}
      title={titleForStatus!}
      variant={ButtonVariant.Subtle}
      onClick={reload}
    >
      {t('errors.retry')}
    </Button>
  );
};

export default LinkedRenderStore.registerRenderer(
  ErrorButtonInline,
  ERROR_CLASSES,
  RENDER_CLASS_NAME,
  [
    attributeListTopology,
    cardFixedTopology,
    cardFloatTopology,
    cardTopology,
    cardMainTopology,
    cardMicroRowTopology,
    cardRowTopology,
    containerFloatTopology,
    detailsBarTopology,
    hoverBoxTopology,
    parentTopology,
  ],
);
