import { makeStyles } from '@mui/styles';
import * as as from '@ontologies/as';
import rdf from '@ontologies/core';
import clsx from 'clsx';
import { t } from 'i18next';
import {
  useIds,
  useNumbers,
} from 'link-redux';
import React, { FC } from 'react';

import Button, { ButtonVariant } from '../../Button';
import { useIRITemplate } from '../../../hooks/useIRITemplate';
import { useCollectionOptions } from '../CollectionContext';

export const defaultPaginationCID = 'CID-DefaultPagination';

export interface PaginationProps {
  alignText?: 'left' | 'right';
}

interface PaginationButtonProps {
  active?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  icon?: string;
  key?: string;
  label?: string;
  url?: string;
}

const pageProp = 'page';

const usePageButtons = (): JSX.Element[] | null => {
  const {
    currentCollection,
    currentCollectionPages,
    setCollectionResource,
  } = useCollectionOptions();

  const [first] = useIds(currentCollection, as.first);
  const [last] = useIds(currentCollection, as.last);
  const currentOrFirst = currentCollectionPages?.[0] ?? first;
  const [pageItemCount] = useNumbers(currentOrFirst, as.totalItems);

  const iriTemplate = useIRITemplate(currentCollection);

  return React.useMemo(() => {
    if (!currentCollection || !currentOrFirst || !iriTemplate) {
      return null;
    }

    const currentPageUrl = new URL(currentOrFirst.value);
    const currentPageParam = currentPageUrl.searchParams.get(pageProp);
    const currentPageNr = currentPageParam ? Number.parseInt(currentPageParam, 10) : 1;

    const firstPageParam = first ? new URL(first.value).searchParams.get(pageProp) : undefined;
    const firstPage = firstPageParam ? Number.parseInt(firstPageParam, 10) : 1;
    const lastPageParam = last ? new URL(last.value).searchParams.get(pageProp) : undefined;
    const lastPage = lastPageParam ? Number.parseInt(lastPageParam, 10) : undefined;

    if (firstPage && lastPage) {
      if (firstPage > lastPage) {
        throw new Error('First page is higher than last page or last is undefined');
      }

      // Don't show the pagination buttons if there's just one page
      if (firstPage === lastPage) {
        return null;
      }
    } else if (pageItemCount === 0) {
      return null;
    }

    const nextPage = () => iriTemplate.set({ page: (currentPageNr + 1).toString() })!.value;

    const previousPage = () => iriTemplate.set({ page: (currentPageNr - 1).toString() })!.value;

    const paginationButton = ({
      active,
      ariaLabel,
      disabled = false,
      icon,
      key,
      label,
      url,
    }: PaginationButtonProps = {}) => (
      <Button
        small
        active={active}
        ariaLabel={ariaLabel}
        disabled={disabled}
        icon={icon}
        key={`${key}-${currentCollection.value}-page-switcher-${key}`}
        variant={ButtonVariant.Pagination}
        onClick={(e) => {
          e.preventDefault();
          setCollectionResource(rdf.namedNode(url));
        }}
      >
        {label}
      </Button>
    );

    const singlePageButton = (page: number) => {
      const url = iriTemplate.set({ page: page.toString() })!.value;
      const isCurrent = currentOrFirst.value === url;

      return paginationButton({
        active: isCurrent,
        key: page.toString(),
        label: page.toString(),
        url,
      });
    };

    const pages = [];
    pages.push(paginationButton({
      ariaLabel: t('collection.nextLabel'),
      disabled: currentPageNr === firstPage,
      icon: 'arrow-left',
      key: 'prev',
      url: previousPage(),
    }));

    if (firstPage && lastPage) {
      pages.push(singlePageButton(firstPage));

      const span = 3;
      const spanFrom = Math.max(firstPage + 1, currentPageNr - span);
      const spanTo = Math.min(currentPageNr + span, lastPage - 1);

      if (spanFrom > firstPage + 1) {
        pages.push(
          <span key="dots-before">
            ...
          </span>,
        );
      }

      for (let i = spanFrom; i <= spanTo; i++) {
        pages.push(singlePageButton(i));
      }

      if (spanTo < lastPage - 1) {
        pages.push(
          <span key="dots-after">
            ...
          </span>,
        );
      }

      pages.push(singlePageButton(lastPage));
    }

    pages.push(paginationButton({
      ariaLabel: t('collection.nextLabel'),
      disabled: currentPageNr === lastPage || pageItemCount === 0,
      icon: 'arrow-right',
      key: 'next',
      url: nextPage(),
    }));

    return pages;
  }, [
    currentOrFirst,
    first,
    iriTemplate,
    last,
    setCollectionResource,
    currentCollection,
  ]);
};

const useStyles = makeStyles(() => ({
  paginationWrapper: {
    marginBottom: '1em',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
}));

const DefaultPagination: FC<PaginationProps> = ({ alignText }) => {
  const pageButtons = usePageButtons();
  const classes = useStyles();

  if (!pageButtons || pageButtons.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        defaultPaginationCID,
        classes.paginationWrapper,
        alignText === 'right' ? classes.textRight : classes.textLeft,
      )}
      data-testid="paginationWrapper"
    >
      {pageButtons}
    </div>
  );
};

export default DefaultPagination;
