import * as rdfx from '@ontologies/rdf';
import {
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, { FC } from 'react';

import fieldTypes from './fieldTypes';

const FormFieldWrapper: FC<SubjectProp> = ({ subject }) => {
  const [type] = useGlobalIds(subject, rdfx.type);
  const Renderer = fieldTypes[type.value];

  if (!Renderer) {
    throw new Error(`No renderer found for ${type.value}`);
  }

  return <Renderer subject={subject} />;
};

export default FormFieldWrapper;
