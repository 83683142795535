// eslint-disable-next-line import/no-extraneous-dependencies
import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from 'history';
import {
  DataRecord,
  LinkedRenderStoreOptions,
  MiddlewareFn,
  createStore,
} from 'link-lib';
import { LinkReduxLRSType } from 'link-redux';
import type React from 'react';

import { FRONTEND_ACCEPT } from '../config';
import { authMiddleware } from '../../middleware/authMiddleware';
import { paginationMiddleware } from '../../middleware/paginationMiddleware';
import analyticsMiddleware from '../../middleware/analyticsMiddleware';
import commonMiddleware from '../../middleware/common';
import execFilter from '../../middleware/execFilter';
import logging from '../../middleware/logging';
import { frontendIRIStr, website } from '../iris';
import { handle } from '../logging';

import { initializeCable, subscribeDeltaChannel } from './websockets';
import { getMetaContent } from './dom';
import { appendPath } from './id';
import ontolaDeltaProcessor from './ontolaDeltaProcessor';
import transformers from './transformers';
import { execActionByIRI } from './execActionByIRI';
import empndjson from './transformers/empndjson';
import hexjson from './transformers/hexjson';

export interface LRSBundle {
  history: History;
  lrs: LinkReduxLRSType;
}

export interface GenerateLRSOpts extends LinkedRenderStoreOptions<React.ComponentType> {
  middleware?: boolean;
}

const defaultOpts = {
  middleware: __CLIENT__,
};

const history = __CLIENT__ && !__TEST__
  ? createBrowserHistory()
  : createMemoryHistory();

export default async function generateLRS(
  initialData: Record<string, DataRecord> = {},
  mapping: Record<string, string>,
  options: GenerateLRSOpts = defaultOpts,
): Promise<LRSBundle> {
  const middleware: Array<MiddlewareFn<any>> = options.middleware ? [
    logging,
    commonMiddleware(history),
    analyticsMiddleware(),
    authMiddleware(),
    paginationMiddleware(),
    execFilter(),
  ] : [];
  const storeOptions: LinkedRenderStoreOptions<React.ComponentType> = {
    data: initialData,
    report: handle,
    ...options,
  };

  if (!__CLIENT__) {
    storeOptions.apiOpts = { bulkEndpoint: 'http://localhost/link-lib/bulk' };
  } else {
    storeOptions.apiOpts = { bulkEndpoint: appendPath(frontendIRIStr, '/link-lib/bulk') };
  }

  const lrs = createStore<React.ComponentType>(storeOptions, middleware);
  lrs.bulkFetch = true;

  lrs.deltaProcessors.unshift(ontolaDeltaProcessor(lrs));

  lrs.api.registerTransformer(empndjson.transformer(lrs, frontendIRIStr, mapping), empndjson.mediaTypes, empndjson.acceptValue);
  lrs.api.registerTransformer(hexjson.transformer(lrs), hexjson.mediaTypes, hexjson.acceptValue);
  transformers(lrs).forEach((t) =>
    lrs.api.registerTransformer(t.transformer, t.mediaTypes, t.acceptValue),
  );

  if (!website) {
    handle(new Error('No website in head'));
  }

  (lrs as any).execActionByIRI = execActionByIRI(lrs);

  // @ts-ignore TS2341
  lrs.api.accept.default = FRONTEND_ACCEPT;

  // Globally disable anti-jump rendering
  (lrs as any).broadcast_old = (lrs as any).broadcast;
  (lrs as any).broadcast = (_: boolean, __: number) => (lrs as any).broadcast_old(false, 0);

  const websocketPath = !__TEST__ && getMetaContent('websocket-path');

  if (__CLIENT__ && websocketPath && !localStorage.getItem('_libro_disable_ws') && options.middleware) {
    initializeCable(lrs, websocketPath).then(() => {
      subscribeDeltaChannel(lrs, 'UserChannel');
      subscribeDeltaChannel(lrs, 'RootChannel');
    });
  }

  return {
    history,
    lrs,
  };
}
