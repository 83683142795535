import * as schema from '@ontologies/schema';
import { SomeNode } from 'link-lib';
import {
  FC,
  useActionById,
  useGlobalIds,
  useIds,
  useStrings,
} from 'link-redux';
import React, { MouseEventHandler } from 'react';

import ontola from '../../../ontology/ontola';
import { normalizeFontAwesomeIRI } from '../../lib/iris';

import DropdownMenuItem from './DropdownMenuItem';

interface LinkedDropdownMenuItemProps {
  hideIcon?: boolean;
  onClose?: () => void;
  subject: SomeNode;
}

const LinkedDropdownMenuItem = React.forwardRef<FC, LinkedDropdownMenuItemProps>(
  (props, ref) => {
    const [action] = useGlobalIds(props.subject, ontola.action);
    const [href] = useGlobalIds(props.subject, ontola.href);
    const [image] = useIds(props.subject, schema.image);
    const [name] = useStrings(props.subject, schema.name);

    const onClick = useActionById(action) as unknown as MouseEventHandler;

    if (!href) {
      return null;
    }

    return (
      <DropdownMenuItem
        innerRef={ref}
        {...props}
        href={href.value}
        icon={image ? normalizeFontAwesomeIRI(image) : undefined}
        label={name}
        onClick={onClick}
      />
    );
  },
);

export default LinkedDropdownMenuItem;
