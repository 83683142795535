import { isNamedNode } from '@ontologies/core';
import * as rdfs from '@ontologies/rdfs';
import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../ontology/ontola';
import { Section } from '../../components/App/typescript';
import CardContent from '../../components/Card/CardContent';
import LinkedDropdownMenu from '../../components/DropdownMenu/LinkedDropdownMenu';
import HeaderWithFloat from '../../components/HeaderWithFloat';
import HeadingContext from '../../components/Heading/HeadingContext';
import Markdown from '../../components/Markdown';
import ActionsBar from '../../topologies/ActionsBar';
import Card from '../../topologies/Card';

import EventDetails from './EventDetails';
import EventSignUpButton from './EventSignUpButton';

const EventCard: FC<SubjectProp> = ({ subject }) => {
  const [title] = useStrings(subject, [schema.name, rdfs.label]);
  const [text] = useStrings(subject, schema.text);
  const [signUpAction] = useGlobalIds(subject, ontola.signUpAction);

  return (
    <Card>
      <HeadingContext>
        <CardContent>
          <HeaderWithFloat
            float={(
              <LinkedDropdownMenu
                label={ontola.actionsMenu}
                subject={subject}
              />
            )}
            link={isNamedNode(subject) ? subject : undefined}
            title={title}
          />
          <EventDetails event={subject} />
          <Markdown text={text} />
        </CardContent>
        {GLOBALS.section === Section.Main && (
          <ActionsBar>
            <EventSignUpButton subject={signUpAction} />
          </ActionsBar>
        )}
      </HeadingContext>
    </Card>
  );
};

export default EventCard;
