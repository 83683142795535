import React, { FC } from 'react';

import BreadcrumbsBar from '../../../components/Breadcrumbs/BreadcrumbsBar';
import CollectionProvider, { CollectionProviderProps } from '../../../components/Collection/CollectionProvider';
import Container from '../../../topologies/Container';

const CollectionShow: FC<CollectionProviderProps> = (props) => (
  <React.Fragment>
    <BreadcrumbsBar subject={props.subject} />
    <Container>
      <CollectionProvider
        redirectPagination
        renderWhenEmpty
        {...props}
      />
    </Container>
  </React.Fragment>
);

export default CollectionShow;
