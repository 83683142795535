import rdf, { QuadPosition } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import * as sh from '@ontologies/shacl';
import { SomeNode } from 'link-lib';
import {
  dig,
  useDataFetching,
  useGlobalIds,
  useIds,
  useLRS,
} from 'link-redux';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SubmitDataProcessor } from '../../../../../lib/errorHandling';
import ll from '../../../../../../ontology/ll';
import ontola from '../../../../../../ontology/ontola';
import { conditionalFormFieldsPath, formFieldsPath } from '../../../../Form/lib/diggers';
import { JSONLDObject } from '../../../../Form/lib/helpers';
import { formContext } from '../../../../Form/FormContext';
import { ItemFactory } from '../../../../FormField/FormFieldTypes';

export const useItemFactory = (subject: SomeNode): ItemFactory => {
  const lrs = useLRS<unknown, SubmitDataProcessor>();
  const { object } = React.useContext(formContext);

  const [path] = useGlobalIds(subject, sh.path);
  const [clonedId] = useIds(object, ll.clonedFrom);
  const [association] = useIds(clonedId, path);
  useDataFetching(association);

  const formPaths = useIds(
    association,
    dig(ontola.createAction, schema.target, ll.actionBody, ...formFieldsPath, sh.path),
  );
  const conditionalFormPaths = useIds(
    association,
    dig(ontola.createAction, schema.target, ll.actionBody, ...conditionalFormFieldsPath, sh.path),
  );
  const [blankObject] = useIds(association, dig(ontola.createAction, schema.object));

  const newItem = React.useCallback(() => {
    const values: JSONLDObject = { '@id': rdf.blankNode(`_:${uuidv4()}`) };

    if (blankObject) {
      lrs.store.quadsFor(blankObject).forEach((quad) => {
        if (formPaths.indexOf(quad[QuadPosition.predicate]) !== -1 || conditionalFormPaths.indexOf(quad[QuadPosition.predicate]) !== -1) {
          values[btoa(quad[QuadPosition.predicate].value)] = [quad[QuadPosition.object]];
        }
      });
    }

    return values;
  }, [blankObject, formPaths, conditionalFormPaths]);

  return newItem;
};
