import {
  Container,
  ContainerProps,
  PaperProps,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import Button, { ButtonVariant } from '../Button';

import { useDialogContainerStyles } from './dialogStyles';

type PaperAndContainerProps = Partial<ContainerProps> & Partial<PaperProps>;

interface DialogContainerProps extends PaperAndContainerProps {
  close: () => void;
}

export const DialogContainer: React.FC<DialogContainerProps> = ({
  children,
  close,
  ...props
}) => {
  const classes = useDialogContainerStyles({ maxWidth: props.maxWidth });

  return (
    <div className={classes.dialogWrapper}>
      <div className={classes.closeButtonArea}>
        <Container
          className={classes.closeButtonContainer}
          maxWidth={props.maxWidth}
        >
          <Button
            className={classes.closeButton}
            edge="start"
            icon="long-arrow-left"
            variant={ButtonVariant.Transparent}
            onClick={close}
          >
            {t('dialog.back')}
          </Button>
        </Container>
      </div>
      <div className={classes.childWrapper}>
        {children}
      </div>
    </div>
  );
};
