import { NamedNode } from '@ontologies/core';
import { SomeNode } from 'link-lib';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';

import { GeometryType } from './geometry';

export interface Point {
  lat: number;
  lon: number;
}

export interface Geometry {
  id?: string;
  image?: NamedNode;
  type: GeometryType;
  points: Point[];
  zoomLevel?: number;
}

export interface Layer {
  clustered?: boolean;
  features: Feature[];
}

export interface ViewProps {
  center: Coordinate;
  zoom: number;
}

export enum MapVariant {
  Default,
  Fullscreen,
  MapQuestion,
}

export interface LocationSearchEvent extends Event {
  address: { details: Record<string, string>, formatted: string };
  bbox: [minX: number, minY: number, maxX: number, maxY: number];
  coordinate: Coordinate;
  type: 'addresschosen';
}

export type ClusterSelectCallback = (features: Feature[], center: Coordinate) => void;
export type FeatureSelectCallback = (feature?: Feature, center?: Coordinate) => void;
export type SearchSelectCallback = (lonLat: Coordinate) => void;
export type MapInteractionCallback = (newGeometry: Geometry, newZoom: number) => void;
export type MapMoveCallback = (newCenter: Coordinate) => void;
export type MapViewChangeCallback = (center: Coordinate, zoom: number) => void;
export type MapZoomCallback = (newZoom: number) => void;
export type NavigateCallback = (resource: SomeNode) => void;
