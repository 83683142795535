import {
  Resource,
  useLRS,
  useTopology,
} from 'link-redux';
import React, { FC } from 'react';
import { useLocation } from 'react-router';

import ll from '../../../ontology/ll';
import { currentLocationControl } from '../../lib/paths';

const RouteNotFound: FC = () => {
  const lrs = useLRS();
  const location = useLocation();
  const subject = currentLocationControl(location, false);
  const topology = useTopology();

  const Comp = lrs.getComponentForType(ll.ErrorResource, topology!);

  if (!Comp) {
    return (
      <div>
        Something went wrong
      </div>
    );
  }

  return (
    <Resource subject={subject}>
      <Comp subject={subject} />
    </Resource>
  );
};

export default RouteNotFound;
