import rdf, { isNode } from '@ontologies/core';
import * as rdfs from '@ontologies/rdfs';
import { t } from 'i18next';
import { useStrings } from 'link-redux';
import React, { FC, MouseEventHandler } from 'react';
import FontAwesome from 'react-fontawesome';

import retrievePath from '../../lib/iris';
import Menu from '../../topologies/Menu';
import { RenderProp } from '../DropdownMenu/DropdownMenu';
import TriggerButton, { Trigger } from '../DropdownMenu/TriggerButton';
import MenuItem from '../MenuItem';

import { useCollectionOptions } from './CollectionContext';
import {
  SortProps,
  useSorting,
} from './hooks/useSorting';

interface SortOptionProps extends SortProps {
  handleClose: () => void;
}

const trigger: Trigger = (props) => (
  <TriggerButton {...props}>
    <FontAwesome name="sort" />
  </TriggerButton>
);

const SortOption = ({
  handleClose,
  item,
  url,
  direction,
  selected,
}: SortOptionProps) => {
  const { setCollectionResource } = useCollectionOptions();
  const [name] = useStrings(isNode(item) ? item : undefined, rdfs.label);
  const action = React.useCallback<MouseEventHandler>((e) => {
    e.preventDefault();
    handleClose();
    setCollectionResource(rdf.namedNode(url));
  }, [handleClose, setCollectionResource, url]);

  return (
    <MenuItem
      href={retrievePath(url!)!}
      onClick={action}
    >
      <FontAwesome name={selected ? 'circle' : 'circle-o'} />
      {' '}
      <FontAwesome name={`sort-amount-${direction}`} />
      {' '}
      {name}
    </MenuItem>
  );
};

const CollectionSortButton: FC = () => {
  const { currentCollection } = useCollectionOptions();
  const sortOptions = useSorting(currentCollection);

  const menuItems = React.useCallback<RenderProp>(({ handleClose }) => (
    <React.Fragment>
      {sortOptions.filter((option) => option.direction && option.url).map((option) => (
        <SortOption
          {...option}
          handleClose={handleClose}
          key={option.url}
        />
      ))}
    </React.Fragment>
  ), [sortOptions]);

  return (
    <Menu
      title={t('collection.sort')}
      trigger={trigger}
    >
      {menuItems}
    </Menu>
  );
};

export default CollectionSortButton;
