import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../ontology/ontola';
import { isInvalidActionStatus } from '../../components/Action/hooks/useValidActions';
import { ButtonVariant } from '../../components/Button';
import EntryPointButton from '../entrypoints/EntryPointButton';

const useStyles = makeStyles({
  notice: {
    background: '#f8f8f8',
    border: '1px solid #e6e6e6',
    display: 'inline-block',
    flexGrow: 1,
    fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
    fontSize: '1em',
    margin: 0,
    padding: '0.5rem 1.3rem',
    textTransform: 'unset',
    transition: 'box-shadow .1s,background-color .1s',
    whiteSpace: 'nowrap',
  },
});

const EventSignUpButton: FC<SubjectProp> = ({ subject }) => {
  const theme = useTheme();
  const [actionStatus] = useGlobalIds(subject, schema.actionStatus);
  const [entrypoint] = useGlobalIds(subject, schema.target);
  const classes = useStyles();

  if (actionStatus === schema.CompletedActionStatus) {
    return (
      <div className={classes.notice}>
        {t('events.status.signedUp')}
      </div>
    );
  }

  if (actionStatus === ontola.ExpiredActionStatus) {
    return (
      <div className={classes.notice}>
        {t('events.status.full')}
      </div>
    );
  }

  if (isInvalidActionStatus(actionStatus) || !entrypoint) {
    return null;
  }

  return (
    <EntryPointButton
      color={theme.palette.primary.main}
      subject={entrypoint}
      variant={ButtonVariant.Toggle}
    />
  );
};

export default EventSignUpButton;
