import {
  ListItemIcon,
  ListItemText,
  MenuItem as MaterialMenuItem,
} from '@mui/material';
import React, {
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLinkProps } from 'react-router-dom';

import Link, { LinkProps } from '../Link';

interface MenuItemProps {
  href: string;
  icon?: string;
  innerRef?: any;
  onClick?: MouseEventHandler;
}

const MenuItem: React.FC<MenuItemProps> = ({
  onClick,
  children,
  icon,
  innerRef,
  href,
}) => {
  const component = href
    ? React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'target'>>((props, nestedRef) => (
      <Link
        ref={nestedRef}
        {...props}
        to={href}
      />
    )) :
    React.forwardRef<any, HTMLAttributes<any> & Omit<NavLinkProps, 'to'>>((props, nestedRef) => {
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars,react/prop-types
      const { ...otherProps } = props;

      return (
        <li
          ref={nestedRef}
          {...otherProps}
        />
      );
    });

  return (
    <MaterialMenuItem
      component={component}
      ref={innerRef}
      onClick={onClick}
    >
      {icon && (
        <ListItemIcon>
          <FontAwesome name={icon} />
        </ListItemIcon>
      )}
      <ListItemText primaryTypographyProps={{ color: 'textPrimary' }}>
        {children}
      </ListItemText>
    </MaterialMenuItem>
  );
};

export default React.forwardRef<any, PropsWithChildren<MenuItemProps>>((props, ref) => (
  <MenuItem
    innerRef={ref}
    {...props}
  />
),
);
