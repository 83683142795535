import Geocoder from 'ol-geocoder';
import { t } from 'i18next';
import { Map as OLMap, View as OLView } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { ListenerFunction } from 'ol/events';
import BaseEvent from 'ol/events/Event';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Cluster from 'ol/source/Cluster';
import TileSource from 'ol/source/Tile';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toLonLat } from 'ol/proj';

import { handle } from '../../../lib/logging';
import {
  ClusterSelectCallback,
  FeatureSelectCallback,
  Layer,
  LocationSearchEvent,
  MapInteractionCallback,
  MapMoveCallback,
  MapViewChangeCallback,
  MapZoomCallback,
  SearchSelectCallback,
  ViewProps,
} from '../lib/types';
import CurrentLocationControl from '../components/CurrentLocationControl';

import useMapAccessToken, { MapAccessToken, RequestMapAccessToken } from './useMapAccessToken';
import { getStyle, useInteraction } from './useInteraction';
import useViewHandlers from './useViewHandlers';

export const FOCUS_ZOOM = 12;
const CLUSTER_DISTANCE = 30;
const TILE_SIZE = 512;

interface CreateMapProps {
  accessToken?: string;
  currentLocationTooltip: string;
  layerSources?: Array<VectorSource | Cluster>;
  mapRef: MutableRefObject<HTMLDivElement | null>;
  onSearchSelect?: SearchSelectCallback;
  tileSource?: TileSource;
  view: ViewProps;
}

export interface UseMapProps {
  layers: Layer[];
  mapboxTileURL: string;
  onClusterSelect?: ClusterSelectCallback;
  onInteraction?: MapInteractionCallback;
  onMove?: MapMoveCallback;
  onSearchSelect?: SearchSelectCallback;
  onSelect?: FeatureSelectCallback;
  onViewChange?: MapViewChangeCallback;
  onZoom?: MapZoomCallback;
  view: ViewProps;
}

const createGeocoder = (onSearchSelect: SearchSelectCallback) => {
  const geocoder = new Geocoder('nominatim', {
    defaultFlyResolution: 50,
    keepOpen: true,
    lang: 'nl-NL',
    limit: 5,
    placeholder: t('map.search.placeholder'),
    preventMarker: true,
    provider: 'osm',
    targetType: 'text-input',
  });

  geocoder.on('addresschosen', (e: LocationSearchEvent) => onSearchSelect(toLonLat(e.coordinate)));

  return geocoder;
};

const updateFeatures = (layerSources: Array<VectorSource | Cluster> | undefined, layers: Layer[]) => {
  if (layerSources) {
    layerSources.forEach((source, index) => {
      const layer = layers[index];

      if (layer.clustered) {
        (source as Cluster).getSource()?.clear(true);
        (source as Cluster).getSource()?.addFeatures(layer.features.slice() || []);
      } else {
        source.clear(true);
        source.addFeatures(layer.features.slice() || []);
      }
    });
  }
};

const createMap = ({
  accessToken,
  currentLocationTooltip,
  layerSources,
  mapRef,
  onSearchSelect,
  tileSource,
  view,
}: CreateMapProps): OLMap | undefined => {
  const { current } = mapRef;

  if (!current || !accessToken || !layerSources || !tileSource) {
    return undefined;
  }

  const layers = [
    new TileLayer({
      source: tileSource,
    }),
    ...layerSources.map((source) => (
      new VectorLayer({
        source,
        style: getStyle('style'),
      })
    )),
  ];

  const customControls = [
    new CurrentLocationControl(currentLocationTooltip),
  ];

  if (onSearchSelect) {
    customControls.push(createGeocoder(onSearchSelect));
  }

  const controls = defaultControls({
    rotate: false,
  }).extend(customControls);

  return new OLMap({
    controls,
    layers,
    target: current,
    view: new OLView(view),
  });
};

const toVectorSource = (layer: Layer): VectorSource => {
  const source = layer.clustered ?
    new Cluster({
      distance: CLUSTER_DISTANCE,
      source: new VectorSource(),
    }) :
    new VectorSource();

  return source;
};

const toTileSource = (accessToken: string, URL: string): TileSource =>
  new XYZ({
    tileSize: [TILE_SIZE, TILE_SIZE],
    url: `${URL}/tiles/{z}/{x}/{y}?access_token=${accessToken}`,
  });

const useMap = (props: UseMapProps): {
  deselect: (() => void) | undefined;
  error: BaseEvent | Event | undefined;
  map: OLMap | undefined;
  mapRef: React.RefObject<HTMLDivElement>;
  mapToken: MapAccessToken;
  requestMapToken: RequestMapAccessToken;
} => {
  const {
    layers,
    mapboxTileURL,
    onClusterSelect,
    onInteraction,
    onMove,
    onSelect,
    onViewChange,
    onZoom,
    view,
  } = props;
  const [mapToken, requestMapToken] = useMapAccessToken();
  const mapRef = useRef<HTMLDivElement>(null);

  const [layerSources, setLayerSources] = useState<Array<Cluster | VectorSource> | undefined>(undefined);
  const [tileSource, setTileSource] = useState<TileSource | undefined>(undefined);
  const [error, setError] = useState<BaseEvent | Event | undefined>(undefined);
  const [map, setMap] = useState<OLMap | undefined>(undefined);
  const zoom = useViewHandlers(
    view,
    map,
    onMove,
    onViewChange,
    onZoom,
  );
  const [deselect] = useInteraction(zoom, onInteraction, map, onClusterSelect, onSelect);

  useEffect(() => {
    if (mapToken.token) {
      setError(undefined);
    }
  }, [mapToken.token]);

  const handleError = useCallback<ListenerFunction>((e) => {
    handle(JSON.stringify(e));
    setError(e);

    return true;
  }, []);

  const handleLoad = useCallback<ListenerFunction>(() => {
    setError(undefined);

    return true;
  }, []);

  useEffect(() => {
    if (mapToken.token) {
      const sources = layers.map(toVectorSource);
      setLayerSources(sources);

      const source = toTileSource(mapToken.token, mapboxTileURL);
      source.addEventListener('tileloadend', handleLoad);
      source.addEventListener('tileloaderror', handleError);
      source.addEventListener('imageloaderror', handleError);
      setTileSource(source);

      return () => {
        source.removeEventListener('tileloadend', handleLoad);
        source.removeEventListener('tileloaderror', handleError);
        source.removeEventListener('imageloaderror', handleError);
      };
    }

    return () => undefined;
  }, [mapToken.token]);

  useEffect(() => {
    const newMap = createMap({
      ...props,
      accessToken: mapToken.token,
      currentLocationTooltip: t('map.currentLocation'),
      layerSources,
      mapRef,
      tileSource,
    });
    setMap(newMap);

    return () => {
      if (newMap) {
        newMap.setTarget(undefined);
      }
    };
  }, [mapRef.current, mapToken.token, layerSources, tileSource]);

  useEffect(() => {
    updateFeatures(layerSources, layers);
  }, [!!layerSources, ...layers]);

  return {
    deselect,
    error,
    map,
    mapRef,
    mapToken,
    requestMapToken,
  };
};

export default useMap;
