import { isNamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import { useLRS } from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../ontology/ontola';
import teamGL from '../../../ontology/teamGL';
import TableFrame, { Column } from '../../components/Collection/Frame/TableFrame';
import RelativeDate from '../../components/RelativeDate';
import { formatNumber } from '../../lib/formatters';
import { tryParseInt } from '../../lib/numbers';
import { Navigate } from '../../middleware/actions';
import ActionTableButton from '../actions/ActionTableButton';

const columns: Column[] = [
  {
    label: t('table.streets.columns.name'),
    property: schema.name,
  },
  {
    label: t('table.streets.columns.lastVisitedAt'),
    property: teamGL.lastVisitedAt,
    render: ({ term }) => <RelativeDate date={term} />,
  },
  {
    label: t('table.streets.columns.pendingAddressesCount'),
    property: teamGL.pendingAddressesCount,
    render: ({ term }) => formatNumber(tryParseInt(term.value)),
  }];
const adminColumns: Column[] = [
  ...columns,
  {
    label: t('table.streets.columns.destroy'),
    property: ontola.destroyAction,
    render: ({ term }) => isNamedNode(term) ? <ActionTableButton subject={term} /> : undefined,
  },
];

const EventStreetTable: FC<{admin?: boolean}> = ({ admin }) => {
  const lrs = useLRS();

  const handleRowClick = (resource: SomeNode) => {
    const street = lrs.getResourceProperty(resource, teamGL.street);

    if (isNamedNode(street)){
      lrs.actions.get(Navigate)(street);
    }
  };

  return (
    <TableFrame
      columns={admin ? adminColumns : columns}
      onRowClick={admin ? undefined : handleRowClick}
    />
  );
};

export default EventStreetTable;
