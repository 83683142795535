import { makeStyles } from '@mui/styles';
import { SomeTerm } from '@ontologies/core';
import clsx from 'clsx';
import React from 'react';

import { LibroTheme } from '../../theme/types';

import { formFieldContext } from './FormFieldContext';
import { FormFieldError } from './FormFieldTypes';

interface FormFieldTrailerProps {
  errors: FormFieldError[];
  inputValue: SomeTerm;
}

const useStyles = makeStyles<LibroTheme>((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  fieldInputTrailingIcon: {
    alignSelf: 'center',
    fontSize: '1rem',
    paddingRight: '.1em',
  },
}));

const FormFieldTrailer: React.FC<FormFieldTrailerProps> = ({
  errors,
}) => {
  const classes = useStyles();
  const { meta } = React.useContext(formFieldContext);
  const { invalid } = meta;

  if (errors && errors.length > 0 && invalid) {
    return (
      <span
        className={clsx(classes.fieldInputTrailingIcon, 'fa', 'fa-exclamation-circle', classes.error)}
        title={errors[0].error}
      />
    );
  }

  return null;
};

export default FormFieldTrailer;
