import React, {
  FC,
  ReactNode,
} from 'react';

import CardContent from '../Card/CardContent';
import Heading, { HeadingSize } from '../Heading';
import Markdown from '../Markdown';
import CardMain from '../../topologies/Card/CardMain';
import Container from '../../topologies/Container';

interface DocumentProps {
  title: string;
  children?: ReactNode;
  content?: string;
}

const Document: FC<DocumentProps> = ({ children, content, title }) => (
  <Container>
    <CardMain>
      <CardContent>
        <Heading size={HeadingSize.XL}>
          {title}
        </Heading>
        {content && <Markdown text={content} />}
        {children}
      </CardContent>
    </CardMain>
  </Container>
);

export default Document;
