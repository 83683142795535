import * as as from '@ontologies/as';
import { useNumbers } from 'link-redux';
import React, {
  FC,
  ReactNode,
} from 'react';

import CardAppendixContent from '../../Card/CardAppendixContent';
import Card from '../../../topologies/Card';
import CardAppendix from '../../../topologies/Card/CardAppendix';
import CollectionHeader from '../CollectionHeader';
import CollectionPagination from '../Pagination/CollectionPagination';

const CardFrame: FC<{children: ReactNode}> = ({ children }) => {
  const [totalItems] = useNumbers(as.totalItems);

  return (
    <React.Fragment>
      <CollectionHeader />
      <Card>
        {children}
        {totalItems !== 0 && (
          <CardAppendix>
            <CardAppendixContent>
              <CollectionPagination />
            </CardAppendixContent>
          </CardAppendix>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CardFrame;
