import React from 'react';

interface FormGroupContext {
  addFieldName?: (fieldName: string) => void;
  buttonContainerRef?: React.MutableRefObject<HTMLDivElement>
  fieldNames: string[];
  groupIndex: number;
  hasContent: boolean;
  setHasContent: (hasContent: boolean) => void;
}

const FormGroupContext = React.createContext<FormGroupContext>({} as FormGroupContext);

export const useFormGroup = (): FormGroupContext => React.useContext(FormGroupContext);
