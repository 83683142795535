import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import { OnDoneHandler } from '../components/Action/lib/helpers';
import SessionForm from '../components/Auth/SessionForm';

const SessionDialog: FC<SubjectProp & {onDone: OnDoneHandler}> = ({ onDone, subject }) => (
  <SessionForm
    subject={subject}
    onCancel={() => onDone(null)}
  />
);

export default SessionDialog;
