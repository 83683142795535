import { makeStyles } from '@mui/styles';
import React, { EventHandler } from 'react';

import PlainEditor from './PlainEditor';

export interface PlainEditorProps {
  autoFocus?: boolean;
  id: string;
  maxLength?: number;
  minLength?: number;
  onBlur: EventHandler<any>;
  onChange?: EventHandler<any>;
  onFocus: EventHandler<any>;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  value: string;
}

export interface TextEditorProps {
  autoFocus: boolean;
  maxLength: number;
  minLength: number;
  name: string;
  onBlur: (args: any) => any;
  onChange: (args: any) => void;
  onFocus: (args: any) => any;
  placeholder: string;
  required?: boolean;
  rows: number;
  value: string;
}

const useStyles = makeStyles({
  editor: {
    flex: 1,
    position: 'relative',
  },
});

const TextEditor = ({
  autoFocus,
  maxLength,
  minLength,
  name,
  placeholder,
  required,
  rows,
  value,
  onBlur,
  onFocus,
  onChange,
}: TextEditorProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.editor}>
      <PlainEditor
        autoFocus={autoFocus}
        id={name}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        rows={rows}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
};

export default TextEditor;
