import { darken } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LibroTheme } from '../../theme/types';
import { headingCID } from '../Heading';

export const HOVER_COEFFICIENT = 0.3;

export default makeStyles<LibroTheme>((theme) => ({
  default: {
    [`&:hover .${headingCID}`]: {
      color: darken(theme.palette.link?.header || theme.palette.common.black, HOVER_COEFFICIENT),
    },
    'display': 'inline-flex',
    'whiteSpace': 'pre-wrap',
  },
}));
