import React from 'react';

import CardRow from '../../topologies/Card/CardRow';

import CardContent from './CardContent';

const CardAppendixContent: React.FC = ({ children }) => (
  <CardRow
    backdrop
    borderTop
  >
    <CardContent>
      {children}
    </CardContent>
  </CardRow>
);

export default CardAppendixContent;
