import { LibroTheme, MaterialStyleMap } from '../../types';
import { navBarContentItemsCID } from '../../../components/Navbar/NavbarLeftItems';

export default (theme: LibroTheme): MaterialStyleMap => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        '& .MuiButton-root': {
          height: '100%',
          whiteSpace: 'nowrap',
        },
        '& .MuiToolbar-root': {
          [`& > .${navBarContentItemsCID}`]: {
            flexBasis: '100%',
          },
          '& img': {
            maxHeight: theme.appBar.height,
          },
          height: theme.appBar.height,
          minHeight: theme.appBar.height,
        },
      },
    },
  },
});
