import { t } from 'i18next';
import React from 'react';

import { LoadingRow } from '../../Loading';
import useFieldOptions from '../../Form/hooks/useFieldOptions';
import RadioGroup from '../../../topologies/RadioGroup';
import { formFieldContext } from '../../FormField/FormFieldContext';
import { InputComponentProps } from '../../FormField/FormFieldTypes';

const RadioGroupWrapper: React.FC<InputComponentProps> = ({
  inputValue,
  onChange,
}) => {
  const {
    fieldShape,
    name,
    renderProp,
    onFocus,
    onBlur,
  } = React.useContext(formFieldContext);
  const {
    shIn,
  } = fieldShape;
  const {
    loading,
    options,
  } = useFieldOptions(shIn);

  if (loading) {
    return <LoadingRow />;
  }

  if (options.length === 0) {
    return t('form.radio.noOptions');
  }

  return (
    <RadioGroup
      name={name}
      options={options}
      renderProp={renderProp}
      required={fieldShape.required}
      value={inputValue}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default RadioGroupWrapper;
