import * as rdfx from '@ontologies/rdf';
import HttpStatus from 'http-status-codes';
import { RequestStatus } from 'link-lib';
import { useIds } from 'link-redux';
import { t } from 'i18next';

import { handle } from '../../lib/logging';
import { ERROR_STATUS_CODES } from '../../lib/metaData';

const errorMessages: Record<number, Record<string, string | null>> = {
  200: {
    body: t('errors.200.body'),
    label: t('errors.200.label'),
  },
  401: {
    body: t('errors.401.body'),
    label: t('errors.401.label'),
  },
  403: {
    body: t('errors.403.body'),
    label: t('errors.403.label'),
  },
  404: {
    body: t('errors.404.body'),
    label: t('errors.404.label'),
  },
  406: {
    body: t('errors.406.body'),
    label: t('errors.406.label'),
  },
  408: {
    body: t('errors.408.body'),
    label: t('errors.408.label'),
  },
  409: {
    body: t('errors.409.body'),
    label: t('errors.409.label'),
  },
  410: {
    body: t('errors.410.body'),
    label: t('errors.410.label'),
  },
  413: {
    body: t('errors.413.body'),
    label: t('errors.413.label'),
  },
  422: {
    body: t('errors.422.body'),
    label: t('errors.422.label'),
  },
  429: {
    body: t('errors.429.body'),
    label: t('errors.429.label'),
  },
  499: {
    body: t('errors.499.body'),
    label: t('errors.499.label'),
  },
  500: {
    body: t('errors.500.body'),
    label: t('errors.500.label'),
  },
  501: {
    body: t('errors.501.body'),
    label: t('errors.501.label'),
  },
  502: {
    body: t('errors.502.body'),
    label: t('errors.502.label'),
  },
  503: {
    body: t('errors.503.body'),
    label: t('errors.503.label'),
  },
  504: {
    body: t('errors.504.body'),
    label: t('errors.504.label'),
  },
};

export function bodyForStatus(statusCode?: number): string | null {
  if (!statusCode || statusCode < HttpStatus.MULTIPLE_CHOICES) {
    return null;
  }

  const body = errorMessages[statusCode]?.body;

  if (!body) {
    handle(new Error(`translation missing for ${statusCode}/body`));

    return null;
  }

  return body;
}

export function headerForStatus(statusCode?: number): string | null {
  if (!statusCode || statusCode < HttpStatus.MULTIPLE_CHOICES) {
    return null;
  }

  const label = errorMessages[statusCode]?.label;

  if (!label) {
    handle(new Error(`translation missing for ${statusCode}/header`));

    return null;
  }

  return label;
}

export const useErrorStatus = (linkRequestStatus?: RequestStatus): number | undefined => {
  const [errorType] = useIds(rdfx.type);

  if (linkRequestStatus?.status) {
    return linkRequestStatus.status;
  }

  return ERROR_STATUS_CODES[errorType?.value];
};

export function useTitleForStatus(statusCode: number | undefined): string | null {
  const header = headerForStatus(statusCode);
  const body = bodyForStatus(statusCode);

  return [header, body]
    .filter(Boolean)
    .join(' - ');
}
