import LinkedRenderStore, { RENDER_CLASS_NAME } from 'link-lib';

import ll from '../../../ontology/ll';
import Loading, {
  LoadingButton,
  LoadingCard,
  LoadingCardFloat,
  LoadingCardRowAppendix,
  LoadingDetail,
  LoadingGridContent,
  LoadingMicroRow,
  LoadingPage,
  LoadingRow,
} from '../../components/Loading';
import {
  actionsBarTopology,
  alertDialogTopology,
  attributeListTopology,
  cardAppendixTopology,
  cardFixedTopology,
  cardFloatTopology,
  cardMainTopology,
  cardMicroRowTopology,
  cardRowTopology,
  cardTopology,
  containerFloatTopology,
  containerTopology,
  contentDetailsTopology,
  detailsBarTopology,
  hoverBoxTopology,
  listTopology,
  mainBodyTopology,
  pageTopology,
} from '../../topologies';

export default [
  ...LinkedRenderStore.registerRenderer(
    LoadingCard,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      alertDialogTopology,
      listTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingButton,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      containerTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingMicroRow,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    cardRowTopology,
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingCardRowAppendix,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    cardAppendixTopology,
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingRow,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      cardTopology,
      cardFixedTopology,
      cardMainTopology,
      hoverBoxTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingDetail,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      detailsBarTopology,
      contentDetailsTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingCardFloat,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      containerFloatTopology,
      cardFloatTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingGridContent,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    mainBodyTopology,
  ),
  ...LinkedRenderStore.registerRenderer(
    Loading,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    [
      attributeListTopology,
      listTopology,
      cardMainTopology,
      cardMicroRowTopology,
    ],
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingPage,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    pageTopology,
  ),
  ...LinkedRenderStore.registerRenderer(
    LoadingButton,
    ll.LoadingResource,
    RENDER_CLASS_NAME,
    actionsBarTopology,
  ),
];
