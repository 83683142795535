import { SomeTerm } from '@ontologies/core';
import * as rdfx from '@ontologies/rdf';
import {
  SubjectProp,
  useGlobalIds,
} from 'link-redux';
import React, {
  FC,
  FunctionComponent,
} from 'react';

import FormField from '../../FormField/FormField';
import { InputComponentProps } from '../../FormField/FormFieldTypes';
import { InputType } from '../../FormInput/Input/Input';
import InputElement from '../../FormInput/Input/InputElement';
import useFormField from '../../Form/hooks/useFormField';
import form from '../../../../ontology/form';

const getInputType = (type: SomeTerm): InputType => {
  if (type === form.ColorInput) {
    return InputType.Color;
  }

  if (type === form.NumberInput) {
    return InputType.Number;
  }

  return InputType.Text;
};

const DelayedFormField: FC<SubjectProp> = ({
  subject,
}) => {
  const fieldProps = useFormField(subject, {
    delay: true,
  });

  const [type] = useGlobalIds(subject, rdfx.type);
  const DelayedInput = React.useCallback<FunctionComponent<InputComponentProps>>((inputProps) => (
    <InputElement
      {...inputProps}
      type={getInputType(type)}
    />
  ), [type]);

  return (
    <FormField
      {...fieldProps}
      inputComponent={DelayedInput}
    />
  );
};

export default DelayedFormField;
